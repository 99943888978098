import { saveAs } from 'file-saver';
var XLSX = require('xlsx');

export const SheetJSFT = [
    '.xlsx',
    '.xlsb',
    '.xlsm',
    '.xls',
    '.xml',
    '.csv',
    '.txt',
    '.ods',
    '.fods',
    '.uos',
    '.sylk',
    '.dif',
    '.dbf',
    '.prn',
    '.qpw',
    '.123',
    '.wb*',
    '.wq*',
    '.html',
    '.htm',
];

export const getRowsFromXLSXData = (lines, headerLine) => {
    let result = [];

    const headers = lines[headerLine];

    for (let i = headerLine + 1; i < lines.length; i++) {
        let obj = {};
        const currentline = lines[i];

        if (currentline.length > 0) {
            for (let j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentline[j];
            }

            result.push(obj);
        }
    }
    return result;
};

export const convertStringToArrayBuffer = (s) => {
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf); //create uint8array as viewer
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
    return buf;
};

export const downloadXLSXFile = (wbout, fileName) => {
    saveAs(new Blob([convertStringToArrayBuffer(wbout)], { type: 'application/octet-stream' }), fileName);
};

export const parseXLSXData = (e, key = 'worktype') => {
    /* Parse data */
    const ab = e.target.result;
    const wb = XLSX.read(ab, { type: 'array' });
    let data = [];
    for (let i = 0; i < wb.SheetNames.length; i++) {
        /* Get first worksheet */
        const wsname = wb.SheetNames[i];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const xlsxData = XLSX.utils.sheet_to_json(ws, { header: 1 });
        const xlsxDataFormatted = getRowsFromXLSXData(xlsxData, 0);
        const sheetData = {
            [key]: wsname,
            excelData: xlsxDataFormatted,
        };
        data = [...data, sheetData];
    }
    return data;
};

export const sanitizeAndShortenSheetName = (name) => {
    // remove special characters
    let sheetName = name.replace(/[^a-z0-9]/gi, '');
    // shorten sheet name to 31 characters
    sheetName = sheetName.substring(0, 30);

    return sheetName;
};

export const appendSheetToWorkbook = (wb, ws, sheetName) => {
    if (sheetName.length > 31) sheetName = sanitizeAndShortenSheetName(sheetName);

    XLSX.utils.book_append_sheet(wb, ws, sheetName);
};
