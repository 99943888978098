import * as React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { getOptionLabelDependingOnLabelKeys } from '../../utils/componentUtils';

function CustomAutoComplete({ options, labelKey, secondLabelKey, onChange, value, label }) {
    const handleChange = (event, value) => {
        onChange(value);
    };

    return (
        <Autocomplete
            multiple
            id="tags-outlined"
            options={options}
            getOptionLabel={(option) => option && getOptionLabelDependingOnLabelKeys(option, labelKey, secondLabelKey)}
            value={value}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} variant="outlined" label={label} />}
        />
    );
}

export default CustomAutoComplete;
