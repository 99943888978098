import { useState } from 'react';
import { Button } from '@mui/material';
import { DropzoneDialog } from 'react-mui-dropzone';
import SelectMonthDialog from '../Dialogs/SelectMonthDialog';
import { SheetJSFT } from '../../utils/xlsxUtils';

function UploadXlsxFileButton({ buttonName, monthForUploading, onChangeMonthForUploading, uploadFiles, dialogTitle }) {
    const [openDropzoneDialog, setOpenDropzoneDialog] = useState(false);
    const [submitButtonText, setSubmitButtonText] = useState('Upload');
    const [cancelButtonText, setCancelButtonText] = useState('Abbrechen');

    const [openSelectMonthDialog, setOpenSelectMonthDialog] = useState(false);
    const [uploadedExcelFiles, setUploadedExcelFiles] = useState(null);

    const handleDropzoneDialogOpen = () => {
        setCancelButtonText('Abbrechen');
        setSubmitButtonText('Upload');
        setOpenDropzoneDialog(true);
    };

    const handleDropzoneDialogClose = () => {
        setOpenDropzoneDialog(false);
    };

    const handleDropzoneDialogSave = (files) => {
        if (files.length > 0) {
            if (monthForUploading) {
                setOpenSelectMonthDialog(true);
                setUploadedExcelFiles(files);
            } else {
                uploadFiles(files, handleDropzoneDialogClose);
            }
        }
    };

    const handleSelectMonthDialogClose = () => {
        setOpenSelectMonthDialog(false);
    };

    const handleSelectMonthDialogSave = () => {
        setOpenSelectMonthDialog(false);
        if (monthForUploading) {
            uploadFiles(uploadedExcelFiles, handleDropzoneDialogClose);
        }
    };

    const handleSelectMonthDialogChange = (month) => {
        onChangeMonthForUploading(month);
    };

    return (
        <>
            <Button onClick={handleDropzoneDialogOpen} variant="contained">
                {buttonName}
            </Button>
            <DropzoneDialog
                open={openDropzoneDialog}
                onSave={handleDropzoneDialogSave}
                onClose={handleDropzoneDialogClose}
                showPreviews={true}
                useChipsForPreview={true}
                maxFileSize={50000000}
                cancelButtonText={cancelButtonText}
                submitButtonText={submitButtonText}
                dialogTitle={dialogTitle}
                acceptedFiles={SheetJSFT}
            />
            {monthForUploading && (
                <SelectMonthDialog
                    open={openSelectMonthDialog}
                    onSave={handleSelectMonthDialogSave}
                    onClose={handleSelectMonthDialogClose}
                    onChange={handleSelectMonthDialogChange}
                    selectedValue={monthForUploading}
                ></SelectMonthDialog>
            )}
        </>
    );
}

export default UploadXlsxFileButton;
