import { useQuery } from '@tanstack/react-query';
import { getWorkingUnits } from '../../services/workingUnit';
import { QueryKey } from '../../enums/QueryKey';

export function useWorkingUnits({ location } = {}) {
    const { data: workingUnits } = useQuery({
        queryKey: [QueryKey.WorkingUnits, { ...(location && { location }) }],
        queryFn: () => getWorkingUnits({ location }),
        initialData: [],
        enabled: !!location,
    });

    return [workingUnits];
}
