import { Grid, Button } from '@mui/material';
import { useState, useEffect, useContext, useMemo } from 'react';
import UserContext from '../store/User/UserContext';
import dayjs from 'dayjs';
import { useLocationsByLoggedInUserRole, useCookieLocation, useWorktypes } from '../hooks';
import getSocketURL from '../mixins/getSocketURL';
import AddIcon from '@mui/icons-material/Add';
import { getUserRole } from '../utils/authUtils';
import { UserRole } from '../enums/UserRole';
import Container from '../components/Layout/Container';
import MultiSelectWorktypeLocDateRangeFilter from '../components/General/MultiSelectWorktypeLocDateRangeFilter';
import EventReductionTable from '../components/Events/EventReductionTable';
import { useDeleteEvent, useEvents, getEventsQueryKey, usePostEvent } from '../hooks/api/events';
import { isOSPUser } from '../utils/authUtils';
import EventReductionDialog from '../components/Events/EventReductionDialog';
import EventReductionExportButton from '../components/Events/EventReductionExportButton';
import { queryClient } from '../services/queryClient';
import { getEventById } from '../services/event';
import GridContainer from '../components/Layout/GridContainer';
const { socket, SocketEvents } = getSocketURL();

const Events = () => {
    const loggedInUser = useContext(UserContext);

    const [locationOptions] = useLocationsByLoggedInUserRole({
        prependAllLocationsOption: loggedInUser?.role.callmart_tef,
    });
    const [selectedLocation, setSelectedLocation] = useCookieLocation(locationOptions);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [worktypeOptions] = useWorktypes({
        prependAllWorktypesOption: true,
        ...(getUserRole(loggedInUser) === UserRole.OSP && { location: selectedLocation }),
    });
    const [selectedWorktypes, setSelectedWorktypes] = useState(worktypeOptions.length > 0 ? [worktypeOptions[0]] : []);
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs().add(6, 'months'));

    const [selectedRow, setSelectedRow] = useState(null);

    const [showEventReductionDialog, setShowEventReductionDialog] = useState(false);
    const filterParams = useMemo(
        () => ({
            startDate,
            endDate,
            location: isOSPUser(loggedInUser) ? selectedLocation : selectedLocations.map((loc) => loc.id),
            worktype: selectedWorktypes.map((worktype) => worktype.id),
        }),
        [startDate, endDate, selectedLocation, selectedLocations, selectedWorktypes, loggedInUser]
    );
    const { events, durationTotal, reductionTotal } = useEvents(filterParams);
    const { mutateAsync: handleDeleteEvent } = useDeleteEvent(filterParams);
    const { mutate: handlePostEvent } = usePostEvent({
        filterParams,
        onSuccess: () => {
            setShowEventReductionDialog(false);
            setSelectedRow(null);
        },
    });

    useEffect(() => {
        if (locationOptions?.length && selectedLocations.length === 0) {
            setSelectedLocations([locationOptions[0]]);
        }
    }, [locationOptions, selectedLocations]);

    useEffect(() => {
        if (worktypeOptions?.length && selectedWorktypes.length === 0) {
            setSelectedWorktypes([worktypeOptions[0]]);
        }
    }, [worktypeOptions, selectedWorktypes]);

    useEffect(() => {
        socket.on(SocketEvents.postedEvent, () => {
            if (loggedInUser?.role?.callmart_tef) {
                queryClient.invalidateQueries(getEventsQueryKey(filterParams));
            }
        });

        return () => {
            socket.removeAllListeners();
        };
    }, [loggedInUser?.role.callmart_tef, selectedLocations, selectedWorktypes, startDate, endDate, filterParams]);
    return (
        <Container>
            <GridContainer>
                <MultiSelectWorktypeLocDateRangeFilter
                    locationOptions={locationOptions}
                    selectedLocation={selectedLocation}
                    onSelectedLocationChange={setSelectedLocation}
                    selectedLocations={selectedLocations}
                    onSelectedLocationsChange={setSelectedLocations}
                    worktypeOptions={worktypeOptions}
                    selectedWorktypes={selectedWorktypes}
                    onSelectedWorktypesChange={setSelectedWorktypes}
                    startDate={startDate}
                    onStartDateChange={setStartDate}
                    endDate={endDate}
                    onEndDateChange={setEndDate}
                ></MultiSelectWorktypeLocDateRangeFilter>
                <Grid item>
                    <Button variant="contained" onClick={() => setShowEventReductionDialog(true)}>
                        <AddIcon />
                    </Button>
                    {showEventReductionDialog && (
                        <EventReductionDialog
                            dialogTitle="Neues Event"
                            showDialog={showEventReductionDialog}
                            onShowDialog={setShowEventReductionDialog}
                            record={
                                selectedRow
                                    ? {
                                          ...selectedRow,
                                          serieId: selectedRow?.eventSerieId,
                                          serie: selectedRow?.serie,
                                      }
                                    : null
                            }
                            onRowSelect={setSelectedRow}
                            location={
                                loggedInUser?.role?.callmart_tef ? loggedInUser.primaryLocation.id : selectedLocation
                            }
                            postRecord={handlePostEvent}
                        />
                    )}
                </Grid>
                {loggedInUser?.role?.callmart_tef && (
                    <>
                        <Grid item>
                            <EventReductionExportButton
                                recordType="event"
                                rows={events}
                                start={startDate}
                                end={endDate}
                                pinnedBottomRowData={[
                                    {
                                        worktypeName: 'Gesamt',
                                        duration: durationTotal,
                                        reduction: reductionTotal,
                                        id: '',
                                    },
                                ]}
                            />
                        </Grid>
                    </>
                )}
                <EventReductionTable
                    tableRows={events}
                    durationTotal={durationTotal}
                    reductionTotal={reductionTotal}
                    onRowEditConfirmation={() => setShowEventReductionDialog(true)}
                    onRowCopy={() => setShowEventReductionDialog(true)}
                    selectedRow={selectedRow}
                    onRowSelect={(row) => setSelectedRow(row)}
                    onRowDelete={handleDeleteEvent}
                    getRecordById={getEventById}
                ></EventReductionTable>
            </GridContainer>
        </Container>
    );
};

export default Events;
