import CircularProgress from '@mui/material/CircularProgress';

function Loader() {
    return (
        <div data-testid="loader" style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress
                size={120}
                sx={{
                    color: 'secondary',
                }}
                style={{ position: 'absolute', top: '40%' }}
            />
        </div>
    );
}

export default Loader;
