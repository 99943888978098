import { useEffect, useContext, useState } from 'react';
import UserContext from '../store/User/UserContext';
import { Grid, Typography } from '@mui/material';
import UserRoles from '../components/Profile/UserRoles';
import Container from '../components/Layout/Container';

const Profile = () => {
    const user = useContext(UserContext);
    const [userRoles, setUserRoles] = useState([]);

    useEffect(() => {
        if (user) {
            const roles = [];

            if (user.role.admin) roles.push('Admin');
            if (user.role.idp) roles.push('IDP');
            if (user.role.manager) roles.push('Manager');
            if (user.role.teamleiter) roles.push('Teamleiter');
            if (user.role.agent) roles.push('Agent');

            setUserRoles(roles);
        }
    }, [user, setUserRoles]);

    return (
        <Container>
            <Grid container spacing={1}>
                {!user && <div>TELEFONICA CALLMART</div>}
                {user && (
                    <>
                        <Grid item xs={12}>
                            <Typography>Name: {user.name}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Email: {user.email}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Location: {user.primaryLocation.city}</Typography>
                        </Grid>
                        <UserRoles roles={userRoles} />
                    </>
                )}
            </Grid>
        </Container>
    );
};

export default Profile;
