import api from './index';
import { setToken } from '../utils/authUtils';
import { appendQueryParamsToUrl } from '../utils';

const BASE = '/worktype';

export const getWorktypes = async ({ location }) => {
    let url = `${BASE}`;
    url = appendQueryParamsToUrl(url, { location });

    return api.get(url, await setToken()).then((res) => res.data);
};

export const getAllWorktype = async () => {
    return api.get(`${BASE}`, await setToken()).then((res) => res.data);
};

export const getAllWorktypeByLocation = async (location) => {
    return api.get(`${BASE}/getAllWorktypeByLocation?location=${location}`, await setToken()).then((res) => res.data);
};

export const createWorktype = async (data) => {
    return api.post(`${BASE}`, { data }, await setToken()).then((res) => res.data);
};

export const deleteWorktype = async (id, deleteRelatedData) =>
    await api.delete(`${BASE}/${id}?deleteRelatedData=${deleteRelatedData}`, await setToken());

export const updateWorktype = async (data) => {
    const { id } = data;
    return api.patch(`${BASE}/${id}`, { data }, await setToken()).then((res) => res.data);
};
