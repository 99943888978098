import { CardContent, Card, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    card: {
        height: '50%',
        overflow: 'auto',
        minWidth: '200px',
        textAlign: 'center',
    },
    closeIcon: {
        '&:hover': {
            backgroundColor: 'lightgray',
            borderRadius: '50%',
        },
        maxWidth: '18px',
        maxHeight: '18px',
        padding: '3px',
        float: 'right',
        top: '8px',
        position: 'absolute',
        right: '8px',
    },
    lastUploadPopover: {
        overflow: 'scroll',
        width: '50vw',
    },
}));

function CardPopover({ anchorEl, onClose, children, className }) {
    const classes = useStyles();

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Card className={className ? classes[className] : classes.card}>
                <CardContent>{children}</CardContent>
            </Card>
        </Popover>
    );
}

export default CardPopover;
