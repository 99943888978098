import { useAlertMessage } from '.';
import { useCallback } from 'react';

export function useDispatchSuccessNotification() {
    const { dispatchAlert } = useAlertMessage();
    const dispatchSuccessNotification = useCallback(
        ({ message }) =>
            dispatchAlert({
                message,
                type: 'success',
            }),
        [dispatchAlert]
    );

    return { dispatchSuccessNotification };
}

export function useDispatchSuccessfullyDeletedRecord() {
    const { dispatchSuccessNotification } = useDispatchSuccessNotification();
    const dispatchSuccessfullyDeletedRecord = useCallback(
        (recordName) => dispatchSuccessNotification({ message: `${recordName} erfolgreich gelöscht` }),
        [dispatchSuccessNotification]
    );

    return { dispatchSuccessfullyDeletedRecord };
}
