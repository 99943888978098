import { Dialog, DialogTitle, DialogContent, Grid, Typography, Button, Tooltip, DialogActions } from '@mui/material';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import {
    FrequencyOptions,
    WeekDays,
    getRepetitionOptionsByFrequency,
    getRepetitionsLabelByFrequency,
    showWeekDaysRepetition,
    getOrdinalNumbersForDayInMonth,
    getDayOrdinalNumberByDate,
    getRepetitionIntervalType,
    RepetitionIntervalType,
} from './utils/serieDialogUtils';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import 'dayjs/locale/de';
import { fieldIsRequiredMsg } from '../../utils/componentUtils';
import { isEndAfterStart } from '../../utils/dateUtils';
import ReactHookFormDatePicker from '../ReactHookForm/ReactHookFormDatePicker';
import ReactHookFormSelect from '../ReactHookForm/ReactHookFormSelect';
import RepetitionIntervalSelector from './RepetitionIntervalSelector';

const useStyles = makeStyles((theme) => ({
    dialogActions: {
        justifyContent: 'space-between',
        paddingLeft: '24px',
        paddingRight: '24px',
    },
    daysContainer: {
        display: 'inline-flex',
    },
    dayButton: {
        width: 24,
        height: 24,
        fontSize: theme.typography.pxToRem(10),
        textTransform: 'capitalize',
        minWidth: 24,
        borderRadius: '10%',
        marginRight: theme.spacing(1.5),
        backgroundColor: '#e8e8e8',
    },
    dayButtonLabel: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 'normal',
    },
    selectedDay: {
        '&.Mui-disabled': {
            color: theme.palette.common.white,
        },
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.common.white,
        },
    },
}));

const EventReductionSerieDialog = ({ showDialog, onClose, onSubmit, record }) => {
    const classes = useStyles();

    const {
        watch,
        handleSubmit,
        formState: { errors },
        control,
        getValues,
        setValue,
    } = useForm({
        defaultValues: {
            start: record?.serie ? dayjs(record.serie.start) : record.start,
            frequency: record.recurringOption,
            repetition: record?.serie?.repetition ? parseInt(record?.serie?.repetition) : 1,
            weekDaysRepetition: record?.serie?.weekDaysRepetition || [dayjs(record.start).day()],
            repetitionIntervalDayNumber: record?.serie?.repetitionIntervalDayNumber || dayjs(record.start).date(),
            repetitionIntervalDayOfWeek: record?.serie?.repetitionIntervalDayOfWeek || dayjs(record.start).day(),
            repetitionIntervalDayOrdinalNumber:
                record?.serie?.repetitionIntervalDayOrdinalNumber || getDayOrdinalNumberByDate(record.start),
            repetitionIntervalType: record?.serie
                ? getRepetitionIntervalType(record?.serie)
                : RepetitionIntervalType.DAY_NUMBER_REPETITION,
            end: record?.serie ? dayjs(record.serie.end) : record.end,
        },
    });
    const selectedStart = dayjs(watch('start'));
    const selectedFrequency = watch('frequency');
    const selectedWeekDaysRepetition = watch('weekDaysRepetition');

    const repetitionOptions = getRepetitionOptionsByFrequency(selectedFrequency);
    const ordinalNumbersForDayInMonth = getOrdinalNumbersForDayInMonth(selectedStart);

    const handleDayClicked = (day, startDay) => {
        if (day === startDay) return;

        const weekDaysRepetition = getValues('weekDaysRepetition');
        let newWeekDaysRepetition = [];
        if (weekDaysRepetition.includes(day.value)) {
            newWeekDaysRepetition = weekDaysRepetition.filter((d) => d !== day.value);
        } else {
            newWeekDaysRepetition = [...weekDaysRepetition, day.value];
        }

        setValue('weekDaysRepetition', newWeekDaysRepetition);
    };

    const showRepetitionsLabel = () => {
        return getValues('frequency') === 'week' || getValues('frequency') === 'month';
    };

    const currentMonthDates = new Array(selectedStart.daysInMonth())
        .fill(null)
        .map((x, i) => selectedStart.startOf('month').add(i, 'days'));

    return (
        <Dialog open={showDialog} onClose={onClose} maxWidth={'xs'}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>Serie</DialogTitle>
                <DialogContent style={{ marginTop: '1rem' }}>
                    <Grid container spacing={2}>
                        <Grid item container>
                            <Grid item xs={4}>
                                <Typography variant="body1">Start</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <ReactHookFormDatePicker
                                    name="start"
                                    control={control}
                                    label="Start"
                                    rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                                    formErrors={errors}
                                ></ReactHookFormDatePicker>
                            </Grid>
                        </Grid>

                        <Grid item container>
                            <Grid item xs={4}>
                                <Typography variant="body1">Wiederholen</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <ReactHookFormSelect
                                    name="frequency"
                                    control={control}
                                    label=""
                                    mainProp="text"
                                    valueProp="value"
                                    options={FrequencyOptions}
                                    rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                                    formErrors={errors}
                                ></ReactHookFormSelect>
                            </Grid>
                        </Grid>
                        <Grid item container>
                            <Grid item xs={4}>
                                <Typography variant="body1">Alle</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <ReactHookFormSelect
                                    name="repetition"
                                    control={control}
                                    label=""
                                    mainProp="text"
                                    valueProp="value"
                                    options={repetitionOptions}
                                    rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                                    formErrors={errors}
                                ></ReactHookFormSelect>
                                {showRepetitionsLabel() && (
                                    <Typography style={{ display: 'inline' }}>
                                        {getRepetitionsLabelByFrequency(getValues('frequency'))}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item container>
                            {showWeekDaysRepetition(selectedFrequency) && (
                                <>
                                    <Grid item xs={4}>
                                        <Typography>Am</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <div className={classes.daysContainer}>
                                            {WeekDays.map((day) => (
                                                <Tooltip key={`${day.text}-${day.value}`} title={day.text} arrow>
                                                    <Button
                                                        className={clsx(classes.dayButton, {
                                                            [classes.selectedDay]: selectedWeekDaysRepetition?.includes(
                                                                day.value
                                                            ),
                                                        })}
                                                        onClick={() =>
                                                            handleDayClicked(day, dayjs(record?.start).day())
                                                        }
                                                        classes={{ label: classes.dayButtonLabel }}
                                                    >
                                                        {day.symbol}
                                                    </Button>
                                                </Tooltip>
                                            ))}
                                        </div>
                                    </Grid>
                                </>
                            )}
                            {!showWeekDaysRepetition(selectedFrequency) && (
                                <>
                                    <Grid item xs={4}>
                                        <Typography>Am</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <RepetitionIntervalSelector
                                            control={control}
                                            currentMonthDates={currentMonthDates}
                                            ordinalNumbersForDayInMonth={ordinalNumbersForDayInMonth}
                                        ></RepetitionIntervalSelector>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        <Grid item container>
                            <Grid item xs={4}>
                                <Typography variant="body1">Ende</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <ReactHookFormDatePicker
                                    name="end"
                                    control={control}
                                    label="Ende"
                                    mindatetime={selectedStart}
                                    rules={{
                                        required: { value: true, message: fieldIsRequiredMsg },
                                        validate: (value) => isEndAfterStart(selectedStart, value),
                                    }}
                                    formErrors={errors}
                                ></ReactHookFormDatePicker>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button onClick={onClose} variant="outlined">
                        Abbrechen
                    </Button>
                    <Button type="submit" variant="contained">
                        Speichern
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default EventReductionSerieDialog;
