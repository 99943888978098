import { Grid, Typography } from '@mui/material';
import Table from '../AgGrid';
import DashboardCard from './DashboardCard';

const DashboardTEFTableCard = ({ title, tableRows, columnDefs }) => {
    const defaultColDef = {
        editable: false,
        filter: true,
        sortable: true,
    };

    return (
        <Grid item xs={12} sm={12} md={4} lg={4} style={{ height: 'fit-content' }}>
            <DashboardCard>
                <Typography variant="h6">{title}</Typography>
                <Table
                    rowData={tableRows}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    pagination={true}
                    paginationPageSize={10}
                    height="100%"
                    domLayout="autoHeight"
                ></Table>
            </DashboardCard>
        </Grid>
    );
};

export default DashboardTEFTableCard;
