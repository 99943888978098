import { Card, CardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
}));

function DashboardCard({ children }) {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardContent>{children}</CardContent>
        </Card>
    );
}

export default DashboardCard;
