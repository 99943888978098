import { forwardRef } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ButtonIconRenderer from '../AgGrid/ButtonIconRenderer/ButtonIconRenderer';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import dayjs from 'dayjs';
import TableGridItem from './TableGridItem';

const HorizontalScrollTable = forwardRef(
    (
        {
            firstMonth,
            changeFirstMonth,
            tableRows,
            tableColumnDefs,
            showOptionToGoOneMonthBackward,
            showOptionToGoMoreMonthsBackward,
            showOptionToGoOneMonthForward,
            showOptionToGoMoreMonthsForward,
            numOfMonthsToMove,
            ...props
        },
        ref
    ) => {
        const goForwardMonth = (numOfMonths) => {
            const nextMonth = dayjs(firstMonth, 'YYYY-MM-DD').add(numOfMonths, 'months');
            changeFirstMonth(nextMonth);
        };

        const goBackwardMonth = (numOfMonths) => {
            const previousMonth = dayjs(firstMonth, 'YYYY-MM-DD').subtract(numOfMonths, 'months');
            changeFirstMonth(previousMonth);
        };

        const defaultArrowColumnDefs = {
            field: ``,
            cellClass: 'grid-cell-centered',
            headerComponent: ButtonIconRenderer,
            maxWidth: 28,
            editable: false,
            sortable: false,
            resizable: false,
            filter: false,
            suppressMovable: true,
            enablePivot: false,
        };

        const leftArrowColumns = [];
        if (showOptionToGoMoreMonthsBackward) {
            leftArrowColumns.push({
                ...defaultArrowColumnDefs,
                headerComponent: ButtonIconRenderer,
                headerComponentParams: {
                    icon: KeyboardDoubleArrowLeftIcon,
                    onClick: () => goBackwardMonth(numOfMonthsToMove),
                    iconStyle: { width: '22px', height: '22px' },
                },
                pinned: 'left',
            });
        }

        if (showOptionToGoOneMonthBackward) {
            leftArrowColumns.push({
                ...defaultArrowColumnDefs,
                headerComponent: ButtonIconRenderer,
                headerComponentParams: {
                    icon: ArrowBackIosIcon,
                    onClick: () => goBackwardMonth(1),
                    iconStyle: { width: '14px', height: '14px' },
                },
                pinned: 'left',
            });
        }

        const rightArrowColumns = [];
        if (showOptionToGoMoreMonthsForward) {
            rightArrowColumns.push({
                ...defaultArrowColumnDefs,
                headerComponentParams: {
                    icon: KeyboardDoubleArrowRightIcon,
                    onClick: () => goForwardMonth(numOfMonthsToMove),
                    iconStyle: { width: '22px', height: '22px' },
                },
                pinned: 'right',
            });
        }
        if (showOptionToGoOneMonthForward) {
            rightArrowColumns.push({
                ...defaultArrowColumnDefs,
                headerComponentParams: {
                    icon: ArrowForwardIosIcon,
                    onClick: () => goForwardMonth(1),
                    iconStyle: { width: '14px', height: '14px' },
                },
                pinned: 'right',
            });
        }

        const columnDefs = [...leftArrowColumns, ...tableColumnDefs, ...rightArrowColumns];

        return (
            <TableGridItem
                ref={ref}
                columnDefs={columnDefs}
                animateRows={true}
                rowData={tableRows}
                {...props}
            ></TableGridItem>
        );
    }
);

export default HorizontalScrollTable;
