import dayjs from 'dayjs';

export const FrequencyOptions = [
    {
        value: 'week',
        text: 'Wöchentlich',
    },
    {
        value: 'month',
        text: 'Monatlich',
    },
    {
        value: 'year',
        text: 'Jährlich',
    },
];

export const Months = [
    {
        value: 1,
        text: 'Januar',
    },
    {
        value: 2,
        text: 'Februar',
    },
    {
        value: 3,
        text: 'März',
    },
    {
        value: 4,
        text: 'April',
    },
    {
        value: 5,
        text: 'Mai',
    },
    {
        value: 6,
        text: 'Juni',
    },
    {
        value: 7,
        text: 'Juli',
    },
    {
        value: 8,
        text: 'August',
    },
    {
        value: 9,
        text: 'September',
    },
    {
        value: 10,
        text: 'Oktober',
    },
    {
        value: 11,
        text: 'November',
    },
    {
        value: 12,
        text: 'Dezember',
    },
];

export const WeekDays = [
    {
        value: 1,
        text: 'Montag',
        symbol: 'M',
    },
    {
        value: 2,
        text: 'Dienstag',
        symbol: 'D',
    },
    {
        value: 3,
        text: 'Mittwoch',
        symbol: 'M',
    },
    {
        value: 4,
        text: 'Donnerstag',
        symbol: 'D',
    },
    {
        value: 5,
        text: 'Freitag',
        symbol: 'F',
    },
    {
        value: 6,
        text: 'Samstag',
        symbol: 'S',
    },
    {
        value: 0,
        text: 'Sonntag',
        symbol: 'S',
    },
];

export const getRepetitionOptionsByFrequency = (frequency) =>
    frequency === 'year' ? Months : [...Array(100).keys()].map((x) => ({ value: x + 1, text: x + 1 }));

function getDaysOfWeekInMonth(day, month, year, dayOfWeek) {
    let days = [];
    let d = new Date(year, month - 1, 1);
    while (d.getMonth() === month - 1) {
        if (d.getDay() === dayOfWeek) {
            days.push(d.getDate());
        }
        d.setDate(d.getDate() + 1);
    }
    return days;
}

export const getRepetitionsLabelByFrequency = (frequency) => {
    switch (frequency) {
        case 'week':
            return 'Woche (n)';
        case 'month':
            return 'Monat (e)';
        default:
            return '';
    }
};

export const showWeekDaysRepetition = (frequency) => frequency === 'week';

export const getOrdinalNumbersForDayInMonth = (eventStart) => {
    const daysOfWeekInMonth = getDaysOfWeekInMonth(
        dayjs(eventStart).date(),
        dayjs(eventStart).month() + 1,
        dayjs(eventStart).year(),
        dayjs(eventStart).day()
    );

    let ordinalNumbers = [];
    for (let i = 0; i < daysOfWeekInMonth.length; i++) {
        switch (i) {
            case 0:
                ordinalNumbers.push('Ersten');
                break;
            case 1:
                ordinalNumbers.push('Zweiten');
                break;
            case 2:
                ordinalNumbers.push('Dritten');
                break;
            case 3:
                ordinalNumbers.push(daysOfWeekInMonth.length === 5 ? 'Vierten' : 'Letzten');
                break;
            case 4:
                ordinalNumbers.push('Letzten');
                break;
            default:
                break;
        }
    }

    return ordinalNumbers;
};

export const getDayOrdinalNumberByDate = (date) => {
    date = dayjs(date);
    const dateNumber = date.date();
    switch (true) {
        case dateNumber <= 7:
            return 'Ersten';
        case dateNumber > 7 && dateNumber <= 14:
            return 'Zweiten';
        case dateNumber > 14 && dateNumber <= 21:
            return 'Dritten';
        case dateNumber > 21 && dateNumber <= 28:
            return date.clone().add(7, 'days').month() !== date.month() ? 'Letzten' : 'Vierten';
        case dateNumber >= 29:
            return 'Letzten';
        default:
            return 'Ersten';
    }
};

export const RecurringOptions = [
    {
        value: 'nonrecurrent',
        text: 'Wiederholt sich nicht',
    },
    {
        value: 'week',
        text: 'Wöchentlich Wiederholen',
    },
    {
        value: 'month',
        text: 'Monatlich Wiederholen',
    },
    {
        value: 'year',
        text: 'Jährlich Wiederholen',
    },
];

export const RepetitionIntervalType = {
    DAY_NUMBER_REPETITION: 'DAY_NUMBER_REPETITION',
    DAY_ORDINAL_NUMBER_REPETITION: 'DAY_ORDINAL_NUMBER_REPETITION',
};

export const getRepetitionIntervalType = (serie) => {
    if (!serie || (!serie.repetitionIntervalDayOrdinalNumber && !serie.repetitionIntervalDayNumber)) return null;
    if (serie.repetitionIntervalDayOrdinalNumber) {
        return RepetitionIntervalType.DAY_ORDINAL_NUMBER_REPETITION;
    }
    if (serie.repetitionIntervalDayNumber) return RepetitionIntervalType.DAY_NUMBER_REPETITION;
};
