import { useState, useRef } from 'react';
import Container from '../components/Layout/Container';
import { Grid } from '@mui/material';
import CustomDatePicker from '../components/UI/CustomDatePicker';
import { handleDatePickerMonthChange } from '../utils/componentUtils';
import dayjs from 'dayjs';
import { useOfferedReportsSummary } from '../hooks/api/offeredReports';
import { numberFormatter } from '../utils/agGridUtils';
import TableGridItem from '../components/UI/TableGridItem';
import GridContainer from '../components/Layout/GridContainer';

const VolumeOverview = () => {
    const gridRef = useRef(null);
    const [selectedDate, setSelectedDate] = useState(dayjs());

    const { data: offeredReports } = useOfferedReportsSummary({
        date: selectedDate,
    });
    const columnDefs = [
        {
            field: 'offeredCluster',
            headerName: 'Offered Volume',
            valueGetter: (params) => {
                const { worktype, channel, task } = params?.data?.offeredCluster || {};

                const worktypeText = worktype?.worktype || '';
                const channelName = channel?.name || '';
                const taskName = task?.name || '';

                return `${worktypeText} - ${channelName} - ${taskName}`;
            },
        },
        {
            field: 'offeredCluster.worktype.forecaster',
            headerName: 'Forecaster',
        },
        {
            field: 'sum',
            headerName: 'SUMME',
            valueFormatter: (params) => (params.value ? numberFormatter(params.value) : '-'),
        },
    ];

    return (
        <Container>
            <GridContainer>
                <Grid item xs={12} md={4} lg={2}>
                    <CustomDatePicker
                        date={selectedDate}
                        views={['year', 'month']}
                        openTo="month"
                        onChange={(newValue) => handleDatePickerMonthChange(selectedDate, newValue, setSelectedDate)}
                    ></CustomDatePicker>
                </Grid>
                <TableGridItem
                    ref={gridRef}
                    defaultColDef={{
                        editable: true,
                        filter: true,
                        sortable: true,
                    }}
                    columnDefs={columnDefs}
                    rowData={offeredReports}
                ></TableGridItem>
            </GridContainer>
        </Container>
    );
};

export default VolumeOverview;
