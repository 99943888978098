import api from './index';
import { setToken } from '../utils/authUtils';
import { appendQueryParamsToUrl } from '../utils';

const BASE = '/offeredReport';

export const getLatestOfferedReports = async ({ dateFrom, dateTo, offeredCluster }) => {
    let url = `${BASE}/latest`;
    url = appendQueryParamsToUrl(url, { dateFrom, dateTo, offeredCluster });
    return api.get(url, await setToken()).then((res) => res.data);
};

export const createOneOfferedReport = async (data) => {
    return api.post(`${BASE}`, { data }, await setToken()).then((res) => res.data);
};

export const createOfferedReport = async ({ data, offeredClusterId }) => {
    return api.post(`${BASE}/multiple`, { data, offeredClusterId }, await setToken()).then((res) => res.data);
};

export const getOfferedReportSummary = async ({ dateFrom, dateTo }) => {
    let url = `${BASE}/summary`;
    url = appendQueryParamsToUrl(url, { dateFrom, dateTo });
    return api.get(url, await setToken()).then((res) => res.data);
};

export const getOfferedReportExportData = async ({ dateFrom, dateTo }) => {
    let url = `${BASE}/export`;
    url = appendQueryParamsToUrl(url, { dateFrom, dateTo });
    return api.get(url, await setToken()).then((res) => res.data);
};
