import api from './index';
import { setToken } from '../utils/authUtils';
import { appendQueryParamsToUrl } from '../utils';
import { getRepetitionIntervalType } from '../components/Events/utils/serieDialogUtils';

const BASE = '/reduction';

export const getReductions = async ({ location, worktype, start, end }) => {
    let url = `${BASE}`;
    url = appendQueryParamsToUrl(url, { start, end, location, worktype });
    return api.get(url, await setToken()).then((res) => res.data);
};

export const postReduction = async (data) => {
    return api.post(`${BASE}`, { data }, await setToken());
};

export const deleteReduction = async ({ id, deleteSerie }) => {
    await api.delete(`${BASE}/${id}?deleteSerie=${deleteSerie}`, await setToken());
};

export const getReductionById = async (id) => {
    return api.get(`${BASE}/${id}`, await setToken()).then((res) => {
        const reduction = res.data;
        if (reduction.reductionSerie) {
            reduction.reductionSerie.repetitionIntervalType = getRepetitionIntervalType(reduction.reductionSerie);
        }

        return reduction;
    });
};

export const getTotalReductionsForAllLocations = async () => {
    return api.get(`${BASE}/totalReductionsForAllLocations`, await setToken()).then((res) => res.data);
};
