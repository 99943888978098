import dayjs from 'dayjs';

export const addRowsInMap = ({ strategicVolumes, monthColumns, compareWithCapacityReport }) => {
    let rowsMap = new Map();

    for (const startegicVolume of strategicVolumes) {
        const key = `${startegicVolume.id}`;

        const {
            id: strategicVolumeId,
            forecasterId,
            forecasterNames,
            contact,
            comment,
            workingUnit,
            capacityType,
        } = startegicVolume;

        const reports = {
            strategicVolumeId,
            forecasterId,
            forecasterNames,
            contact,
            comment,
            capacityType: capacityType,
            worktype: {
                id: workingUnit.worktypeLocation.worktype.id,
                name: workingUnit.worktypeLocation.worktype.worktype,
            },
            channel: {
                id: workingUnit.channel.id,
                name: workingUnit.channel.name,
            },
            task: {
                id: workingUnit.task.id,
                name: workingUnit.task.name,
            },
            selected: false,
        };

        monthColumns.forEach((monthColumn) => {
            const month = dayjs(monthColumn).format('YYYY-MM');

            const strategicVolumeReport = startegicVolume.strategicVolumeReports.find(
                (cr) => dayjs(new Date(cr.date)).format('YYYY-MM') === month
            );
            const strategicVolumeReportValue = strategicVolumeReport ? strategicVolumeReport.value : null;
            reports[month] = {
                value: strategicVolumeReportValue,
                originalValue: strategicVolumeReportValue,
                strategicVolumeReportId: strategicVolumeReport?.id,
                release: strategicVolumeReport?.release,
            };

            if (compareWithCapacityReport) {
                const capacityReport =
                    startegicVolume.matchingCapacity &&
                    startegicVolume.matchingCapacity.capacityReports.find((cr) => {
                        let result = dayjs(new Date(cr.date)).format('YYYY-MM') === month;
                        return result;
                    });

                const capacityReportValue = capacityReport ? capacityReport.value : 0;

                reports[`${month}`] = {
                    ...reports[`${month}`],
                    capacityReportValue,
                    capacityReport,
                    ...((strategicVolumeReportValue || capacityReportValue) && {
                        differenceWithCapacity: strategicVolumeReportValue - capacityReportValue,
                    }),
                };
            }
        });

        if (rowsMap.has(key)) {
            rowsMap.set(key, {
                ...rowsMap.get(key),
                ...reports,
            });
        } else {
            rowsMap.set(key, {
                ...reports,
            });
        }
    }

    return rowsMap;
};
