import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    button: {
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
});

function ButtonIconRenderer({ icon: Icon, onClick, iconStyle, ...params }) {
    const classes = useStyles();

    const handleClick = () => onClick && onClick(params);

    return (
        <IconButton onClick={handleClick} className={classes.button}>
            <Icon className={classes.icon} sx={{ ...iconStyle }} />
        </IconButton>
    );
}

export default ButtonIconRenderer;
