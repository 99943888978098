import { useQuery } from '@tanstack/react-query';
import { getAllHEMTLocationsWithWorktypes } from '../../services/worktypeLocation';
import { QueryKey } from '../../enums/QueryKey';

export function useAllHEMTLocationsWithWorktypes() {
    const { data } = useQuery({
        queryKey: [QueryKey.AllHEMTLocationsWithWorktypes],
        queryFn: getAllHEMTLocationsWithWorktypes,
        initialData: [],
    });

    return [data];
}
