import { useMutation } from '@tanstack/react-query';
import { queryClient } from '../../services/queryClient';
import {
    useDispatchErrorNotification,
    useDispatchSuccessfullySavedRecord,
    useDispatchSuccessfullyDeletedRecord,
} from '..';

export function useCreateRecord({ createRecordFn, fetchRecordsQueryKey, recordName }) {
    const { dispatchErrorNotification } = useDispatchErrorNotification();
    const { dispatchSuccessfullySavedRecord } = useDispatchSuccessfullySavedRecord();

    return useMutation({
        mutationFn: createRecordFn,
        onSuccess: async (createdRecord) => {
            await queryClient.cancelQueries({ queryKey: [fetchRecordsQueryKey] });
            queryClient.setQueryData([fetchRecordsQueryKey], (prevCachedRecords) => {
                return [...(prevCachedRecords || []), { ...createdRecord, isNewRecord: false }];
            });

            dispatchSuccessfullySavedRecord(recordName);
        },
        onError: (error) => {
            dispatchErrorNotification({ message: error.message });
        },
    });
}

export function useUpdateRecord({ updateRecordFn, fetchRecordsQueryKey, recordName }) {
    const { dispatchErrorNotification } = useDispatchErrorNotification();
    const { dispatchSuccessfullySavedRecord } = useDispatchSuccessfullySavedRecord();

    return useMutation({
        mutationFn: updateRecordFn,
        onSuccess: () => {
            dispatchSuccessfullySavedRecord(recordName);
        },
        onError: (error) => {
            dispatchErrorNotification({ message: error.message });
        },
        onSettled: () => queryClient.invalidateQueries([fetchRecordsQueryKey]),
    });
}

export function useDeleteRecord({ deleteRecordFn, fetchRecordsQueryKey, recordName }) {
    const { dispatchErrorNotification } = useDispatchErrorNotification();
    const { dispatchSuccessfullyDeletedRecord } = useDispatchSuccessfullyDeletedRecord();

    return useMutation({
        mutationFn: deleteRecordFn,
        onSuccess: () => {
            dispatchSuccessfullyDeletedRecord(recordName);
        },
        onError: (error) => {
            dispatchErrorNotification({ message: error.message });
        },
        onSettled: () => queryClient.invalidateQueries(fetchRecordsQueryKey),
    });
}
