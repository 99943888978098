import { useQuery } from '@tanstack/react-query';
import { getWorktypeLocations } from '../../services/worktypeLocation';
import { QueryKey } from '../../enums/QueryKey';

export function useWorktypeLocations() {
    const { data } = useQuery({
        queryKey: [QueryKey.WorktypeLocations],
        queryFn: getWorktypeLocations,
        initialData: [],
    });

    return [data];
}
