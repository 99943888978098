import { useMessage } from '../../../hooks';
import SnackbarAlert from '../../SnackbarAlert/SnackbarAlert';
import AlertDialog from './AlertDialog';

function AlertNotification({ onClose, anchorOrigin }) {
    const { message, resetMessage } = useMessage();

    const handleClose = (event, reason) => {
        if (onClose) {
            onClose(event, reason);
        } else {
            resetMessage();
        }
    };

    const content = message?.useSnackbar ? (
        <SnackbarAlert message={message} handleClose={handleClose} anchorOrigin={anchorOrigin}></SnackbarAlert>
    ) : (
        <AlertDialog message={message} handleClose={handleClose}></AlertDialog>
    );

    return content;
}

export default AlertNotification;
