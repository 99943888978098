import IconButton from '@mui/material/IconButton';
import { CheckOutlined as CheckOutlinedIcon, Close as CloseIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    icon: {
        color: 'var(--primary)',
        justifyContent: 'center',
        width: '15px !important',
        height: '15px !important',
        fontWeight: 'bold',
    },
}));

function NewBookingsCustomHeader({ onSaveBtnClick, onCancelBtnClick, ...params }) {
    const classes = useStyles();

    const handleSaveBtnClick = (event) => {
        onSaveBtnClick(params);
    };

    const handleCancelBtnClick = (event) => {
        onCancelBtnClick(params);
    };

    return (
        <div>
            <IconButton aria-label="save" onClick={handleCancelBtnClick} sx={{ padding: '5px' }}>
                <CloseIcon className={classes.icon} />
            </IconButton>
            <IconButton aria-label="save" onClick={handleSaveBtnClick} sx={{ padding: '5px' }}>
                <CheckOutlinedIcon className={classes.icon} />
            </IconButton>
        </div>
    );
}

export default NewBookingsCustomHeader;
