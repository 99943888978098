import { useQuery } from '@tanstack/react-query';
import { getOfferedClusters } from '../../services/offeredCluster';
import { QueryKey } from '../../enums/QueryKey';

export function useOfferedClusters() {
    const { data: offeredClusters } = useQuery({
        queryKey: [QueryKey.OfferedClusters],
        queryFn: getOfferedClusters,
        initialData: [],
    });

    return [offeredClusters];
}
