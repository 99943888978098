import api from './index';
import { setToken } from '../utils/authUtils';
import { appendQueryParamsToUrl } from '../utils';

const BASE = '/event';

export const getAllEventsByDateRange = async (start, end, isTEFUser, location, worktype) => {
    let endpoint = `${BASE}/allByDateRange?start=${start}&end=${end}&isTEFUser=${isTEFUser}`;
    if (location) endpoint += `&location=${location}`;
    if (worktype) endpoint += `&worktype=${worktype}`;

    return api.get(endpoint, await setToken()).then((res) => res.data);
};

export const getEvents = async ({ location, worktype, start, end }) => {
    let url = `${BASE}`;
    url = appendQueryParamsToUrl(url, { start, end, location, worktype });
    return api.get(url, await setToken()).then((res) => res.data);
};

export const postEvent = async (data) => {
    return api.post(`${BASE}`, { data }, await setToken());
};

export const deleteEvent = async ({ id, deleteSerie }) => {
    await api.delete(`${BASE}/${id}?deleteSerie=${deleteSerie}`, await setToken());
};

export const getEventById = async (id) => {
    return api.get(`${BASE}/${id}`, await setToken()).then((res) => res.data);
};

export const getTotalEventsForAllLocations = async () => {
    return api.get(`${BASE}/totalEventsForAllLocations`, await setToken()).then((res) => res.data);
};
