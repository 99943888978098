import { Stack, Typography, Grid, Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import clsx from 'clsx';
import DashboardCard from './DashboardCard';

const useStyles = makeStyles((theme) => ({
    header: {
        textAlign: 'center',
        marginTop: '10px !important',
        marginBottom: '40px !important',
    },
    stack: {
        textAlign: 'right',
    },
    primaryColor: {
        color: 'var(--primary)',
    },
    greyColor: {
        color: 'var(--grey)',
    },
    totalsTextAlign: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        textAlign: 'center',
    },
    totalValue: {
        textAlign: 'left',
        marginLeft: '1.5em !important',
        fontWeight: '600',
    },
    opacity: {
        opacity: '0.6',
    },
}));

function DashboardTotals({ totals, ...props }) {
    const classes = useStyles();
    const { totalForecastDeltas, totalBookedBookings, totalAvailableBookings } = totals;

    return (
        <DashboardCard>
            <Typography variant="h6" className={classes.header}>
                Verbleibende Calls
            </Typography>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={12}>
                    <Avatar
                        sx={{
                            border: '2px solid var(--primary)',
                            bgcolor: 'var(--white)',
                            color: 'var(--primary)',
                            width: '160px',
                            height: '160px',
                            overflowWrap: 'break-word',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                        variant="round"
                    >
                        <Typography variant="h4" align="center">
                            {totalAvailableBookings.positive + totalAvailableBookings.negative}
                            <Typography variant="body2" align="center">
                                verbleibende Calls
                            </Typography>
                        </Typography>
                    </Avatar>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Grid container item md={12} justifyContent="center" alignItems="center">
                        <Stack className={classes.stack} style={{ textAlign: 'center' }}>
                            <div>
                                <Typography variant="subtitle2" className={classes.totalsTextAlign}>
                                    <RadioButtonCheckedIcon className={classes.primaryColor}></RadioButtonCheckedIcon>
                                    <span>gebuchte Calls</span>
                                </Typography>
                                <Typography variant="body1" className={clsx(classes.totalValue, classes.greyColor)}>
                                    <span>
                                        +{totalBookedBookings.positive} | {totalBookedBookings.negative}
                                    </span>
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="subtitle2" className={classes.totalsTextAlign}>
                                    <RadioButtonCheckedIcon
                                        className={clsx(classes.primaryColor, classes.opacity)}
                                    ></RadioButtonCheckedIcon>
                                    verbleibende Calls
                                </Typography>
                                <Typography variant="body1" className={clsx(classes.totalValue, classes.greyColor)}>
                                    <span>
                                        +{totalAvailableBookings.positive} | {totalAvailableBookings.negative}
                                    </span>
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="subtitle2" className={classes.totalsTextAlign}>
                                    <RadioButtonCheckedIcon className={classes.greyColor}></RadioButtonCheckedIcon>
                                    <span>verfügbare Calls</span>
                                </Typography>
                                <Typography variant="body1" className={clsx(classes.totalValue, classes.greyColor)}>
                                    <span>
                                        +{totalForecastDeltas.positive} | {totalForecastDeltas.negative}
                                    </span>
                                </Typography>
                            </div>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </DashboardCard>
    );
}

export default DashboardTotals;
