import api from './index';
import { setToken } from '../utils/authUtils';

const BASE = '/capacity';

export const getCapacities = async () => {
    return api.get(`${BASE}`, await setToken()).then((res) => res.data);
};

export const getAllCapacitiesByFilters = async (location, worktype, channel, task) => {
    return api
        .get(
            `${BASE}/allByFilters?location=${location}&worktype=${worktype}&channel=${channel}&task=${task}`,
            await setToken()
        )
        .then((res) => res.data);
};

export const getAllCapacitiesByLocation = async (location) => {
    return api.get(`${BASE}/byLocation?location=${location}`, await setToken()).then((res) => res.data);
};

export const createCapacity = async (data) => {
    return api.post(`${BASE}`, { data }, await setToken()).then((res) => res.data);
};

export const updateCapacity = async (data) => {
    const { id } = data;
    return api.patch(`${BASE}/${id}`, { data }, await setToken()).then((res) => res.data);
};
