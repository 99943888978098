export const QueryKey = {
    AllLocationsFromHEMT: 'all-locations-from-hemt',
    Worktypes: 'worktypes',
    DistinctLocations: 'distinct-locations',
    Channels: 'channels',
    Tasks: 'tasks',
    CapacityTypes: 'capacity-types',
    Capacities: 'capacities',
    AllHEMTLocationsWithWorktypes: 'all-HEMT-locations-with-worktypes',
    WorktypeLocations: 'worktype-locations',
    LastUploadDate: 'last-upload-date',
    AllSlotDaysInMonthAndYear: 'all-slot-days-in-month-and-year',
    AllAvailableBookingsByDateAndWorktype: 'all-available-bookings-by-date-and-worktype',
    LatestLocationDeltas: 'latest-location-deltas',
    AdditionalWorktypeChannelsForLocation: 'additional-worktype-channels-for-location',
    User: 'user',
    AvailableAndBookedBookings: 'available-and-booked-bookings',
    WFMs: 'wfms',
    OfferedClusters: 'offered-clusters',
    LatestOfferedReports: 'latest-offered-reports',
    WorkingHours: 'working-hours',
    OfferedReportsSummary: 'offered-reports-summary',
    Reductions: 'reductions',
    WorkingUnits: 'working-units',
};
