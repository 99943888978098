import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

export const EditButtonCellRenderer = ({ clicked, value, ...props }) => {
    const cellRendererParams = props.colDef.cellRendererParams || { alwaysShow: false };

    const handleClick = () => clicked && clicked({ ...props, value });

    return cellRendererParams.alwaysShow || value ? (
        <IconButton aria-label="edit" onClick={handleClick} className="action-icon-button" style={{ padding: 0 }}>
            <EditIcon className="action-icon" style={{ color: 'var(--primary)' }} />
        </IconButton>
    ) : null;
};
