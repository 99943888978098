import { DialogContentText } from '@mui/material';
import ResponsiveDialog from './ResponsiveDialog';

function ConfirmationDialog({ title, text, open, onClose, onConfirm }) {
    return (
        <ResponsiveDialog title={title} open={open} onClose={onClose} onConfirm={onConfirm} isConfirmationDialog={true}>
            {text && <DialogContentText>{text}</DialogContentText>}
        </ResponsiveDialog>
    );
}

export default ConfirmationDialog;
