import api from './index';
import { setToken } from '../utils/authUtils';
import { appendQueryParamsToUrl } from '../utils';

const BASE = '/channel';

export const getChannels = async ({ name }) => {
    let url = `${BASE}`;
    url = appendQueryParamsToUrl(url, { name });

    return api.get(url, await setToken()).then((res) => res.data);
};

export const getAllChannels = async () => {
    return api.get(`${BASE}`, await setToken()).then((res) => res.data);
};

export const getAllChannelsFromCapacitiesByFilters = async (location, worktype) => {
    return api
        .get(`${BASE}/allFromCapacitiesByFilters?location=${location}&worktype=${worktype}`, await setToken())
        .then((res) => res.data);
};

export const createChannel = async (data) => {
    return api.post(`${BASE}`, { data }, await setToken()).then((res) => res.data);
};

export const updateChannel = async (data) => {
    const { id } = data;
    return api.patch(`${BASE}/${id}`, { data }, await setToken()).then((res) => res.data);
};
