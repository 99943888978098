import { useQuery } from '@tanstack/react-query';
import { getLastForecastDeltaUploadDate } from '../../services/forecastDelta';
import { QueryKey } from '../../enums/QueryKey';
import { formatLastUploadDate } from '../../utils/dateUtils';

export function useLastForecastDeltaUploadDate(worktype) {
    const { data } = useQuery({
        queryKey: [QueryKey.LastUploadDate + '-forecastDelta', { worktype }],
        queryFn: () => getLastForecastDeltaUploadDate(worktype),
        enabled: !!worktype,
    });

    return formatLastUploadDate(data);
}
