import { useState, useEffect, useCallback, useContext, useRef } from 'react';
import CustomDatePicker from '../components/UI/CustomDatePicker';
import CustomSelect from '../components/UI/CustomSelect';
import { Grid, MenuItem } from '@mui/material';
import Container from '../components/Layout/Container';
import {
    useAlertMessage,
    useIsMounted,
    useCookieWorktype,
    useCookieLocation,
    useLocationsByLoggedInUserRole,
    useHEMTLocations,
    useChannels,
    useWorktypes,
    useSelectedChannel,
    useCustomStyles,
} from '../hooks';
import { getAllCallCenterPotentialsByFilters, uploadCallCenterPotentials } from '../services/callCenterPotential';
import { getAllSlotDaysInMonthAndYear } from '../services/slot';
import { createTableRows, createColumnDefsFromSlotDays, addRowsWithDayNamesAsColumnsInMap } from '../utils/agGridUtils';
import UserContext from '../store/User/UserContext';
import WorktypeSelect from '../components/Selects/WorktypeSelect';
import {
    onMonthPickerChange,
    setCommonStateAfterDataUpload,
    showSuccessfulUploadNotification,
    showErrorUploadNotification,
} from '../utils/componentUtils';
import { getFirstDayOfMonth, getLastDayOfMonth } from '../utils/dateUtils';
import PotentialsEmptyTemplateDownloadButton from '../components/Potentials/PotentialsEmptyTemplateDownloadButton';
import { ChannelOptions } from '../enums/ChannelOptions';
import UploadXlsxFileButton from '../components/Buttons/UploadXlsxFileButton';
import { parseXLSXData } from '../utils/xlsxUtils';
import PercentageLoadingOverlay from '../components/Loaders/PercentageLoadingOverlay';
import moment from 'moment-timezone';
import {
    uploadTefOfferedPotentials,
    getAllTefOfferedPotentialByFilters,
    getTefOfferedPotentialsForExcelExport,
} from '../services/tefOfferedPotential';
import { PotentialsType } from '../enums/PotentialsType';
import ExportToExcelButton from '../components/Buttons/ExportToExcelButton';
import PotentialsCurrentDataDownloadButton from '../components/Potentials/PotentialsCurrentDataDownloadButton';
import UserRoleLocation from '../components/General/UserRoleLocation';
import TableGridItem from '../components/UI/TableGridItem';

const handledPotentialsEmptyTemplateColumnNames = [
    'START_TIME',
    'HOUR',
    'MINUTE',
    'Potential',
    'Stadt',
    'Standort Detail',
    'Worktype',
    'Kanal',
];

const offeredPotentialsEmptyTemplateColumnNames = ['START_TIME', 'HOUR', 'MINUTE', 'Offered', 'Worktype', 'Kanal'];

const exportColumnNames = [
    'Standort Code',
    'Standort Detail',
    'Worktype',
    'Channel',
    'START_TIME',
    'Uhrzeit',
    'Potential',
    'Meldung',
];

const createMapWithTableRows = (potentials) => {
    const calculateTotals = true;
    return addRowsWithDayNamesAsColumnsInMap(potentials, new Map(), calculateTotals, 'potentialValue');
};

const Potentials = ({ type }) => {
    const classes = useCustomStyles();

    const loggedInUser = useContext(UserContext);

    const isMounted = useIsMounted();
    const { dispatchAlert } = useAlertMessage();

    const gridRef = useRef(null);

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [locationOptions] = useLocationsByLoggedInUserRole();
    const [selectedLocation, setSelectedLocation] = useCookieLocation(locationOptions);
    const [worktypeOptions] = useWorktypes({ location: selectedLocation });
    const [selectedWorktype, setSelectedWorktype] = useCookieWorktype(worktypeOptions);
    const [channelOptions] = useChannels({ name: [ChannelOptions.INBOUND_VOICE.name, ChannelOptions.CHAT.name] });
    const [selectedChannel, setSelectedChannel] = useSelectedChannel(channelOptions);
    const [allLocationsFromHEMT] = useHEMTLocations();

    const [tableRows, setTableRows] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);

    const [monthForUploading, setMonthForUploading] = useState(moment().format('YYYY-MM'));
    const [loading, setLoading] = useState(false);
    const [percentage, setPercentage] = useState(0);

    const getEmptyTemplateFileNamePrefix = () => {
        const location = locationOptions.find((loc) => loc.id === parseInt(selectedLocation));
        return `potential_${location?.city}`;
    };

    const createTableCols = useCallback(async (dateFrom, dateTo) => {
        const slotDaysInMonth = await getAllSlotDaysInMonthAndYear(dateFrom, dateTo);
        const columnDefinitions = createColumnDefsFromSlotDays(slotDaysInMonth);

        setColumnDefs(columnDefinitions);
    }, []);

    const getCallCenterPotentials = useCallback(
        (date, locationId, worktypeId, channelId) => {
            gridRef?.current?.api?.showLoadingOverlay();

            const dateFrom = getFirstDayOfMonth(date);
            const dateTo = getLastDayOfMonth(date);

            getAllCallCenterPotentialsByFilters(dateFrom, dateTo, locationId, worktypeId, channelId).then(
                (potentials) => {
                    if (potentials.length) {
                        const rowsMap = createMapWithTableRows(potentials);
                        const rows = createTableRows(rowsMap, 'slotTime');

                        setTableRows(rows);
                        gridRef?.current?.api?.hideOverlay();
                    } else {
                        setTableRows([]);

                        gridRef?.current?.api?.showNoRowsOverlay();

                        dispatchAlert({
                            message: 'No data available',
                            type: 'error',
                        });
                    }
                }
            );
        },
        [dispatchAlert]
    );

    const getTefOfferedPotentials = useCallback(
        (date, worktypeId, channelId) => {
            gridRef?.current?.api?.showLoadingOverlay();

            const dateFrom = getFirstDayOfMonth(date);
            const dateTo = getLastDayOfMonth(date);

            getAllTefOfferedPotentialByFilters(dateFrom, dateTo, worktypeId, channelId).then((potentials) => {
                if (potentials.length) {
                    const rowsMap = createMapWithTableRows(potentials);
                    const rows = createTableRows(rowsMap, 'slotTime');

                    setTableRows(rows);
                    gridRef?.current?.api?.hideOverlay();
                } else {
                    setTableRows([]);

                    gridRef?.current?.api?.showNoRowsOverlay();

                    dispatchAlert({
                        message: 'No data available',
                        type: 'error',
                    });
                }
            });
        },
        [dispatchAlert]
    );

    const uploadPotentials = ({ data, monthForUploading }, setPercentage) => {
        if (loggedInUser?.role?.callmart_tef) {
            return uploadTefOfferedPotentials({ data, monthForUploading }, setPercentage);
        }

        return uploadCallCenterPotentials({ data, monthForUploading }, setPercentage);
    };

    const uploadFiles = (files, closeDropzoneDialog) => {
        setLoading(true);

        const file = files[0];

        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = parseXLSXData(e);

            uploadPotentials({ data, monthForUploading }, setPercentage)
                .then(async (res) => {
                    const response = res[0];
                    const resData = JSON.parse(response);
                    const { success, errorMessage } = resData;

                    const selectedMonth = parseInt(monthForUploading.split('-')[1]) - 1;
                    const selectedYear = parseInt(monthForUploading.split('-')[0]);
                    setSelectedDate((prevSelectedDate) => {
                        prevSelectedDate.setMonth(selectedMonth);
                        prevSelectedDate.setFullYear(selectedYear);
                        return prevSelectedDate;
                    });

                    if (success) {
                        showSuccessfulUploadNotification(dispatchAlert);
                    } else {
                        showErrorUploadNotification({
                            dispatchAlert,
                            errorMessage,
                        });
                    }
                })
                .catch(async (error) => {
                    showErrorUploadNotification({
                        dispatchAlert,
                        errorMessage: error.message,
                    });
                })
                .finally(() => {
                    setCommonStateAfterDataUpload(setLoading, setPercentage, closeDropzoneDialog);
                });
        };
        reader.readAsArrayBuffer(file);
    };

    const getExportFileName = () => {
        return `Potenziale_${moment(selectedDate).format('MM.YYYY')}.xlsx`;
    };

    const createExcelRowForExportPotentialsFile = (exportDataRow) => {
        const location = allLocationsFromHEMT.find((location) => location.id === exportDataRow.locationId);
        const locationCity = location?.city;
        const locationCode = location?.code;
        const worktype = exportDataRow.worktype;
        const channel = exportDataRow.channel;
        const slotDate = new Date(exportDataRow.slotDate);
        const date = moment(slotDate).format('DD.MM.YYYY');
        const time = moment(slotDate).format('HH:mm:ss');
        const potentialValue = exportDataRow.tefOfferedPotentialValue;
        const potentialCreatedAt = moment(new Date(exportDataRow.tefOfferedPotentialCreatedAt)).format(
            'DD.MM.YYYY HH:mm'
        );

        return [locationCode, locationCity, worktype, channel, date, time, potentialValue, potentialCreatedAt];
    };

    useEffect(() => {
        if (selectedDate) {
            const dateFrom = getFirstDayOfMonth(selectedDate);
            const dateTo = getLastDayOfMonth(selectedDate);

            createTableCols(dateFrom, dateTo);
        }
    }, [selectedDate, createTableCols]);

    useEffect(() => {
        if (loggedInUser) {
            if (loggedInUser.role.callmart_tef && type === PotentialsType.TEF_OFFERED) {
                if (selectedDate && selectedWorktype && selectedChannel) {
                    getTefOfferedPotentials(selectedDate, selectedWorktype, selectedChannel, isMounted);
                }
            } else {
                if (selectedDate && selectedLocation && selectedWorktype && selectedChannel) {
                    getCallCenterPotentials(
                        selectedDate,
                        selectedLocation,
                        selectedWorktype,
                        selectedChannel,
                        isMounted
                    );
                }
            }
        }
    }, [
        loggedInUser,
        selectedDate,
        selectedLocation,
        selectedWorktype,
        selectedChannel,
        type,
        isMounted,
        getTefOfferedPotentials,
        getCallCenterPotentials,
    ]);

    return (
        <Container>
            <Grid container spacing={1}>
                {loggedInUser && (
                    <UserRoleLocation
                        loggedInUser={loggedInUser}
                        selectedLocation={selectedLocation}
                        locationOptions={locationOptions}
                        onChange={setSelectedLocation}
                    ></UserRoleLocation>
                )}
                <Grid item xs={12} md={3} lg={2}>
                    <WorktypeSelect
                        selectedWorktype={selectedWorktype}
                        worktypeOptions={worktypeOptions}
                        onChange={setSelectedWorktype}
                    />
                </Grid>
                <Grid item xs={12} md={2} lg={2}>
                    <CustomSelect
                        selectedValue={selectedChannel}
                        options={channelOptions}
                        onChange={setSelectedChannel}
                        label={'Channel'}
                    >
                        {channelOptions &&
                            channelOptions.map((channel) => (
                                <MenuItem value={channel.id} key={channel.id}>
                                    {channel.name}
                                </MenuItem>
                            ))}
                    </CustomSelect>
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                    <CustomDatePicker
                        date={selectedDate}
                        views={['year', 'month']}
                        openTo="month"
                        onChange={(newValue) => onMonthPickerChange(newValue, selectedDate, setSelectedDate)}
                    ></CustomDatePicker>
                </Grid>
                {loggedInUser?.role?.callmart_tef && (
                    <Grid
                        item
                        xs={type === PotentialsType.CALL_CENTER ? 12 : 5}
                        md={type === PotentialsType.CALL_CENTER ? 12 : 8}
                        lg={3}
                        className={classes.btnRightAligned}
                    >
                        <ExportToExcelButton
                            columnNames={exportColumnNames}
                            sheetName="Tabelle1"
                            getFileName={getExportFileName}
                            wbTitle="Potenziale"
                            wbSubject="Export Potenziale"
                            createRowFromExportData={createExcelRowForExportPotentialsFile}
                            getDataFromDb={() =>
                                getTefOfferedPotentialsForExcelExport(
                                    moment(selectedDate).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
                                    moment(selectedDate).endOf('month').format('YYYY-MM-DD HH:mm:ss')
                                )
                            }
                        ></ExportToExcelButton>
                    </Grid>
                )}
                {loggedInUser?.role?.callmart_osp && (
                    <>
                        <Grid item xs={10} md={11} lg={11} className={classes.btnRightAligned}>
                            <PotentialsEmptyTemplateDownloadButton
                                selectedDate={selectedDate}
                                columnNames={handledPotentialsEmptyTemplateColumnNames}
                                hourSlotsStart={0}
                                hourSlotsEnd={23}
                                fileNamePrefix={getEmptyTemplateFileNamePrefix()}
                                channels={channelOptions}
                                worktypes={worktypeOptions}
                                selectedLocation={selectedLocation}
                            />
                            <PotentialsCurrentDataDownloadButton
                                date={selectedDate}
                                selectedLocation={selectedLocation}
                                worktypes={worktypeOptions}
                            ></PotentialsCurrentDataDownloadButton>
                        </Grid>
                    </>
                )}
                {loggedInUser?.role?.callmart_tef && (
                    <>{type === PotentialsType.CALL_CENTER && <Grid item xs={10} md={10} lg={2}></Grid>}</>
                )}
                {(loggedInUser?.role?.callmart_osp || type === PotentialsType.TEF_OFFERED) && (
                    <>
                        {type === PotentialsType.TEF_OFFERED && (
                            <Grid item xs={4} md={3} lg={11} className={classes.btnRightAligned}>
                                <PotentialsEmptyTemplateDownloadButton
                                    selectedDate={selectedDate}
                                    columnNames={offeredPotentialsEmptyTemplateColumnNames}
                                    hourSlotsStart={0}
                                    hourSlotsEnd={23}
                                    fileNamePrefix={'Offered'}
                                    channels={channelOptions}
                                    worktypes={worktypeOptions}
                                    selectedLocation={selectedLocation}
                                />
                            </Grid>
                        )}
                        <Grid
                            item
                            xs={type === PotentialsType.TEF_OFFERED ? 3 : 1}
                            md={1}
                            lg={1}
                            className={classes.btnRightAligned}
                        >
                            <UploadXlsxFileButton
                                buttonName="Upload"
                                monthForUploading={monthForUploading}
                                onChangeMonthForUploading={setMonthForUploading}
                                uploadFiles={uploadFiles}
                                dialogTitle="30 min Potentiale hochladen"
                            ></UploadXlsxFileButton>
                        </Grid>
                    </>
                )}
                <TableGridItem
                    ref={gridRef}
                    rowData={tableRows}
                    defaultColDef={{
                        editable: false,
                        filter: true,
                        sortable: true,
                    }}
                    columnDefs={columnDefs}
                ></TableGridItem>
            </Grid>
            <PercentageLoadingOverlay loading={loading} percentage={percentage}></PercentageLoadingOverlay>
        </Container>
    );
};

export default Potentials;
