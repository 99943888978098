import { useState, useEffect, useCallback, useRef } from 'react';
import { Grid } from '@mui/material';
import Container from '../components/Layout/Container';
import {
    getAllAvailableBookingsByDateAndWorktype,
    getOldestAndLatestBookedBookingsByDateAndWorktype,
    getAllBookingDeltaByBookingId,
    getTotalBookedBookingsByDateRangeAndWorktype,
    getAllBookedBookingsByDateRangeGroupByDateAndLocation,
} from '../services/bookingDelta';
import { getAllLocationsByWorktype, getAllDistinctLocations } from '../services/worktypeLocation';
import DailyOSPBookingsTotals from '../components/DailyOSPBookings/DailyOSPBookingsTotals';
import { getTotalForecastDeltaValuesByDateRangeAndWorktype } from '../services/forecastDelta';
import CustomDatePicker from '../components/UI/CustomDatePicker';
import DailyOSPBookingsHistory from '../components/DailyOSPBookings/DailyOSPBookingsHistory';
import {
    createTableRows,
    addRowsWithStaticFieldNameInMap,
    getSlotTimeRowKey,
    getSlotTimeColumnDef,
} from '../utils/agGridUtils';
import CardPopover from '../components/UI/CardPopover';
import { useCellPopover } from '../hooks/useCellPopover';
import { useAlertMessage, useIsMounted, useCookieWorktype, useWorktypes, useHEMTLocations } from '../hooks';
import { WorktypeSelectOptions } from '../enums/WorktypeSelectOptions';
import WorktypeSelect from '../components/Selects/WorktypeSelect';
import getSocketURL from '../mixins/getSocketURL';
import { setUploadedMonthAndYearToDate } from '../utils/dateUtils';
import dayjs from 'dayjs';
import TableGridItem from '../components/UI/TableGridItem';
const { socket, SocketEvents } = getSocketURL();

const getResults = async (dateFrom, dateTo, worktype) => {
    const locationsList =
        worktype === WorktypeSelectOptions.ALL_WORKTYPES.value
            ? await getAllDistinctLocations()
            : await getAllLocationsByWorktype(worktype);
    const bookedBookingsList =
        worktype === WorktypeSelectOptions.ALL_WORKTYPES.value
            ? await getAllBookedBookingsByDateRangeGroupByDateAndLocation(dateFrom, dateTo)
            : await getOldestAndLatestBookedBookingsByDateAndWorktype(dateFrom, dateTo, worktype);
    const availableBookingsList = await getAllAvailableBookingsByDateAndWorktype(dateFrom, dateTo, worktype);

    return { locationsList, availableBookingsList, bookedBookingsList };
};

const getTotals = async (dateFrom, dateTo, worktype) => {
    const { totalForecastDeltas } = await getTotalForecastDeltaValuesByDateRangeAndWorktype(dateFrom, dateTo, worktype);
    const { totalBookedBookings } = await getTotalBookedBookingsByDateRangeAndWorktype(dateFrom, dateTo, worktype);

    return {
        totalForecastDeltas: totalForecastDeltas.positive + totalForecastDeltas.negative,
        totalBookedBookings: totalBookedBookings.positive + totalBookedBookings.negative,
    };
};

const createColumnDefs = (locations, allLocationsFromHEMT) => {
    let columns = [];

    columns = [
        ...columns,
        getSlotTimeColumnDef(),
        {
            field: 'availableBookings',
            headerName: 'Verfügbar',
            maxWidth: 80,
            children: [
                {
                    field: 'availableBookings',
                    headerName: '',
                    valueFormatter: (params) => (params.value ? params.value : 0),
                    minWidth: 100,
                    cellClass: 'grid-cell-centered',
                    cellStyle: {
                        justifyContent: 'center',
                        fontWeight: 'bold',
                    },
                },
            ],
        },
    ];

    for (let i = 0; i < locations.length; i++) {
        let locationFromTelefonica = allLocationsFromHEMT.find((location) => location.id === locations[i].locationId);
        columns = [
            ...columns,
            {
                field: `locationId${locations[i].locationId}`,
                headerName: locationFromTelefonica ? locationFromTelefonica.city : locations[i].id,
                children: [
                    {
                        field: `lastBookedBookingValueLocationId${locations[i].locationId}`,
                        headerName: '',
                        valueFormatter: (params) => (params.value ? params.value : 0),
                        minWidth: 30,
                        editable: false,
                        sortable: false,
                        resizable: true,
                        filter: false,
                        cellClass: 'grid-cell-centered',
                        cellStyle: (params) => {
                            const cellStyle = { justifyContent: 'center' };
                            if (params.value > 0) {
                                return { color: 'red', ...cellStyle };
                            }

                            return cellStyle;
                        },
                    },
                    {
                        field: `oldestBookedBookingValueLocationId${locations[i].locationId}`,
                        headerName: '',
                        editable: false,
                        sortable: false,
                        resizable: false,
                        filter: false,
                        minWidth: 30,
                        cellRenderer: (params) => {
                            let result = params.value ? (
                                <span
                                    style={{
                                        padding: '0.5em 1em',
                                        backgroundColor: 'var(--primary)',
                                        borderRadius: '30px',
                                        color: 'var(--white)',
                                    }}
                                >
                                    {params.value}
                                </span>
                            ) : (
                                ''
                            );
                            return result;
                        },
                    },
                ],
            },
        ];
    }
    return columns;
};

const createMapWithTableRows = (availableBookingsList, bookedBookingsList) => {
    let rowsMap = new Map();
    rowsMap = addRowsWithStaticFieldNameInMap(availableBookingsList, rowsMap, 'availableBookings', 'availableBookings');
    rowsMap = addBookingsInMap(bookedBookingsList, rowsMap);

    return rowsMap;
};

const addBookingsInMap = (list, rowsMap) => {
    for (let i = 0; i < list.length; i++) {
        const key = getSlotTimeRowKey(list[i].slotDate);

        if (rowsMap.has(key)) {
            rowsMap.set(key, {
                ...rowsMap.get(key),
                [`lastBookedBookingValueLocationId${list[i].locationId}`]: list[i].bookedBookings,
                [`oldestBookedBookingValueLocationId${list[i].locationId}`]: list[i].oldestBookedBookings,
                [`bookingIdLocationId${list[i].locationId}`]: list[i].bookingId,
            });
        } else {
            rowsMap.set(key, {
                [`lastBookedBookingValueLocationId${list[i].locationId}`]: list[i].bookedBookings,
                [`oldestBookedBookingValueLocationId${list[i].locationId}`]: list[i].oldestBookedBookings,
                [`bookingIdLocationId${list[i].locationId}`]: list[i].bookingId,
            });
        }
    }

    return rowsMap;
};

const DailyOSPBookings = () => {
    const isMounted = useIsMounted();
    const { dispatchAlert } = useAlertMessage();

    const gridRef = useRef(null);

    const [selectedDate, setSelectedDate] = useState(dayjs());

    const [worktypeOptions] = useWorktypes({ prependAllWorktypesOption: true });
    const [selectedWorktype, setSelectedWorktype] = useCookieWorktype(worktypeOptions);

    const [bookedCalls, setBookedCalls] = useState(0);
    const [availableCalls, setAvailableCalls] = useState(0);
    const [forecastCalls, setForecastCalls] = useState(0);

    const [allLocationsFromHEMT] = useHEMTLocations();
    const [tableRows, setTableRows] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);

    const { showPopover, hoveredCell, hoveredCellHistoryList, popoverOpen, popoverClose } = useCellPopover();

    const fillTableWithData = useCallback(
        (dateFrom, dateTo, worktype, locationsFromHEMT) => {
            getResults(dateFrom, dateTo, worktype).then((res) => {
                const { locationsList, availableBookingsList, bookedBookingsList } = res;

                if (locationsList.length > 0) {
                    const columnDefinitions = createColumnDefs(locationsList, locationsFromHEMT);
                    setColumnDefs(columnDefinitions);
                }

                if (availableBookingsList.length > 0 || bookedBookingsList.length > 0) {
                    const rowsMap = createMapWithTableRows(availableBookingsList, bookedBookingsList);
                    const rows = createTableRows(rowsMap, 'slotTime');
                    setTableRows(rows);

                    gridRef?.current?.api?.hideOverlay();
                } else {
                    setColumnDefs([]);
                    setTableRows([]);

                    gridRef?.current?.api?.showNoRowsOverlay();

                    dispatchAlert({
                        message: 'No data available',
                        type: 'error',
                    });
                }
            });
        },
        [dispatchAlert]
    );

    const calculateTotals = async (dateFrom, dateTo, worktype) => {
        const { totalForecastDeltas, totalBookedBookings } = await getTotals(dateFrom, dateTo, worktype);
        const totalAvailableBookings = totalForecastDeltas - totalBookedBookings;

        return { totalForecastDeltas, totalBookedBookings, totalAvailableBookings: totalAvailableBookings };
    };

    const setTotals = useCallback(async (dateFrom, dateTo, worktype) => {
        const { totalForecastDeltas, totalBookedBookings, totalAvailableBookings } = await calculateTotals(
            dateFrom,
            dateTo,
            worktype
        );

        setBookedCalls(totalBookedBookings);
        setAvailableCalls(totalAvailableBookings);
        setForecastCalls(totalForecastDeltas);
    }, []);

    const cellHovered = async (params) => {
        if (params.column.colId.startsWith('oldestBookedBookingValueLocationId')) {
            const locationId = params.column.colId.replace('oldestBookedBookingValueLocationId', '');
            if (params.data[`oldestBookedBookingValueLocationId${locationId}`]) {
                const bookingDeltasList = await getAllBookingDeltaByBookingId(
                    params.data[`bookingIdLocationId${locationId}`]
                );

                popoverOpen(true, params.event.target, bookingDeltasList);
            }
        }
    };

    const handleDatePickerChange = (newValue) => {
        setSelectedDate(newValue);
    };

    useEffect(() => {
        if (selectedDate && selectedWorktype) {
            gridRef?.current?.api?.showLoadingOverlay();
            if (allLocationsFromHEMT && allLocationsFromHEMT.length > 0) {
                const dateFrom = dayjs.utc(selectedDate).startOf('day');
                const dateTo = dayjs.utc(selectedDate).endOf('day');
                if (isMounted) {
                    fillTableWithData(dateFrom, dateTo, selectedWorktype, allLocationsFromHEMT);
                    setTotals(dateFrom, dateTo, selectedWorktype);
                }
            }
        }
    }, [selectedDate, selectedWorktype, allLocationsFromHEMT, fillTableWithData, setTotals, isMounted]);

    useEffect(() => {
        socket.on(SocketEvents.uploadedForecastDelta, ({ monthForUploading }) => {
            const uploadedDate = setUploadedMonthAndYearToDate(dayjs(), monthForUploading);
            setSelectedDate(uploadedDate);
            setSelectedWorktype(-1);
        });

        socket.on(SocketEvents.createdBookingDelta, ({ monthForCreatedBookings, worktypeForCreatedBookings }) => {
            const uploadedDate = dayjs().month(monthForCreatedBookings - 1);
            setSelectedDate(uploadedDate);
            setSelectedWorktype(worktypeForCreatedBookings);
        });

        return () => {
            socket.removeAllListeners();
        };
    }, [setSelectedWorktype]);

    return (
        <Container>
            <Grid container spacing={1}>
                <Grid item xs={12} md={3} lg={2}>
                    <CustomDatePicker
                        date={selectedDate}
                        inputFormat="DD.MM.YYYY"
                        mask="__.__.____"
                        onChange={handleDatePickerChange}
                    ></CustomDatePicker>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <WorktypeSelect
                        selectedWorktype={selectedWorktype}
                        worktypeOptions={worktypeOptions}
                        onChange={setSelectedWorktype}
                    />
                </Grid>
                <Grid item xs={12} md={3} lg={5}>
                    <DailyOSPBookingsTotals
                        bookedCalls={bookedCalls}
                        availableCalls={availableCalls}
                        forecastCalls={forecastCalls}
                    />
                </Grid>
                <TableGridItem
                    ref={gridRef}
                    headerHeight={'0'}
                    groupHeaderHeight={'50'}
                    rowData={tableRows}
                    defaultColDef={{
                        editable: false,
                        filter: true,
                        sortable: true,
                    }}
                    columnDefs={columnDefs}
                    onCellMouseOver={cellHovered}
                ></TableGridItem>
            </Grid>
            {showPopover && (
                <CardPopover anchorEl={hoveredCell} onClose={popoverClose}>
                    <DailyOSPBookingsHistory bookingDeltasHistory={hoveredCellHistoryList} />
                </CardPopover>
            )}
        </Container>
    );
};

export default DailyOSPBookings;
