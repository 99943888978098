import { Grid } from '@mui/material';

const GridContainer = ({ children, classes }) => {
    return (
        <Grid container spacing={1} rowGap={2} className={classes}>
            {children}
        </Grid>
    );
};

export default GridContainer;
