import api from './index';
import { setToken } from '../utils/authUtils';
import { appendQueryParamsToUrl } from '../utils';
import { fetchReadableStream } from './fetchReadableStream';

const BASE = '/WFMValue';

export const getWFMValues = async ({ dateFrom, dateTo, KPINames }) => {
    let url = `${BASE}`;
    url = appendQueryParamsToUrl(url, { dateFrom, dateTo, KPINames });
    return api.get(url, await setToken()).then((res) => res.data);
};

export const uploadWFMs = async ({ data, setPercentage }) => {
    return await fetchReadableStream(`${BASE}`, { data }, setPercentage);
};
