import api from './index';
import { setToken } from '../utils/authUtils';
import { appendQueryParamsToUrl } from '../utils';

const BASE = '/capacityType';

export const getCapacityTypes = async () => {
    const url = `${BASE}`;

    return api.get(url, await setToken()).then((res) => res.data);
};

export const getAllCapacityTypes = async () => {
    return api.get(`${BASE}`, await setToken()).then((res) => res.data);
};

export const getCapacityTypesByUnit = async (unit) => {
    return api.get(`${BASE}/byUnit?unit=${unit}`, await setToken()).then((res) => res.data);
};

export const getAllCapacityTypesFromCapacitiesByFilters = async (location, worktype, channel, task) => {
    let url = `${BASE}/allFromCapacitiesByFilters`;
    url = appendQueryParamsToUrl(url, { location, worktype, channel, task });

    return api.get(url, await setToken()).then((res) => res.data);
};

export const createCapacityType = async (data) => {
    return api.post(`${BASE}`, { data }, await setToken()).then((res) => res.data);
};

export const updateCapacityType = async (data) => {
    const { id } = data;
    return api.patch(`${BASE}/${id}`, { data }, await setToken()).then((res) => res.data);
};
