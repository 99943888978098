import CRUDSettings from '../components/General/CRUDSettings';
import { useCapacityTypes, useCreateCapacityType, useUpdateCapacityType } from '../hooks';

const CapacityTypes = () => {
    const [capacityTypes] = useCapacityTypes();
    const { mutate: handleCreateCapacityType } = useCreateCapacityType();
    const { mutate: handleUpdateCapacityType } = useUpdateCapacityType();

    const columnDefs = [
        {
            field: 'name',
            headerName: 'Capacity Type',
            editable: (params) => params.data.isRowEdited || params.data.isNewRecord,
        },
    ];

    return (
        <CRUDSettings
            tableRows={capacityTypes}
            columnDefs={columnDefs}
            btnText="Neuer Capacity Type"
            createRecord={handleCreateCapacityType}
            updateRecord={handleUpdateCapacityType}
            recordType="Capacity Type"
        ></CRUDSettings>
    );
};

export default CapacityTypes;
