import DashboardCard from './DashboardCard';
import CustomDatePicker from '../UI/CustomDatePicker';
import WorktypeSelect from '../Selects/WorktypeSelect';
import { Box } from '@mui/material';

function DashboardDateRangeWorktypeSelect({
    selectedStartDate,
    selectedEndDate,
    onStartDateChange,
    onEndDateChange,
    worktype,
    worktypeOptions,
    onWorktypeChange,
    ...props
}) {
    const handleStartDateChange = (newValue) => {
        onStartDateChange(newValue);
    };

    const handleEndDateChange = (newValue) => {
        onEndDateChange(newValue);
    };

    const handleWorktypeChange = (newValue) => {
        onWorktypeChange(newValue);
    };

    return (
        <DashboardCard>
            <WorktypeSelect
                selectedWorktype={worktype}
                worktypeOptions={worktypeOptions}
                onChange={handleWorktypeChange}
            />
            <Box sx={{ marginTop: '1rem' }}>
                <CustomDatePicker
                    date={selectedStartDate}
                    inputFormat="DD.MM.YYYY"
                    mask="__.__.____"
                    onChange={handleStartDateChange}
                ></CustomDatePicker>
            </Box>
            <Box sx={{ marginTop: '1rem' }}>
                <CustomDatePicker
                    date={selectedEndDate}
                    inputFormat="DD.MM.YYYY"
                    mask="__.__.____"
                    onChange={handleEndDateChange}
                ></CustomDatePicker>
            </Box>
        </DashboardCard>
    );
}

export default DashboardDateRangeWorktypeSelect;
