import { useWorktypes, useCreateWorktype, useUpdateWorktype } from '../hooks';
import CRUDSettings from '../components/General/CRUDSettings';

const Worktypes = () => {
    const [worktypes] = useWorktypes();
    const { mutate: handleCreateWorktype } = useCreateWorktype();
    const { mutate: handleUpdateWorktype } = useUpdateWorktype();

    const columnDefs = [
        {
            field: 'worktype',
            headerName: 'Worktype',
            editable: (params) => params.data.isRowEdited || params.data.isNewRecord,
        },
    ];

    return (
        <CRUDSettings
            tableRows={worktypes}
            columnDefs={columnDefs}
            columnToFocusOnEdit="worktype"
            btnText="Neuer Worktype"
            headerForNameColumn="Worktype"
            createRecord={handleCreateWorktype}
            updateRecord={handleUpdateWorktype}
            recordType="Worktype"
        ></CRUDSettings>
    );
};

export default Worktypes;
