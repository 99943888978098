import { useState, useEffect, useCallback } from 'react';
import {
    getAllBookedBookingsByDateRangeAndWorktype,
    getTotalBookedBookingsByDateRangeAndWorktype,
} from '../../services/bookingDelta';
import {
    getAllForecastDeltasByDateRangeAndWorktype,
    getTotalForecastDeltaValuesByDateRangeAndWorktype,
} from '../../services/forecastDelta';
import { WorktypeSelectOptions } from '../../enums/WorktypeSelectOptions';
import { RecordCreatedAt } from '../../enums/RecordCreatedAt';
import { getAllDistinctLocations, getAllLocationsByWorktype } from '../../services/worktypeLocation';
import { getSumOfBookedBookingsByDateAndWorktypeGroupByLocation } from '../../services/bookingDelta';
import { useQuery, useIsMounted } from '../../hooks';
import {
    createTableRows,
    combineChartDataInOneArray,
    checkIfNoDashboardDataIsAvailable,
} from '../../utils/agGridUtils';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import { Grid, Typography } from '@mui/material';
import DashboardTotals from './DashboardTotals';
import DashboardChart from './DashboardChart';
import DashboardTable from './DashboardTable';
import { getAllLocationFromHEMT } from '../../services/telefonica';
import { getLatestLocationDeltaUploadDateForAllLocations } from '../../services/locationDelta';
import { getLatestCapacityReportUploadDateForAllLocations } from '../../services/capacityReport';
import { getTotalEventsForAllLocations } from '../../services/event';
import dayjs from 'dayjs';
import { orderBy } from 'lodash';
import DashboardTEFTableCard from './DashboardTEFTableCard';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const getTotals = async (dateFrom, dateTo, worktype) => {
    const { totalForecastDeltas } = await getTotalForecastDeltaValuesByDateRangeAndWorktype(dateFrom, dateTo, worktype);
    const { totalBookedBookings } = await getTotalBookedBookingsByDateRangeAndWorktype(dateFrom, dateTo, worktype);
    const totalPositiveAvailableBookings = totalForecastDeltas.positive - totalBookedBookings.positive;
    const totalNegativeAvailableBookings = totalForecastDeltas.negative - totalBookedBookings.negative;
    const totalAvailableBookings = {
        positive: totalPositiveAvailableBookings,
        negative: totalNegativeAvailableBookings,
    };

    return { totalForecastDeltas, totalBookedBookings, totalAvailableBookings };
};

const getChartData = async (dateFrom, dateTo, worktype) => {
    const bookedCalls = await getAllBookedBookingsByDateRangeAndWorktype(dateFrom, dateTo, worktype);
    const forecastDeltaCalls = await getAllForecastDeltasByDateRangeAndWorktype(dateFrom, dateTo, worktype);

    return { bookedCalls, forecastDeltaCalls };
};

const getTableData = async (dateFrom, dateTo, worktype) => {
    const locationsList =
        worktype === WorktypeSelectOptions.ALL_WORKTYPES.value
            ? await getAllDistinctLocations()
            : await getAllLocationsByWorktype(worktype);
    const latesBookedBookingsList = await getSumOfBookedBookingsByDateAndWorktypeGroupByLocation(
        dateFrom,
        dateTo,
        worktype,
        RecordCreatedAt.LATEST
    );
    const oldestBookedBookingsList = await getSumOfBookedBookingsByDateAndWorktypeGroupByLocation(
        dateFrom,
        dateTo,
        worktype,
        RecordCreatedAt.OLDEST
    );

    return { locationsList, latesBookedBookingsList, oldestBookedBookingsList };
};

const createMapWithTableRows = (bookingsList) => {
    let rowsMap = new Map();
    rowsMap.set('latestBookings', {});
    rowsMap.set('difference', {});
    rowsMap = addBookingsInMap(bookingsList, rowsMap);

    return rowsMap;
};

const addBookingsInMap = (list, rowsMap) => {
    for (let i = 0; i < list.length; i++) {
        rowsMap.set('latestBookings', {
            ...rowsMap.get('latestBookings'),
            [`locationId${list[i].locationId}`]: list[i].latestBookings,
        });
        rowsMap.set('difference', {
            ...rowsMap.get('difference'),
            [`locationId${list[i].locationId}`]: list[i].difference,
        });
    }

    return rowsMap;
};

const combineLatestAndOldestBookings = (latesBookedBookingsList, oldestBookedBookingsList) => {
    let combinedResult = [];
    if (latesBookedBookingsList.length > 0) {
        latesBookedBookingsList.map((latestBooking) => {
            let oldestBookedBooking = oldestBookedBookingsList.find(
                (oldestBooking) => oldestBooking.locationId === latestBooking.locationId
            );
            let difference = parseInt(latestBooking.bookedBookings) - parseInt(oldestBookedBooking.bookedBookings);
            combinedResult = [
                ...combinedResult,
                {
                    latestBookings: latestBooking.bookedBookings,
                    oldestBookings: oldestBookedBooking.bookedBookings,
                    difference,
                    locationId: latestBooking.locationId,
                },
            ];

            return combinedResult;
        });
    }

    return combinedResult;
};

const getResults = async (dateFrom, dateTo, worktype) => {
    const totals = await getTotals(dateFrom, dateTo, worktype);
    const { bookedCalls, forecastDeltaCalls } = await getChartData(dateFrom, dateTo, worktype);
    const chartData = combineChartDataInOneArray(bookedCalls, forecastDeltaCalls);
    const tableData = await getTableData(dateFrom, dateTo, worktype);

    return { totals, chartData, tableData };
};

const createColumnDefs = (locations, allLocations) => {
    let columns = [];

    columns = [
        ...columns,
        {
            field: 'bookingType',
            headerName: '',
            maxWidth: 80,
            cellClass: 'grid-cell-centered',
            pinned: 'left',
            cellRenderer: (params) => {
                if (params.value === 'latestBookings') {
                    return (
                        <span>
                            <PhoneIcon style={{ verticalAlign: 'middle' }}></PhoneIcon>
                        </span>
                    );
                } else if (params.value === 'difference') {
                    return (
                        <span>
                            <PhoneDisabledIcon
                                style={{ transform: 'rotate(90deg)', verticalAlign: 'middle' }}
                            ></PhoneDisabledIcon>
                        </span>
                    );
                }
            },
        },
    ];

    for (let i = 0; i < locations.length; i++) {
        let locationFromTelefonica = allLocations.find((location) => location.id === locations[i].locationId);
        columns = [
            ...columns,
            {
                field: `locationId${locations[i].locationId}`,
                headerName: locationFromTelefonica ? locationFromTelefonica.city : locations[i].id,
                minWidth: 120,
                cellClass: 'grid-cell-centered',
                cellStyle: {
                    justifyContent: 'center',
                },
                cellRenderer: (params) => {
                    let result =
                        params.data.bookingType === 'latestBookings' ? (
                            <>
                                <Typography
                                    align="left"
                                    variant="h5"
                                    style={{ color: 'var(--primary)', verticalAlign: 'middle' }}
                                >
                                    {params.value}
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Typography align="left" variant="subtitle2" style={{ color: 'gray' }}>
                                    Änderungen
                                </Typography>
                                <Typography
                                    align="left"
                                    variant="h5"
                                    style={{ color: 'gray', verticalAlign: 'middle' }}
                                >
                                    {params.value}
                                </Typography>
                            </>
                        );
                    return result;
                },
            },
        ];
    }
    return columns;
};

function DashboardTEF({ dateFrom, dateTo, worktype, dispatchAlert }) {
    const isMounted = useIsMounted();

    const [totals, setTotals] = useState({
        totalForecastDeltas: { positive: 0, negative: 0 },
        totalBookedBookings: { positive: 0, negative: 0 },
        totalAvailableBookings: { positive: 0, negative: 0 },
    });
    const [chartData, setChartData] = useState([]);
    const [showSlotIntervalOnChart, setShowSlotIntervalOnChart] = useState(false);

    const [tableRows, setTableRows] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [allLocationsFromHEMT, setAllLocationsFromHEMT] = useState([]);

    const tefTablesCommonColumnDefs = [
        {
            field: 'city',
            headerName: 'Standort',
            valueGetter: (params) => `${params.data.cityName} - ${params.data.cityCode}`,
        },
        {
            field: 'value',
            headerName: 'Letzte Meldung',
        },
    ];
    const [latestLocationDeltaUploadRows, setLatestLocationDeltaUploadRows] = useState([]);
    const [latestCapacityReportUploadRows, setLatestCapacityReportUploadRows] = useState([]);
    const [totalEventsRows, setTotalEventsRows] = useState([]);

    const setTotalsValues = ({ totalForecastDeltas, totalBookedBookings, totalAvailableBookings }) => {
        setTotals({
            totalForecastDeltas,
            totalBookedBookings,
            totalAvailableBookings,
        });
    };

    const setTableData = useCallback(
        (tableData) => {
            const { locationsList, latesBookedBookingsList, oldestBookedBookingsList } = tableData;
            const bookingsList = combineLatestAndOldestBookings(latesBookedBookingsList, oldestBookedBookingsList);
            const columnDefinitions = createColumnDefs(locationsList, allLocationsFromHEMT);
            setColumnDefs(columnDefinitions);
            const rowsMap = createMapWithTableRows(bookingsList);
            const rows = createTableRows(rowsMap, 'bookingType');
            setTableRows(rows);
        },
        [allLocationsFromHEMT]
    );

    const getTableRowsForCommonTables = async (allLocationsFromHEMT, getData, setRows, valueType = 'latestUpload') => {
        getData().then((res) => {
            let rows = [];
            for (const hemtLocation of allLocationsFromHEMT) {
                const data = res.find((loc) => loc.locationId === hemtLocation.id);
                rows.push({
                    cityCode: hemtLocation.code,
                    cityName: hemtLocation.city,
                    ...(valueType === 'latestUpload' && { latestUpload: data?.value ? dayjs(data.value) : null }),
                    value: !data?.value
                        ? ''
                        : valueType === 'totalEvents'
                        ? data.value
                        : dayjs(data?.value).format('DD.MM.YYYY HH:mm'),
                });
            }

            rows = orderBy(
                rows,
                [
                    (row) => {
                        let valueToOrderBy = valueType === 'latestUpload' ? row.latestUpload : row.value;
                        return valueToOrderBy || '';
                    },
                ],
                ['desc']
            );
            setRows(rows);
        });
    };

    useQuery(getAllLocationFromHEMT, {
        onSuccess: async (data) => {
            setAllLocationsFromHEMT(data);
            await getTableRowsForCommonTables(
                data,
                getLatestLocationDeltaUploadDateForAllLocations,
                setLatestLocationDeltaUploadRows
            );
            await getTableRowsForCommonTables(
                data,
                getLatestCapacityReportUploadDateForAllLocations,
                setLatestCapacityReportUploadRows
            );
            await getTableRowsForCommonTables(data, getTotalEventsForAllLocations, setTotalEventsRows, 'totalEvents');
        },
    });

    useEffect(() => {
        if (dateFrom && dateTo && worktype) {
            getResults(dateFrom, dateTo, worktype).then((res) => {
                let diffBetweenDateRangeInMs = dateTo.diff(dateFrom);
                let diffBetweenDateRangeInDays = diffBetweenDateRangeInMs / (1000 * 3600 * 24);

                if (isMounted) {
                    diffBetweenDateRangeInDays < 5
                        ? setShowSlotIntervalOnChart(true)
                        : setShowSlotIntervalOnChart(false);

                    setChartData(res.chartData);
                    setTotalsValues({ ...res.totals });
                    setTableData(res.tableData);

                    checkIfNoDashboardDataIsAvailable(res, dispatchAlert);
                }
            });
        }
    }, [dateFrom, dateTo, worktype, setTableData, isMounted, dispatchAlert]);

    return (
        <>
            <Grid item xs={12} sm={12} md={7} lg={3}>
                <DashboardTotals totals={totals}></DashboardTotals>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={5}>
                <DashboardChart chartData={chartData} showSlotInterval={showSlotIntervalOnChart}></DashboardChart>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2, mb: 2 }}>
                <DashboardTable tableRows={tableRows} columnDefs={columnDefs}></DashboardTable>
            </Grid>
            <DashboardTEFTableCard
                title="Deltameldungen"
                tableRows={latestLocationDeltaUploadRows}
                columnDefs={tefTablesCommonColumnDefs}
            ></DashboardTEFTableCard>
            <DashboardTEFTableCard
                title="Potenzialmeldungen"
                tableRows={latestCapacityReportUploadRows}
                columnDefs={tefTablesCommonColumnDefs}
            ></DashboardTEFTableCard>
            <DashboardTEFTableCard
                title="Events"
                tableRows={totalEventsRows}
                columnDefs={[tefTablesCommonColumnDefs[0], { field: 'value', headerName: 'Total' }]}
            ></DashboardTEFTableCard>
        </>
    );
}

export default DashboardTEF;
