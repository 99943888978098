import { forwardRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import getLoadingOverlay from './LoadingOverlay';
import { makeStyles } from '@mui/styles';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './agGridStyle.scss';
import NoRowsOverlay from './NoRowsOverlay';

const DISTANCE_TO_TABLE = 250; //px

const useStyles = makeStyles(() => ({
    root: ({ height }) => ({
        height: height ? height : `calc(100vh - ${DISTANCE_TO_TABLE}px)`,
        width: '100%',
    }),
}));

let headerComponentParams = {
    template:
        '<div class="ag-cell-label-container" role="presentation" style="padding:0px">' +
        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
        '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: pre-line;"></span>' +
        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
        '  </div>' +
        '</div>',
};

const Table = forwardRef(({ children, height, defaultColDef, ...props }, ref) => {
    const classes = useStyles({ height });

    return (
        <div className={'ag-theme-material ' + classes.root}>
            <AgGridReact
                ref={ref}
                defaultColDef={{
                    editable: true,
                    sortable: true,
                    resizable: true,
                    filter: 'agMultiColumnFilter',
                    minWidth: 60,
                    flex: 1,
                    headerComponentParams: headerComponentParams,
                    ...defaultColDef,
                }}
                animateRows={true}
                undoRedoCellEditing={true}
                undoRedoCellEditingLimit={10}
                overlayLoadingTemplate={getLoadingOverlay('Loading...')}
                noRowsOverlayComponent={() => <NoRowsOverlay text="No data available"></NoRowsOverlay>}
                suppressCsvExport={true}
                suppressExcelExport={true}
                rowHeight={32}
                {...props}
            >
                {children}
            </AgGridReact>
        </div>
    );
});

export default Table;
