import api from './index';
import { setToken } from '../utils/authUtils';
import { appendQueryParamsToUrl } from '../utils';
import { fetchReadableStream } from './fetchReadableStream';

const BASE = '/capacityReport';

export const getCapacityReport = async ({ location, dateFrom, dateTo, plausibilityCheck }) => {
    return api
        .get(
            `${BASE}/report?location=${location}&dateFrom=${dateFrom}&dateTo=${dateTo}&plausibilityCheck=${plausibilityCheck}`,
            await setToken()
        )
        .then((res) => res.data);
};

export const createCapacityReport = async (data, userId, locationId) => {
    return api.post(`${BASE}/create`, { data, userId, locationId }, await setToken()).then((res) => res.data);
};

export const getAllCapacityReportsForPeriod = async ({ dateFrom, dateTo, location }) => {
    let url = `${BASE}/allForPeriod`;
    url = appendQueryParamsToUrl(url, { dateFrom, dateTo, location });
    return api.get(url, await setToken()).then((res) => res.data);
};

export const getLastCapacityReportUploadDate = async (location) => {
    return api.get(`${BASE}/lastUploadDate?location=${location}`, await setToken()).then((res) => res.data);
};

export const getLastCapacityReportUploadUserAndDate = async (location) => {
    return api.get(`${BASE}/lastUploadUserAndDate?location=${location}`, await setToken()).then((res) => res.data);
};

export const getLatestCapacityReportByWorktypes = async ({ location, user }) => {
    return api
        .get(`${BASE}/latestCapacityReportByWorktypes?location=${location}&user=${user}`, await setToken())
        .then((res) => res.data);
};

export const createOneCapacityReport = async (data) => {
    return api.post(`${BASE}/one`, { data }, await setToken()).then((res) => res.data);
};

export const getLatestCapacityReportUploadDateForAllLocations = async () => {
    return api.get(`${BASE}/latestCapacityReportUploadDateForAllLocations`, await setToken()).then((res) => res.data);
};

export const getLastCapacityReportUploadDatePerLocation = async (location) => {
    return api
        .get(`${BASE}/latestCapacityReportUploadDatePerLocation?location=${location}`, await setToken())
        .then((res) => res.data);
};

export const getCapacityReportAnalysisByWorktypes = async ({ location, planDate, planComparisonDate }) => {
    return api.get(
        `${BASE}/capacityReportAnalysisByWorktypes?location=${location}&planDate=${planDate}&planComparisonDate=${planComparisonDate}`,
        await setToken()
    );
};

export const getCapacityReportAnalysisByChannels = async ({ location, worktype, planDate, planComparisonDate }) => {
    return api
        .get(
            `${BASE}/capacityReportAnalysisByChannels?location=${location}&worktype=${worktype}&planDate=${planDate}&planComparisonDate=${planComparisonDate}`,
            await setToken()
        )
        .then((res) => res.data);
};

export const getCapacityReportAnalysis = async ({
    location,
    worktype,
    channel,
    task,
    capacityType,
    planDate,
    planComparisonDate,
    firstMonthColumnDate,
}) => {
    let url = `${BASE}/capacityReportAnalysis`;
    url = appendQueryParamsToUrl(url, {
        location,
        planDate,
        planComparisonDate,
        firstMonthColumnDate,
        worktype,
        channel,
        task,
        capacityType,
    });

    return api.get(url, await setToken()).then((res) => res.data);
};

export const getCapacityReportUploadedDatesInMonth = async ({ location, date }) => {
    return api
        .get(`${BASE}/uploadedDatesInMonth?location=${location}&date=${date}`, await setToken())
        .then((res) => res.data);
};

export const checkCapacityReportPlausibility = async (data, locationId) => {
    return api.post(`${BASE}/checkPlausibility`, { data, locationId }, await setToken()).then((res) => res.data);
};

export const uploadCapacityReports = async (data, setPercentage) => {
    return await fetchReadableStream(`${BASE}/upload`, data, setPercentage);
};
