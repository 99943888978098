import { useState, useEffect } from 'react';
import { getCookie } from '../utils';

export function useCookieWorktype(worktypes) {
    const [selectedWorktype, setSelectedWorktype] = useState('');

    useEffect(() => {
        if (worktypes.length > 0) {
            const cookieWorktype = parseInt(getCookie('worktype'));
            const cookieWorktypeInWorktypes = worktypes.find((worktype) => worktype.id === cookieWorktype);

            const selectedWorktypeId = cookieWorktypeInWorktypes ? cookieWorktypeInWorktypes.id : worktypes[0].id;

            setSelectedWorktype(selectedWorktypeId);
        }
    }, [worktypes]);

    return [selectedWorktype, setSelectedWorktype];
}
