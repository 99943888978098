import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

export const DeleteButtonCellRenderer = ({ clicked, value, ...props }) => {
    const cellRendererParams = props.colDef.cellRendererParams || { alwaysShow: false };

    const handleClick = () => clicked && clicked({ ...props, value });

    return cellRendererParams.alwaysShow || value ? (
        <IconButton aria-label="delete" onClick={handleClick} className="action-icon-button" style={{ padding: 0 }}>
            <DeleteOutlineIcon className="action-icon" style={{ color: 'var(--error)' }} />
        </IconButton>
    ) : null;
};
