import { Box, CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    circularProgress: {
        flex: 1,
        padding: 8,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: theme.zIndex.modal + 1,
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
    },
}));

function LoadingOverlay({ loading, percentage }) {
    const classes = useStyles();

    return (
        <>
            {loading && (
                <Box display="flex" justifyContent="center" alignItems="center" className={classes.circularProgress}>
                    <CircularProgress
                        size={120}
                        sx={{
                            color: 'secondary',
                        }}
                    />
                    <Typography position="absolute" style={{ fontWeight: '500', fontSize: '1.5em', marginTop: '22px' }}>
                        {percentage}%
                    </Typography>
                </Box>
            )}
        </>
    );
}

export default LoadingOverlay;
