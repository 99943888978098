import React from 'react';
import Container from '../components/Layout/Container';
import Logo from '../assets/Callmart logo.png';
import { Stack, Box, Typography } from '@mui/material';

const ErrorFallback = () => {
    return (
        <Container>
            <Stack direction="column" spacing={2} alignItems={'center'}>
                <Box maxWidth={'200px'}>
                    <img width={'100%'} src={Logo} alt={'callmart'} />
                </Box>
                <Box textAlign={'center'}>
                    <Typography variant={'h2'}>An error occurred</Typography>
                    <Typography>
                        We are sorry about this. <br />
                        Please refresh the page.
                    </Typography>
                </Box>
            </Stack>
        </Container>
    );
};

export default ErrorFallback;
