import Container from '../components/Layout/Container';
import { Grid, MenuItem } from '@mui/material';
import { useState, useEffect, useCallback, useRef } from 'react';
import { useIsMounted, useLocationsByLoggedInUserRole, useCookieLocation, useAlertMessage } from '../hooks';
import { getAllChannelsFromCapacitiesByFilters } from '../services/channel';
import { getAllWorktypeByLocation } from '../services/worktype';
import { getAllTasksFromCapacitiesByFilters } from '../services/task';
import LocationSelect from '../components/Selects/LocationSelect';
import CustomSelect from '../components/UI/CustomSelect';
import WorktypeSelect from '../components/Selects/WorktypeSelect';
import CustomDatePicker from '../components/UI/CustomDatePicker';
import { getCapacityReportAnalysis, getCapacityReportUploadedDatesInMonth } from '../services/capacityReport';
import {
    getPerformedCapacityReportAnalysis,
    getPerformedCapacityReportUploadedDatesInMonth,
} from '../services/performedCapacityReport';
import { CapacityAnalysisType } from '../enums/CapacityAnalysisType';
import { PickersDay } from '@mui/x-date-pickers';
import { CapacityAnalysisDateType } from '../enums/CapacityAnalysisDateType';
import CapacityHorizontalScrollTable from '../components/CapacityReport/CapacityHorizontalScrollTable';
import { createTableRows } from '../utils/agGridUtils';
import { addRowsInMap } from '../components/CapacityReport/helpers/utils';
import { getAllCapacityTypesFromCapacitiesByFilters } from '../services/capacityType';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import GridContainer from '../components/Layout/GridContainer';
dayjs.extend(utc);

const getMonthColumns = (startingMonth) => {
    const months = [];
    for (let i = 0; i < 6; i++) {
        months.push(dayjs(startingMonth, 'YYYY-MM-DD').subtract(i, 'months'));
    }

    return months.sort((date1, date2) => date1.diff(date2));
};

const getSelectOptionsWithoutAllOption = (selectedOption, options) => {
    if (selectedOption === -1) {
        return options.filter((option) => option.id !== -1).map((option) => option.id);
    } else {
        return [selectedOption];
    }
};

function CapacityAnalysis({ type }) {
    const isMounted = useIsMounted();
    const { dispatchAlert } = useAlertMessage();

    const gridRef = useRef(null);

    const [locationOptions] = useLocationsByLoggedInUserRole();
    const [selectedLocation, setSelectedLocation] = useCookieLocation(locationOptions);
    const [selectedWorktype, setSelectedWorktype] = useState('');
    const [worktypeOptions, setWorktypeOptions] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState('');
    const [channelOptions, setChannelOptions] = useState([]);
    const [selectedTask, setSelectedTask] = useState('');
    const [taskOptions, setTaskOptions] = useState([]);
    const [selectedCapacityType, setSelectedCapacityType] = useState('');
    const [capacityTypeOptions, setCapacityTypeOptions] = useState([]);

    const [planDate, setPlanDate] = useState(dayjs());
    const [planComparisonDate, setPlanComparisonDate] = useState(dayjs());
    const [firstMonthColumnDate, setFirstMonthColumnDate] = useState(dayjs());

    const [tableRows, setTableRows] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);

    const planDatePickerUploadDates = useRef();
    const planComparisonDatePickerUploadDates = useRef();

    const reportsPropertyName =
        type === CapacityAnalysisType.PERFORMED_CAPACITY_ANALYSIS ? 'performedCapacityReports' : 'capacityReports';

    const getExpandedRowsInTable = () => {
        const expandedRows = [];
        gridRef.current.api.forEachNode((node) => {
            if (node.displayed && node.expanded) {
                expandedRows.push(node);
            }
        });

        return expandedRows;
    };

    const setUploadedDatesForDatePicker = (location, date, dateType, reportType) => {
        const getReportUploadedDatesInMonth =
            reportType === CapacityAnalysisType.PERFORMED_CAPACITY_ANALYSIS
                ? getPerformedCapacityReportUploadedDatesInMonth
                : getCapacityReportUploadedDatesInMonth;

        getReportUploadedDatesInMonth({ location, date }).then((res) => {
            if (dateType === CapacityAnalysisDateType.PLAN_COMPARISON_DATE) {
                planComparisonDatePickerUploadDates.current = res;
            } else {
                planDatePickerUploadDates.current = res;
            }
        });
    };

    useEffect(() => {
        if (selectedLocation && planDate) {
            setUploadedDatesForDatePicker(selectedLocation, planDate, CapacityAnalysisDateType.PLAN_DATE, type);
        }
    }, [selectedLocation, planDate, type]);

    useEffect(() => {
        if (selectedLocation && planComparisonDate) {
            setUploadedDatesForDatePicker(
                selectedLocation,
                planComparisonDate,
                CapacityAnalysisDateType.PLAN_COMPARISON_DATE,
                type
            );
        }
    }, [selectedLocation, planComparisonDate, type]);

    const fetchWorktypes = (location) => {
        getAllWorktypeByLocation(location).then((res) => {
            const worktypes = [
                {
                    id: -1,
                    worktype: 'Alle Worktypes',
                },
                ...res,
            ];

            setWorktypeOptions(worktypes);
            setSelectedWorktype(worktypes[0].id);
        });
    };

    const fetchChannels = (location, worktypes) => {
        getAllChannelsFromCapacitiesByFilters(location, worktypes).then((res) => {
            const channels = [
                {
                    id: -1,
                    name: 'Alle Kanäle',
                },
                ...res,
            ];
            setChannelOptions(channels);
            setSelectedChannel(channels[0].id);
        });
    };

    const fetchTasks = (location, worktypes, channels) => {
        getAllTasksFromCapacitiesByFilters(location, worktypes, channels).then((res) => {
            const tasks = [
                {
                    id: -1,
                    name: 'Alle Tätigkeiten',
                },
                ...res,
            ];
            setTaskOptions(tasks);
            setSelectedTask(tasks[0].id);
        });
    };

    const fetchCapacityTypes = (location, worktypes, channels, tasks) => {
        getAllCapacityTypesFromCapacitiesByFilters(location, worktypes, channels, tasks).then((res) => {
            const capacityTypes = [
                {
                    id: -1,
                    name: 'Alle Capacity Types',
                },
                ...res,
            ];
            setCapacityTypeOptions(capacityTypes);
            setSelectedCapacityType(capacityTypes[0].id);
        });
    };

    useEffect(() => {
        if (selectedLocation) {
            fetchWorktypes(selectedLocation);
        }
    }, [selectedLocation, isMounted]);

    useEffect(() => {
        if (selectedLocation && selectedWorktype) {
            const worktypes = getSelectOptionsWithoutAllOption(selectedWorktype, worktypeOptions);

            if (worktypes.length > 0) {
                fetchChannels(selectedLocation, worktypes);
            } else {
                setChannelOptions([]);
                setSelectedChannel('');
            }
        }
    }, [selectedLocation, selectedWorktype, worktypeOptions]);

    useEffect(() => {
        if (selectedLocation && selectedWorktype && selectedChannel) {
            const worktypes = getSelectOptionsWithoutAllOption(selectedWorktype, worktypeOptions);
            const channels = getSelectOptionsWithoutAllOption(selectedChannel, channelOptions);

            if (worktypes.length > 0 && channels.length > 0) {
                fetchTasks(selectedLocation, worktypes, channels);
            } else {
                setTaskOptions([]);
                setSelectedTask('');
            }
        }
    }, [selectedLocation, selectedWorktype, selectedChannel, worktypeOptions, channelOptions]);

    useEffect(() => {
        if (selectedLocation && selectedWorktype && selectedChannel && selectedTask) {
            const worktypes = getSelectOptionsWithoutAllOption(selectedWorktype, worktypeOptions);
            const channels = getSelectOptionsWithoutAllOption(selectedChannel, channelOptions);
            const tasks = getSelectOptionsWithoutAllOption(selectedTask, taskOptions);

            if (worktypes.length > 0 && channels.length > 0 && tasks.length > 0) {
                fetchCapacityTypes(selectedLocation, worktypes, channels, tasks);
            } else {
                setCapacityTypeOptions([]);
                setSelectedCapacityType('');
            }
        }
    }, [
        selectedLocation,
        selectedWorktype,
        selectedChannel,
        selectedTask,
        worktypeOptions,
        channelOptions,
        taskOptions,
    ]);

    const setRows = useCallback(
        (data, firstMonthColumnDate) => {
            const { reportPlanDate, reportPlanComparisonDate, reportDifference } = data;

            const monthColumns = getMonthColumns(firstMonthColumnDate);

            const rowsMapPlanDate = addRowsInMap(reportPlanDate, monthColumns, reportsPropertyName, 'Planstand');
            const reportPlanDateRows = createTableRows(rowsMapPlanDate, 'id');

            const rowsMapPlanComparisonDate = addRowsInMap(
                reportPlanComparisonDate,
                monthColumns,
                reportsPropertyName,
                'Planstand Vergleich'
            );
            const reportPlanComparisonDateRows = createTableRows(rowsMapPlanComparisonDate, 'id');

            const rowsMapDifference = addRowsInMap(reportDifference, monthColumns, reportsPropertyName, '+/-');
            const reportDifferenceRows = createTableRows(rowsMapDifference, 'id');

            const allRows = [...reportPlanDateRows, ...reportPlanComparisonDateRows, ...reportDifferenceRows];

            setTableRows(allRows);
        },
        [reportsPropertyName]
    );

    const getTableData = useCallback(async () => {
        try {
            const params = {
                location: selectedLocation,
                planDate,
                planComparisonDate,
                firstMonthColumnDate: dayjs.utc(firstMonthColumnDate),
                type: null,
            };

            if (
                !selectedLocation ||
                !selectedWorktype ||
                !selectedChannel ||
                !selectedTask ||
                !selectedCapacityType ||
                !planDate ||
                !planComparisonDate ||
                !firstMonthColumnDate
            )
                return;

            selectedWorktype !== -1 && (params.worktype = selectedWorktype);
            selectedChannel !== -1 && (params.channel = selectedChannel);
            selectedTask !== -1 && (params.task = selectedTask);
            selectedCapacityType !== -1 && (params.capacityType = selectedCapacityType);
            const res =
                type === CapacityAnalysisType.PERFORMED_CAPACITY_ANALYSIS
                    ? await getPerformedCapacityReportAnalysis(params)
                    : await getCapacityReportAnalysis(params);
            setRows(res, firstMonthColumnDate);
        } catch (error) {
            dispatchAlert({
                message: 'Beim Laden des Reports ist ein Fehler aufgetreten.',
                type: 'error',
            });
        }
    }, [
        dispatchAlert,
        selectedLocation,
        selectedWorktype,
        selectedChannel,
        selectedTask,
        selectedCapacityType,
        planDate,
        planComparisonDate,
        firstMonthColumnDate,
        type,
        setRows,
    ]);

    useEffect(() => {
        if (!selectedLocation) {
            return;
        }

        getTableData();
    }, [getTableData, selectedLocation]);

    const renderDayInPicker = (day, value, DayComponentProps, dateType) => {
        let className = '';
        if (
            (dateType === CapacityAnalysisDateType.PLAN_DATE &&
                planDatePickerUploadDates.current?.includes(dayjs(day).format('DD-MM-YYYY'))) ||
            (dateType === CapacityAnalysisDateType.PLAN_COMPARISON_DATE &&
                planComparisonDatePickerUploadDates.current?.includes(dayjs(day).format('DD-MM-YYYY')))
        ) {
            className = 'datepicker-circle-day';
        }

        return <PickersDay {...DayComponentProps} className={className} />;
    };

    const onMonthChangeDatePicker = (date, dateType, reportType) => {
        setUploadedDatesForDatePicker(selectedLocation, date, dateType, reportType);
    };

    return (
        <Container>
            <GridContainer>
                <Grid item xs={12} md={4} lg={3}>
                    <LocationSelect
                        selectedLocation={selectedLocation}
                        locationOptions={locationOptions}
                        onChange={setSelectedLocation}
                    ></LocationSelect>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <WorktypeSelect
                        selectedWorktype={selectedWorktype}
                        worktypeOptions={worktypeOptions}
                        onChange={setSelectedWorktype}
                    />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <CustomSelect
                        selectedValue={selectedChannel}
                        options={channelOptions}
                        onChange={setSelectedChannel}
                        label="Channel"
                    >
                        {channelOptions &&
                            channelOptions.map((channel) => (
                                <MenuItem value={channel.id} key={channel.id}>
                                    {channel.name}
                                </MenuItem>
                            ))}
                    </CustomSelect>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <CustomSelect
                        selectedValue={selectedTask}
                        options={taskOptions}
                        onChange={setSelectedTask}
                        label="Task"
                    >
                        {taskOptions.map((task) => (
                            <MenuItem value={task.id} key={task.id}>
                                {task.name}
                            </MenuItem>
                        ))}
                    </CustomSelect>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <CustomSelect
                        selectedValue={selectedCapacityType}
                        options={capacityTypeOptions}
                        onChange={setSelectedCapacityType}
                        label="Capacity Type"
                    >
                        {capacityTypeOptions.map((capacityType) => (
                            <MenuItem value={capacityType.id} key={capacityType.id}>
                                {capacityType.name}
                            </MenuItem>
                        ))}
                    </CustomSelect>
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                    <CustomDatePicker
                        date={planDate}
                        label="Planstand"
                        onChange={setPlanDate}
                        renderDay={(day, value, DayComponentProps) =>
                            renderDayInPicker(day, value, DayComponentProps, CapacityAnalysisDateType.PLAN_DATE)
                        }
                        onMonthChange={(value) =>
                            onMonthChangeDatePicker(value, CapacityAnalysisDateType.PLAN_DATE, type)
                        }
                    ></CustomDatePicker>
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                    <CustomDatePicker
                        date={planComparisonDate}
                        label="Planstand Vergleich"
                        onChange={setPlanComparisonDate}
                        renderDay={(day, value, DayComponentProps) =>
                            renderDayInPicker(
                                day,
                                value,
                                DayComponentProps,
                                CapacityAnalysisDateType.PLAN_COMPARISON_DATE
                            )
                        }
                        onMonthChange={(value) =>
                            onMonthChangeDatePicker(value, CapacityAnalysisDateType.PLAN_COMPARISON_DATE)
                        }
                    ></CustomDatePicker>
                </Grid>
                <CapacityHorizontalScrollTable
                    ref={gridRef}
                    firstMonth={firstMonthColumnDate}
                    onFirstMonthChanged={setFirstMonthColumnDate}
                    tableRows={tableRows}
                    expandedRows={expandedRows}
                    onExpandedRowsChanged={setExpandedRows}
                    getExpandedRowsInTable={getExpandedRowsInTable}
                    defaultColDef={{
                        editable: false,
                        filter: true,
                        sortable: true,
                    }}
                    tableType="CAPACITY_ANALYSIS"
                    suppressAggFuncInHeader={true}
                    groupDefaultExpanded={1}
                    getMonthColumns={getMonthColumns}
                ></CapacityHorizontalScrollTable>
            </GridContainer>
        </Container>
    );
}

export default CapacityAnalysis;
