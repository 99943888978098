import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loader from '../Loaders/Loader';

function ProtectedRoute({ component, ...props }) {
    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => <Loader />,
    });

    return <Component {...props} />;
}

export default ProtectedRoute;
