import { forwardRef } from 'react';
import { Alert as MuiAlert, Snackbar } from '@mui/material';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SnackbarAlert({ message, handleClose, anchorOrigin }) {
    return (
        <Snackbar
            open={message?.useSnackbar && message?.show}
            autoHideDuration={message?.autoHideDuration ?? 2000}
            onClose={handleClose}
            anchorOrigin={anchorOrigin}
        >
            <Alert onClose={handleClose} severity={message?.type}>
                {message?.message}
            </Alert>
        </Snackbar>
    );
}

export default SnackbarAlert;
