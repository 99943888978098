import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../enums/QueryKey';
import { getAllSlotDaysInMonthAndYear } from '../../services/slot';
import dayjs from 'dayjs';

export function useAllSlotDaysInMonthAndYear(date) {
    const dateFrom = dayjs.utc(date).startOf('month');
    const dateTo = dayjs.utc(date).endOf('month');

    const { data, refetch } = useQuery({
        queryKey: [QueryKey.AllSlotDaysInMonthAndYear, { dateFrom, dateTo }],
        queryFn: () => getAllSlotDaysInMonthAndYear(dateFrom, dateTo),
        enabled: !!date,
    });

    return { data, refetch };
}
