import { useContext, useEffect, useState } from 'react';
import UserContext from '../store/User/UserContext';
import { getPreferedUserLocation } from '../utils/componentUtils';

export const usePreferedUserLocation = (selectedLocation) => {
    const loggedInUser = useContext(UserContext);
    const [location, setLocation] = useState(null);

    useEffect(() => {
        loggedInUser &&
            getPreferedUserLocation(loggedInUser).then((res) => {
                setLocation(res);
            });
    }, [loggedInUser, selectedLocation]);

    return [location, setLocation];
};
