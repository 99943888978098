import { useCallback, useEffect, useReducer, useRef } from 'react';

const initialState = {
    isLoading: false,
    data: undefined,
    error: undefined,
};

function reducer(state, action) {
    switch (action.type) {
        case 'loading':
            return { ...state, isLoading: true };
        case 'data':
            return { error: undefined, data: action.payload.data, isLoading: false };
        case 'error':
            return { error: action.payload.error, data: undefined, isLoading: false };
        default:
            throw new Error('Reducer not defined for action: ' + action.type);
    }
}

export function useQuery(asyncFn, config = {}) {
    const { onSuccess, onError } = config;

    const [state, dispatch] = useReducer(reducer, initialState);
    const isMountedRef = useRef(false);
    const queryFnRef = useRef(asyncFn);
    const onSuccessRef = useRef(onSuccess);
    const onErrorRef = useRef(onError);

    const queryFn = useCallback(() => {
        const fetch = queryFnRef.current;
        const onSuccess = onSuccessRef.current;
        const onError = onErrorRef.current;

        if (fetch) {
            dispatch({ type: 'loading' });
            fetch()
                .then((data) => {
                    if (isMountedRef.current) {
                        dispatch({ type: 'data', payload: { data } });
                        onSuccess && onSuccess(data);
                    }
                })
                .catch((error) => {
                    if (isMountedRef.current) {
                        dispatch({ type: 'error', payload: { error } });
                        onError && onError(error);
                    }
                });
        }
    }, []);

    useEffect(() => {
        isMountedRef.current = true;
        queryFn();

        return () => {
            isMountedRef.current = false;
        };
    }, [queryFn]);

    return {
        isLoading: state.isLoading,
        data: state.data,
        error: state.error,
        refetch: queryFn,
    };
}
