import { FormHelperText } from '@mui/material';
import { useCustomStyles } from '../../hooks';

const ReactHookFormErrorLabel = ({ fieldError, formErrors, fieldName }) => {
    const classes = useCustomStyles();

    return <FormHelperText className={classes.error}>{formErrors[fieldName].message}</FormHelperText>;
};

export default ReactHookFormErrorLabel;
