import api from './index';
import { setToken } from '../utils/authUtils';

const BASE = '/slot';

export const getAllSlotDaysInMonthAndYear = async (dateFrom, dateTo) => {
    return api
        .get(`${BASE}/getAllSlotDaysInMonthAndYear?dateFrom=${dateFrom}&dateTo=${dateTo}`, await setToken())
        .then((res) => res.data);
};

export const getAllSlotHoursInMonthAndYear = async (dateFrom, dateTo) => {
    return api
        .get(`${BASE}/getAllSlotHoursInMonthAndYear?dateFrom=${dateFrom}&dateTo=${dateTo}`, await setToken())
        .then((res) => res.data);
};
