import dayjs from 'dayjs';
import { usePreferedUserLocation } from '../../hooks';
import { getAllDatesInAMonth, getAllHourSlotsInATimeRange } from '../../utils/dateUtils';
import DownloadExcelButton from '../Buttons/DownloadExcelButton';

function PotentialsEmptyTemplateDownloadButton({
    selectedDate,
    columnNames,
    additionalColumns,
    hourSlotsStart,
    hourSlotsEnd,
    fileNamePrefix,
    channels,
    worktypes,
    selectedLocation,
}) {
    const [location] = usePreferedUserLocation(selectedLocation);

    const getXLSXSheets = (worktypes, currentMonthDates, allHourSlots) => {
        const sheetsData = [];
        for (let i = 0; i < worktypes.length; i++) {
            const sheetData = [];

            for (let j = 0; j < channels.length; j++) {
                let row = [];
                for (let k = 0; k < currentMonthDates.length; k++) {
                    const channel = channels[j].name;

                    for (let m = 0; m < allHourSlots.length; m++) {
                        const start_time = currentMonthDates[k].format('DD.MM.YYYY');
                        const hour = allHourSlots[m].split(':')[0];
                        const minute = allHourSlots[m].split(':')[1];
                        row = [start_time, hour, minute, 0];

                        if (columnNames.includes('Stadt') && location?.city) {
                            row.push(location?.city);
                        }

                        if (columnNames.includes('Standort Detail') && location?.code) {
                            row.push(location?.code);
                        }

                        row = [...row, worktypes[i].worktype, channel];

                        if (additionalColumns) {
                            for (let n = 0; n < additionalColumns.length; n++) {
                                row.push(additionalColumns[n].defaultValue);
                            }
                        }

                        sheetData.push(row);
                    }
                }
            }

            sheetsData.push({
                sheetName: worktypes[i].worktype,
                sheetData: sheetData,
            });
        }

        return sheetsData;
    };

    return (
        <DownloadExcelButton
            title="Potential value Template"
            fileName={`${fileNamePrefix}_${dayjs(selectedDate).format('MM_YYYY')}.xlsx`}
            location={selectedLocation}
            columnNames={columnNames}
            getSheetsData={() =>
                getXLSXSheets(
                    worktypes,
                    getAllDatesInAMonth(selectedDate),
                    getAllHourSlotsInATimeRange({ startTime: hourSlotsStart, endTime: hourSlotsEnd })
                )
            }
            buttonText="Leeres Template"
        ></DownloadExcelButton>
    );
}

export default PotentialsEmptyTemplateDownloadButton;
