import { useContext, useEffect, useState } from 'react';
import { getAllDistinctLocations } from '../../services/worktypeLocation';
import UserContext from '../../store/User/UserContext';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../enums/QueryKey';

export function useLocationsByLoggedInUserRole(params) {
    const prependAllLocationsOption = params?.prependAllLocationsOption || false;
    const loggedInUser = useContext(UserContext);
    let [locationOptions, setLocationOptions] = useState([]);

    const { data: locationsForTEFUsers } = useQuery({
        queryKey: [QueryKey.DistinctLocations],
        queryFn: getAllDistinctLocations,
        enable: loggedInUser?.role.callmart_tef,
        initialData: [],
    });

    useEffect(() => {
        if (loggedInUser) {
            let locations = [];
            locations = loggedInUser.role.callmart_tef
                ? locationsForTEFUsers
                : [loggedInUser.primaryLocation, ...loggedInUser?.additionalLocations];

            if (locations.length && prependAllLocationsOption) {
                locations = [
                    {
                        id: -1,
                        code: '',
                        city: 'Alle Standorte',
                    },
                    ...locations,
                ];
            }
            setLocationOptions(locations);
        }
    }, [loggedInUser, locationsForTEFUsers, prependAllLocationsOption]);

    return [locationOptions];
}
