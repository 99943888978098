import React, { forwardRef } from 'react';
import { Grid } from '@mui/material';
import { useCustomStyles } from '../../hooks';
import Table from '../AgGrid';

const TableGridItem = forwardRef(({ children, ...props }, ref) => {
    const classes = useCustomStyles();
    return (
        <Grid item xs={12} className={classes.tableGridItem}>
            <Table ref={ref} {...props}></Table>
        </Grid>
    );
});

export default TableGridItem;
