import { useEffect, useState, useRef, useCallback } from 'react';
import Container from '../components/Layout/Container';
import { Grid } from '@mui/material';
import { OfferedClusterSelect } from '../components/Offered/OfferedClusterSelect';
import CustomDatePicker from '../components/UI/CustomDatePicker';
import { handleDatePickerMonthChange } from '../utils/componentUtils';
import dayjs from 'dayjs';
import { useOfferedClusters } from '../hooks/api/offeredClusters';
import {
    useLatestOfferedReports,
    getLatestOfferedReportsQueryKey,
    useOfferedReportsExportData,
} from '../hooks/api/offeredReports';
import { createTableRows, getSlotTimeColumnDef } from '../utils/agGridUtils';
import { integerComparator } from '../components/AgGrid/comparators';
import { getAllDatesInAMonth, getAllHourSlotsInATimeRange } from '../utils/dateUtils';
import { createOneOfferedReport, createOfferedReport } from '../services/offeredReport';
import { useAlertMessage } from '../hooks';
import { parseStringToFloat } from '../utils/numberUtils';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '../services/queryClient';
import ExportToExcelButton from '../components/Buttons/ExportToExcelButton';
import TableGridItem from '../components/UI/TableGridItem';
import GridContainer from '../components/Layout/GridContainer';

const exportColumnNames = ['Worktype', 'Kanal', 'Task', 'Uhrzeit', 'Datum', 'Value'];

const addRowsInMap = (allDatesInAMonth, offeredReports, rowsMap) => {
    const allHourSlots = getAllHourSlotsInATimeRange({
        startTime: 0,
        endTime: 23,
        displayHoursInHH: true,
        displayMinutesInmm: true,
    });

    for (let i = 0; i < allHourSlots.length; i++) {
        const hourSlot = allHourSlots[i];

        const valuesPerDay = {};
        for (const date of allDatesInAMonth) {
            const slotDate = `${date.format('DD.MM.YYYY')}-${hourSlot}`;
            const offeredReport = offeredReports.find((r) => r.slotId.substring(0, 16) === slotDate);

            valuesPerDay[`day${date.format('DD')}`] = {
                value: offeredReport?.value,
                date: slotDate,
                slotId: offeredReport?.slotId,
            };
        }

        rowsMap.set(hourSlot, valuesPerDay);
    }

    return rowsMap;
};

export const createMapWithTableRows = (date, offeredReports) => {
    let rowsMap = new Map();
    rowsMap = addRowsInMap(date, offeredReports, rowsMap);

    return rowsMap;
};

const createColumnDefs = (allDatesInAMonth) => {
    const columns = [getSlotTimeColumnDef()];

    for (let i = 0; i < allDatesInAMonth.length; i++) {
        columns.push({
            field: `day${allDatesInAMonth[i].format('DD')}`,
            headerName: `${allDatesInAMonth[i].format('DD.MM')}`,
            resizable: true,
            comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
                return integerComparator({ valueA, valueB });
            },
            valueGetter: (params) => params?.data[`day${allDatesInAMonth[i].format('DD')}`]?.value,
            valueSetter: (params) => {
                params.data[`day${allDatesInAMonth[i].format('DD')}`].value =
                    params.newValue === '' ? 0 : params.newValue;
                return true;
            },
        });
    }

    return columns;
};

const OfferedReport = () => {
    const gridRef = useRef(null);
    const { dispatchAlert } = useAlertMessage();

    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [offeredClusters] = useOfferedClusters();
    const offeredClusterOptions = offeredClusters.map((offeredCluster) => ({
        ...offeredCluster,
        label: `${offeredCluster.worktype.worktype} - ${offeredCluster.channel.name} - ${offeredCluster.task.name}`,
    }));
    const [selectedOfferedCluster, setSelectedOfferedCluster] = useState(
        offeredClusterOptions.length > 0 ? offeredClusterOptions[0].id : ''
    );

    const { data: offeredReports } = useLatestOfferedReports({
        date: selectedDate,
        offeredCluster: selectedOfferedCluster,
    });
    const allDatesInAMonth = getAllDatesInAMonth(selectedDate);
    const columnDefs = allDatesInAMonth.length > 0 ? createColumnDefs(allDatesInAMonth) : [];
    const tableRows = createTableRows(createMapWithTableRows(allDatesInAMonth, offeredReports || []), 'slotTime');

    const { mutateAsync: handleCreateOneOfferedReport } = useMutation({
        mutationFn: createOneOfferedReport,
    });
    const { mutateAsync: handleCreateOfferedReport } = useMutation({
        mutationFn: createOfferedReport,
    });

    const { refetch: refetchExportData } = useOfferedReportsExportData({
        date: selectedDate,
        fetchImmediately: false,
    });

    const saveOfferedReport = useCallback(
        async ({ tableRows, reportData }) => {
            try {
                let isDataSaved = false;
                let numOfNewReports = 0;
                if (reportData) {
                    await handleCreateOneOfferedReport(reportData);
                    isDataSaved = true;
                } else {
                    const result = await handleCreateOfferedReport({
                        data: tableRows,
                        offeredClusterId: selectedOfferedCluster,
                    });
                    isDataSaved = numOfNewReports > 0 ? true : false;
                    numOfNewReports = result.numOfNewReports;
                }

                if (isDataSaved) {
                    dispatchAlert({
                        message: 'Offered Report gespeichert',
                        type: 'success',
                    });
                } else if (numOfNewReports === 0) {
                    dispatchAlert({
                        message: 'Geben Sie gültige Daten ein',
                        type: 'error',
                    });
                }
            } catch (error) {
                dispatchAlert({
                    message: 'Offered Report konnte nicht gespeichert werden',
                    type: 'error',
                });
            } finally {
                const queryKey = getLatestOfferedReportsQueryKey(selectedDate, selectedOfferedCluster);
                queryClient.invalidateQueries(queryKey);
            }
        },
        [dispatchAlert, handleCreateOfferedReport, handleCreateOneOfferedReport, selectedDate, selectedOfferedCluster]
    );

    const handleCellEdit = async (params) => {
        const enteredValue = parseStringToFloat(params.value);
        if (enteredValue && Number.isFinite(enteredValue)) {
            saveOfferedReport({
                reportData: {
                    ...params.data[params.column.colId],
                    value: enteredValue,
                    offeredClusterId: selectedOfferedCluster,
                },
            });
        } else {
            dispatchAlert({
                message: 'Geben Sie eine gültige Zahl ein',
                type: 'error',
            });
        }
    };

    const handleCellValueChange = (params) => {
        params.data[params.column.colId].changed = true;
    };

    const onPasteEnd = () => {
        let numOfInvalidValues = 0;
        let numOfChangedCells = 0;
        for (const row of tableRows) {
            for (const cell of Object.keys(row)) {
                if (row[cell].changed === true) {
                    numOfChangedCells++;
                    const newValue = parseStringToFloat(row[cell].value);
                    if (Number.isNaN(newValue)) {
                        numOfInvalidValues++;
                    }
                }
            }
        }
        if (numOfInvalidValues === numOfChangedCells) {
            dispatchAlert({
                message: 'Geben Sie gültige Daten ein',
                type: 'error',
            });
        } else {
            saveOfferedReport({
                tableRows,
            });
        }
    };

    const getExportFileName = () => {
        return `Offered_Report_${dayjs(selectedDate).format('MM.YYYY')}.xlsx`;
    };

    const getExportData = (offeredReports) => {
        return offeredReports.data;
    };

    useEffect(() => {
        if (offeredClusters.length) {
            setSelectedOfferedCluster(offeredClusters[0].id);
        }
    }, [offeredClusters]);

    return (
        <Container>
            <GridContainer>
                <Grid item xs={12} md={5} lg={4} xl={3}>
                    <OfferedClusterSelect
                        selectedCluster={selectedOfferedCluster}
                        onChange={setSelectedOfferedCluster}
                        offeredClusterOptions={offeredClusterOptions}
                    ></OfferedClusterSelect>
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                    <CustomDatePicker
                        date={selectedDate}
                        views={['year', 'month']}
                        openTo="month"
                        onChange={(newValue) => handleDatePickerMonthChange(selectedDate, newValue, setSelectedDate)}
                    ></CustomDatePicker>
                </Grid>
                <Grid item xs={3} md={1} lg={1} xl={1}>
                    <ExportToExcelButton
                        columnNames={exportColumnNames}
                        sheetName="Tabelle1"
                        getFileName={getExportFileName}
                        wbTitle="Offered_Report"
                        wbSubject="Export Offered Report"
                        getDataFromDb={refetchExportData}
                        getExportData={getExportData}
                    />
                </Grid>
                <TableGridItem
                    ref={gridRef}
                    rowData={tableRows}
                    defaultColDef={{
                        editable: true,
                        filter: true,
                        sortable: true,
                    }}
                    columnDefs={columnDefs}
                    onPasteEnd={onPasteEnd}
                    onCellEditingStopped={handleCellEdit}
                    onCellValueChanged={handleCellValueChange}
                ></TableGridItem>
            </GridContainer>
        </Container>
    );
};

export default OfferedReport;
