import { List, ListItem, Typography, ListItemIcon, ListItemText } from '@mui/material';
import ResponsiveDialog from '../UI/Dialogs/ResponsiveDialog';
import CircleIcon from '@mui/icons-material/Circle';

function WorktypeListDialog({ worktypes, open, onClose }) {
    return (
        <ResponsiveDialog title="" open={open} onClose={onClose}>
            <Typography variant="subtitle1">
                Es gab Probleme mit deinem Upload. Die folgenden Worktypes konnten nicht identifiziert werden, für die
                restlichen Worktypes wurde der Import erfolgreich abgeschlossen.
            </Typography>
            <List>
                {worktypes &&
                    worktypes.map((worktype, i) => (
                        <ListItem key={i}>
                            <ListItemIcon>
                                <CircleIcon style={{ color: 'var(--primary)' }} />
                            </ListItemIcon>
                            <ListItemText primary={worktype} />
                        </ListItem>
                    ))}
            </List>
        </ResponsiveDialog>
    );
}

export default WorktypeListDialog;
