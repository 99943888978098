import api from './index';
import { setToken } from '../utils/authUtils';
import { appendQueryParamsToUrl } from '../utils';
import { fetchReadableStream } from './fetchReadableStream';

const BASE = '/strategicVolumeReport';

export const getStrategicVolumeReport = async (location, dateFrom, dateTo, user, releaseType) => {
    let url = appendQueryParamsToUrl(BASE, { location, dateFrom, dateTo, user, releaseType });

    return api.get(url, await setToken()).then((res) => res.data);
};

export const createOneStrategicVolumeReport = async (data) => {
    return api.post(`${BASE}/one`, { data }, await setToken()).then((res) => res.data);
};

export const createStrategicVolumeReport = async (data, userId) => {
    return api.post(`${BASE}/create`, { data, userId }, await setToken()).then((res) => res.data);
};

export const markStrategicVolumeReportsRelease = async (data) => {
    return api.put(`${BASE}/markRelease`, { data }, await setToken()).then((res) => res.data);
};

export const compareStrategicVolumeAndCapacityReport = async (location, dateFrom, dateTo, user, releaseType) => {
    let url = `${BASE}/compareWithCapacityReport`;
    url = appendQueryParamsToUrl(url, { location, dateFrom, dateTo, user, releaseType });

    return api.get(url, await setToken()).then((res) => res.data);
};

export const uploadStrategicVolumes = async (data, setPercentage) => {
    return await fetchReadableStream(`${BASE}/upload`, data, setPercentage);
};

export const getLastStrategicVolumeUploadDate = async (location) => {
    return api.get(`${BASE}/lastUploadDate?location=${location}`, await setToken()).then((res) => res.data);
};
