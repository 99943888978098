import { useState } from 'react';
import { MessageContext } from './MessageContext';

const MessageContextProvider = ({ children }) => {
    const [message, setMessage] = useState({ show: false, type: 'info', message: '', useSnackbar: true });

    return <MessageContext.Provider value={{ message, setMessage }}>{children}</MessageContext.Provider>;
};

export default MessageContextProvider;
