import api from './index';
import { setToken } from '../utils/authUtils';
import { appendQueryParamsToUrl } from '../utils';

const BASE = '/working-unit';

export const getWorkingUnits = async ({ location } = {}) => {
    let url = `${BASE}`;
    url = appendQueryParamsToUrl(url, { location });

    return api.get(url, await setToken()).then((res) => res.data);
};
