import { useState, useEffect, useContext, useRef } from 'react';
import CustomDatePicker from '../components/UI/CustomDatePicker';
import PercentageLoadingOverlay from '../components/Loaders/PercentageLoadingOverlay';
import { Grid, Typography, MenuItem } from '@mui/material';
import Container from '../components/Layout/Container';
import {
    useAlertMessage,
    useCookieWorktype,
    useLocationsByLoggedInUserRole,
    useCookieLocation,
    useSelectedChannel,
    useWorktypes,
    useChannels,
    useHEMTLocations,
    useLastLocationDeltaUploadDate,
    useAllSlotDaysInMonthAndYear,
    useLatestLocationDeltas,
} from '../hooks';
import { parseXLSXData } from '../utils/xlsxUtils';
import { uploadLocationDeltas, getAllLocationDeltaByBookingId } from '../services/locationDelta';
import CardPopover from '../components/UI/CardPopover';
import LocationDeltasHistory from '../components/LocationDelta/LocationDeltasHistory';
import { useCellPopover } from '../hooks/useCellPopover';
import { integerComparator } from '../components/AgGrid/comparators';
import LocationDeltaEmptyTemplateDownloadButton from '../components/LocationDelta/LocationDeltaEmptyTemplateDownloadButton';
import UserContext from '../store/User/UserContext';
import LocationDeltaCurrentDataDownloadButton from '../components/LocationDelta/LocationDeltaCurrentDataDownloadButton';
import WorktypeSelect from '../components/Selects/WorktypeSelect';
import getSocketURL from '../mixins/getSocketURL';
import ExportToExcelButton from '../components/Buttons/ExportToExcelButton';
import { getLocationDeltaForExcelExport } from '../services/locationDelta';
import {
    setCommonStateAfterDataUpload,
    showSuccessfulUploadNotification,
    showErrorUploadNotification,
    handleDatePickerMonthChange,
} from '../utils/componentUtils';
import { getShortDayNameFromDate, setUploadedMonthAndYearToDate } from '../utils/dateUtils';
import { ChannelOptions } from '../enums/ChannelOptions';
import CustomSelect from '../components/UI/CustomSelect';
import dayjs from 'dayjs';
import UserRoleLocation from '../components/General/UserRoleLocation';
import UploadXlsxFileButton from '../components/Buttons/UploadXlsxFileButton';
import { queryClient } from '../services/queryClient';
import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '../enums/QueryKey';
import * as Sentry from '@sentry/react';
import { isStringValidJSON } from '../utils';
import { getSlotTimeColumnDef } from '../utils/agGridUtils';
import { useCustomStyles } from '../hooks';
import TableGridItem from '../components/UI/TableGridItem';
import GridContainer from '../components/Layout/GridContainer';
const { socket, SocketEvents } = getSocketURL();

const exportColumnNames = ['Worktype', 'Stadt', 'START_TIME', 'Uhrzeit', 'Standortdelta', 'Meldung', 'Grund', 'Kanal'];

const createColumnDefs = (slotDaysInMonth) => {
    const columns = [getSlotTimeColumnDef({ headerName: 'Datum Intervall', maxWidth: 80 })];

    for (let i = 0; i < slotDaysInMonth.length; i++) {
        const dayName = getShortDayNameFromDate(slotDaysInMonth[i].date, 'de');
        columns.push({
            field: `day${dayjs(slotDaysInMonth[i].date).format('DD')}`,
            headerName: `${dayjs(slotDaysInMonth[i].date).format('DD.MM')}\n${dayName}`,
            wrapText: true,
            resizable: true,
            comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
                return integerComparator({ valueA, valueB });
            },
        });
    }

    return columns;
};

const LocationDeltas = () => {
    const classes = useCustomStyles();

    const loggedInUser = useContext(UserContext);
    const { dispatchAlert } = useAlertMessage();
    const gridRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [monthForUploading, setMonthForUploading] = useState(dayjs().format('YYYY-MM'));

    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [locationOptions] = useLocationsByLoggedInUserRole();
    const [selectedLocation, setSelectedLocation] = useCookieLocation(locationOptions);
    const [allLocationsFromHEMT] = useHEMTLocations();
    const [worktypeOptions] = useWorktypes({ location: selectedLocation });
    const [selectedWorktype, setSelectedWorktype] = useCookieWorktype(worktypeOptions);
    const [channelOptions] = useChannels({ name: [ChannelOptions.INBOUND_VOICE.name, ChannelOptions.CHAT.name] });
    const [selectedChannel, setSelectedChannel] = useSelectedChannel(channelOptions);

    const { mutateAsync: handleUploadLocationDeltas } = useMutation({ mutationFn: uploadLocationDeltas });
    const lastUploadDate = useLastLocationDeltaUploadDate(selectedLocation, selectedWorktype);

    const { showPopover, hoveredCell, hoveredCellHistoryList, popoverOpen, popoverClose } = useCellPopover();

    const { data: slotDaysInMonthAndYear } = useAllSlotDaysInMonthAndYear(selectedDate);
    const columnDefs = slotDaysInMonthAndYear?.length > 0 ? createColumnDefs(slotDaysInMonthAndYear) : [];
    const { data: tableRows } = useLatestLocationDeltas({
        date: selectedDate,
        location: selectedLocation,
        worktype: selectedWorktype,
        channel: selectedChannel,
    });

    const cellClicked = async (params) => {
        if (params.column.colId.startsWith('day')) {
            const day = params.column.colId.replace('day', '');
            if (params.data[`bookingIdDay${day}`]) {
                const locationDeltasList = await getAllLocationDeltaByBookingId(params.data[`bookingIdDay${day}`]);

                popoverOpen(true, params.event.target, locationDeltasList);
            }
        }
    };

    const uploadFiles = (files, closeDropzoneDialog) => {
        setLoading(true);

        const file = files[0];

        const reader = new FileReader();
        reader.onload = async (e) => {
            const dataToUpload = parseXLSXData(e);

            await handleUploadLocationDeltas({
                dataToUpload,
                locations: allLocationsFromHEMT,
                monthForUploading,
                setPercentage,
            })
                .then(async (res) => {
                    const response = res[0];
                    if (!response || !isStringValidJSON(response)) {
                        console.log('Location delta upload response: ', res);
                    }
                    const resData = JSON.parse(response);
                    const { success, locationForUploading, areNewDeltasCreated, errorMessage } = resData;

                    if (success) {
                        if (areNewDeltasCreated) {
                            showSuccessfulUploadNotification(dispatchAlert);
                            socket.emit(SocketEvents.uploadedLocationDelta, {
                                monthForUploading,
                                locationForUploading,
                            });
                        } else {
                            dispatchAlert({
                                message: 'There are not new deltas to be uploaded',
                                type: 'warning',
                            });
                        }
                    } else {
                        showErrorUploadNotification({
                            dispatchAlert,
                            errorMessage,
                            customErrorMessage:
                                'Der Upload dauert länger als normal. Bitte aktualisiere die Seite in einigen Minuten und überprüfe deinen Upload.',
                        });
                    }
                })
                .catch(async (error) => {
                    Sentry.captureException(error);
                    showErrorUploadNotification({
                        dispatchAlert,
                        errorMessage: error.message,
                        customErrorMessage:
                            'Leider gab es Probleme mit deinem Upload. Versuche es später noch einmal oder wende dich an einen Administrator.',
                    });
                })
                .finally(async () => {
                    setCommonStateAfterDataUpload(setLoading, setPercentage, closeDropzoneDialog);
                    queryClient.invalidateQueries([
                        QueryKey.LastUploadDate + '-locationDelta',
                        { location: selectedLocation, worktype: selectedWorktype },
                    ]);
                });
        };
        reader.readAsArrayBuffer(file);
    };

    const getExportFileName = () => {
        return `Standortdelta_${dayjs(selectedDate).format('MM.YYYY')}.xlsx`;
    };

    useEffect(() => {
        if (loggedInUser?.role?.callmart_tef) {
            socket.on(SocketEvents.uploadedLocationDelta, ({ monthForUploading, locationForUploading }) => {
                if (locationOptions.length > 0 && selectedWorktype && worktypeOptions.length > 0) {
                    const uploadedDate = setUploadedMonthAndYearToDate(selectedDate, monthForUploading);
                    setSelectedDate(uploadedDate);

                    let uploadedLocation = locationForUploading.id;
                    let uploadedLocationInUserLocations = locationOptions.find(
                        (location) => location.id === uploadedLocation
                    );
                    if (uploadedLocationInUserLocations) {
                        setSelectedLocation(uploadedLocation);
                        setSelectedWorktype(worktypeOptions[0].id);
                        setSelectedChannel(channelOptions[0].id);
                    }
                }
            });
        }

        return () => {
            socket.removeAllListeners();
        };
    }, [
        locationOptions,
        worktypeOptions,
        channelOptions,
        selectedWorktype,
        selectedDate,
        loggedInUser,
        setSelectedLocation,
        setSelectedChannel,
        setSelectedWorktype,
    ]);

    return (
        <Container>
            <GridContainer classes={loading ? classes.containerOpacity : ''}>
                {loggedInUser && (
                    <UserRoleLocation
                        loggedInUser={loggedInUser}
                        selectedLocation={selectedLocation}
                        locationOptions={locationOptions}
                        onChange={setSelectedLocation}
                    ></UserRoleLocation>
                )}
                <Grid item xs={12} md={4} lg={2} xl={2}>
                    <WorktypeSelect
                        selectedWorktype={selectedWorktype}
                        worktypeOptions={worktypeOptions}
                        onChange={setSelectedWorktype}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={2} xl={2}>
                    <CustomSelect
                        selectedValue={selectedChannel}
                        options={channelOptions}
                        onChange={setSelectedChannel}
                        label={'Channel'}
                    >
                        {channelOptions &&
                            channelOptions.map((channel) => (
                                <MenuItem value={channel.id} key={channel.id}>
                                    {channel.name}
                                </MenuItem>
                            ))}
                    </CustomSelect>
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                    <CustomDatePicker
                        date={selectedDate}
                        views={['year', 'month']}
                        openTo="month"
                        onChange={(newValue) => handleDatePickerMonthChange(selectedDate, newValue, setSelectedDate)}
                    ></CustomDatePicker>
                </Grid>
                <Grid item xs={9} md={6} lg={2}>
                    <Typography variant="subtitle1">
                        Letztes Update: <div>{lastUploadDate}</div>
                    </Typography>
                </Grid>
                {loggedInUser?.role?.callmart_tef && (
                    <>
                        <Grid item xs={3} md={1} lg={1} xl={1}>
                            <ExportToExcelButton
                                columnNames={exportColumnNames}
                                sheetName="Tabelle1"
                                getFileName={getExportFileName}
                                wbTitle="Standortdelta"
                                wbSubject="Export Standortdelta"
                                getDataFromDb={() =>
                                    getLocationDeltaForExcelExport(
                                        dayjs(selectedDate).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
                                        dayjs(selectedDate).endOf('month').format('YYYY-MM-DD HH:mm:ss')
                                    )
                                }
                            />
                        </Grid>
                    </>
                )}

                {loggedInUser?.role?.callmart_osp && (
                    <Grid item container xs={12} md={6} lg={6}>
                        <LocationDeltaEmptyTemplateDownloadButton
                            selectedDate={selectedDate}
                            selectedLocation={selectedLocation}
                            allWorktypes={worktypeOptions}
                        />
                        <LocationDeltaCurrentDataDownloadButton
                            date={selectedDate}
                            selectedLocation={selectedLocation}
                            worktypes={worktypeOptions}
                        />
                        <UploadXlsxFileButton
                            buttonName="Upload"
                            monthForUploading={monthForUploading}
                            onChangeMonthForUploading={setMonthForUploading}
                            uploadFiles={uploadFiles}
                            dialogTitle="Deltas hochladen"
                        ></UploadXlsxFileButton>
                    </Grid>
                )}
                <TableGridItem
                    ref={gridRef}
                    rowData={tableRows}
                    defaultColDef={{
                        editable: false,
                        filter: true,
                        sortable: true,
                    }}
                    columnDefs={columnDefs}
                    onCellClicked={cellClicked}
                ></TableGridItem>
            </GridContainer>
            <PercentageLoadingOverlay loading={loading} percentage={percentage}></PercentageLoadingOverlay>
            {showPopover && (
                <CardPopover anchorEl={hoveredCell} onClose={popoverClose}>
                    <LocationDeltasHistory locationDeltasHistory={hoveredCellHistoryList} />
                </CardPopover>
            )}
        </Container>
    );
};

export default LocationDeltas;
