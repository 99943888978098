import { useMemo } from 'react';
import dayjs from 'dayjs';

const MatchingCapacityReportTooltip = (props) => {
    const row = useMemo(() => props.api.getDisplayedRowAtIndex(props.rowIndex), [props.api, props.rowIndex]);
    const matchingCapacityReport = useMemo(
        () => row.data[props.column.colId].capacityReport,
        [props.column.colId, row.data]
    );
    const diffWithCapacity = useMemo(
        () => row.data[props.column.colId].differenceWithCapacity,
        [props.column.colId, row.data]
    );

    return diffWithCapacity !== undefined ? (
        <div
            style={{
                backgroundColor: 'white',
                color: 'black',
                borderRadius: '10px',
                border: '1px solid grey',
                boxShadow: '10px 10px 5px #D3D3D3',
                padding: '20px 10px',
            }}
        >
            {matchingCapacityReport && (
                <span>
                    {dayjs(matchingCapacityReport.createdAt).format('DD.MM.')}: {matchingCapacityReport.value}
                </span>
            )}
            {!matchingCapacityReport && <span>Keine Daten verfügbar</span>}
        </div>
    ) : (
        // Required so that AgGrid doesn't throw an error about the tooltip not being created in time
        <div></div>
    );
};

export default MatchingCapacityReportTooltip;
