import { useQuery } from '@tanstack/react-query';
import { getLastLocationDeltaUploadDate, getLatestLocationDeltas } from '../../services/locationDelta';
import { QueryKey } from '../../enums/QueryKey';
import { formatLastUploadDate } from '../../utils/dateUtils';
import dayjs from 'dayjs';
import { ifDateLocationAndWorktype } from '../../utils/componentUtils';

export function useLastLocationDeltaUploadDate(location) {
    const { data } = useQuery({
        queryKey: [QueryKey.LastUploadDate + '-locationDelta', { location }],
        queryFn: () => getLastLocationDeltaUploadDate(location),
        enabled: !!location,
    });

    return formatLastUploadDate(data);
}

export function useLatestLocationDeltas({ date, location, worktype, channel }) {
    const dateFrom = dayjs.utc(date).startOf('month');
    const dateTo = dayjs.utc(date).endOf('month');

    const { data, refetch } = useQuery({
        queryKey: [QueryKey.LatestLocationDeltas, { dateFrom, dateTo, location, worktype, channel }],
        queryFn: () => getLatestLocationDeltas({ dateFrom, dateTo, location, worktype, channel }),
        enabled: ifDateLocationAndWorktype({ date, location, worktype }),
    });

    return { data, refetch };
}
