import { Typography, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
const LastUpload = ({ showInfoIcon, onClick, date }) => {
    const handleClick = (event) => {
        onClick(event);
    };
    return (
        <Typography variant="subtitle1">
            Letztes Update:
            <div>
                <span>{date}</span>
                {showInfoIcon && (
                    <IconButton
                        aria-label="info"
                        onClick={handleClick}
                        sx={{
                            position: 'absolute',
                            color: 'black',
                            marginTop: '-5px',
                        }}
                    >
                        <InfoIcon />
                    </IconButton>
                )}
            </div>
        </Typography>
    );
};

export default LastUpload;
