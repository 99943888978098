import { NUM_OF_MONTH_COLUMNS_FOR_CAPACITY_REPORT, CapacityTypesPlausibilityCheck } from './constants';
import dayjs from 'dayjs';
import { CapacityType } from '../../../enums/CapacityType';
import { parseStringToFloat } from '../../../utils/numberUtils';
import { CapacityReportType } from '../../../enums/CapacityReportType';
import { getUpcomingMonths } from '../../../utils/dateUtils';

export const isCapacityReportPresent = (capacityReport1, capacityReport2, capacityTypeName) => {
    return (
        capacityReport1.capacityType.name === capacityTypeName &&
        capacityReport1.task.id === capacityReport2.task.id &&
        capacityReport1.worktype.id === capacityReport2.worktype.id &&
        capacityReport1.channel.id === capacityReport2.channel.id
    );
};

export const getCapacityTypesToCheck = (capacityTypeName, paidTimeInfluentalCapacityTypes) => {
    const capacityTypesToCheck = [];
    if (
        CapacityTypesPlausibilityCheck.CUSTOMER_WORKLOAD.influentialCapacityTypes.some(
            (capacityType) => capacityType.name === capacityTypeName
        )
    ) {
        capacityTypesToCheck.push(CapacityTypesPlausibilityCheck.CUSTOMER_WORKLOAD);
    }

    if (
        CapacityTypesPlausibilityCheck.FTE_PAID_AND_SENIOR_AGENT.influentialCapacityTypes.some(
            (capacityType) => capacityType.name === capacityTypeName
        )
    ) {
        capacityTypesToCheck.push(CapacityTypesPlausibilityCheck.FTE_PAID_AND_SENIOR_AGENT);
    }
    if (paidTimeInfluentalCapacityTypes.some((capacityType) => capacityType.name === capacityTypeName)) {
        capacityTypesToCheck.push({
            mainCapacityType: CapacityType.PAID_TIME.name,
            influentialCapacityTypes: paidTimeInfluentalCapacityTypes,
        });
    }

    return capacityTypesToCheck;
};

export const getPlausibilityCheckValues = (mainCapacityType, capacityTypesToCheck, cellParams, tableRows) => {
    let plausibilityCheck;

    for (const capacityTypeData of capacityTypesToCheck) {
        const capacityReport = tableRows.find((cr) =>
            isCapacityReportPresent(cr, cellParams.data, capacityTypeData.name)
        );

        if (!capacityReport) continue;
        else {
            const key =
                mainCapacityType === CapacityType.PAID_TIME.name
                    ? `${capacityTypeData.name}`
                    : `${capacityTypeData.variable}Value`;

            plausibilityCheck = {
                ...plausibilityCheck,
                mainCapacityType,
                [key]: parseStringToFloat(capacityReport[cellParams.column.colId].value),
            };

            if (mainCapacityType === capacityTypeData.name) {
                plausibilityCheck = {
                    ...plausibilityCheck,
                    mainCapacityReportId: capacityReport[cellParams.column.colId].capacityReportId,
                };
            }
        }
    }

    return { plausibilityCheck };
};

export const getMonthColumns = (firstMonth) => {
    return getUpcomingMonths(firstMonth, NUM_OF_MONTH_COLUMNS_FOR_CAPACITY_REPORT);
};

export const addRowsInMap = (list, monthColumns, capacityReportType, rowsHeader) => {
    let rowsMap = new Map();

    for (const item of list) {
        const key = `${item.id}`;

        const capacityReports = {
            capacityId: item.id,
            capacityTypeId: item.capacityTypeId,
            capacityType: item.capacityType,
            worktype: {
                id: item.workingUnit.worktypeLocation.worktype.id,
                name: item.workingUnit.worktypeLocation.worktype.worktype,
            },
            channel: {
                id: item.workingUnit.channel.id,
                name: item.workingUnit.channel.name,
            },
            task: {
                id: item.workingUnit.task.id,
                name: item.workingUnit.task.name,
            },
            rowsHeader: rowsHeader,
        };

        monthColumns.forEach((monthColumn) => {
            const month = dayjs(monthColumn).format('YYYY-MM');

            const capacityReport = item[capacityReportType].find((cr) => {
                return dayjs(new Date(cr.date)).format('YYYY-MM') === month;
            });
            const capacityReportValue = capacityReport ? capacityReport.value : null;
            capacityReports[`${month}`] = {
                value: capacityReportValue,
                originalValue: capacityReportValue,
                checked: capacityReport?.checked,
                capacityReportId: capacityReport?.id,
            };
        });

        if (rowsMap.has(key)) {
            rowsMap.set(key, {
                ...rowsMap.get(key),
                ...capacityReports,
            });
        } else {
            rowsMap.set(key, {
                ...capacityReports,
            });
        }
    }

    return rowsMap;
};

export const getDateOptionsForExportDialog = ({ reportType }) => {
    const numOfPreviousMonths = reportType === CapacityReportType.CAPACITY_REPORT ? 6 : 12;
    const previousMonthsStartPoint = reportType === CapacityReportType.CAPACITY_REPORT ? 0 : 1;
    const nextMonthsStartPoint = reportType === CapacityReportType.CAPACITY_REPORT ? 1 : 0;

    const previousMonths = [];
    const nextMonths = [];
    for (let i = previousMonthsStartPoint; i < numOfPreviousMonths; i++) {
        previousMonths.push({
            month: dayjs().subtract(i, 'months'),
            monthName: dayjs().subtract(i, 'months').format('MMMM YYYY'),
        });
    }

    let currentMonth = dayjs().startOf('month');
    const endOfNextYear = dayjs().add(1, 'year').endOf('year').startOf('month');
    const monthsInBetween = endOfNextYear.diff(currentMonth, 'months');
    for (let i = nextMonthsStartPoint; i <= monthsInBetween; i++) {
        nextMonths.push({
            month: dayjs().add(i, 'months'),
            monthName: dayjs().add(i, 'months').format('MMMM YYYY'),
        });
    }

    return { previousMonths, nextMonths };
};
