import { getLocalesNumberFormatter } from './index';
import { integerComparator } from '../components/AgGrid/comparators';
const moment = require('moment-timezone');
moment.tz.setDefault('Europe/Berlin');

export const createTableRows = (rowsMap, firstColumnField) => {
    let rows = [];

    for (let key of rowsMap.keys()) {
        const row = {
            [firstColumnField]: key,
            ...rowsMap.get(key),
        };

        rows = [...rows, row];
    }

    return rows;
};

export const addRowsWithStaticFieldNameInMap = (list, rowsMap, field, value) => {
    for (let i = 0; i < list.length; i++) {
        const key = getSlotTimeRowKey(list[i].slotDate);
        if (rowsMap.has(key)) {
            rowsMap.set(key, {
                ...rowsMap.get(key),
                [`${field}`]: list[i][value] ? list[i][value] : 0,
            });
        } else {
            rowsMap.set(key, {
                [`${field}`]: list[i][value] ? list[i][value] : 0,
            });
        }
    }

    return rowsMap;
};

export const addRowsWithDynamicFieldNameInMap = (list, rowsMap, field, value) => {
    for (let i = 0; i < list.length; i++) {
        const key = getSlotTimeRowKey(list[i].slotDate);
        if (rowsMap.has(key)) {
            rowsMap.set(key, {
                ...rowsMap.get(key),
                [`${field}${list[i][field]}`]: list[i][value],
            });
        } else {
            rowsMap.set(key, {
                [`${field}${list[i][field]}`]: list[i][value],
            });
        }
    }

    return rowsMap;
};

export const getSlotTimeRowKey = (slotDate, displaySeconds = false) => {
    const key = displaySeconds
        ? moment(new Date(slotDate)).format('HH:mm:ss')
        : moment(new Date(slotDate)).format('HH:mm');

    return key;
};

export const combineChartDataInOneArray = (bookedCalls, forecastDeltaCalls) => {
    const result = forecastDeltaCalls.map((forecastDeltaCall) => {
        const itemInBookedCalls = bookedCalls.find((bookedCall) => bookedCall.slotDate === forecastDeltaCall.slotDate);
        const formattedDate = moment(new Date(forecastDeltaCall.slotDate)).format('DD.MM');
        const slotInterval = new Date(forecastDeltaCall.slotDate).getTime();
        const slotDate = moment(new Date(forecastDeltaCall.slotDate)).format('DD.MM.YYYY HH:mm:ss');
        if (itemInBookedCalls) {
            return {
                ...forecastDeltaCall,
                ...itemInBookedCalls,
                formattedDate,
                slotInterval,
                slotDate,
            };
        } else {
            return {
                ...forecastDeltaCall,
                bookedBookings: '0',
                formattedDate,
                slotInterval,
                slotDate,
            };
        }
    });

    return result;
};

export const isEmptyChartData = (chartData) => !chartData?.length;

export const isEmptyTableData = (tableData) => {
    const { latesBookedBookingsList, oldestBookedBookingsList } = tableData;

    return !latesBookedBookingsList?.length && !oldestBookedBookingsList?.length;
};

export const areEmptyTotals = (totals) => {
    const { totalForecastDeltas, totalBookedBookings, totalAvailableBookings } = totals;

    return !totalForecastDeltas && !totalBookedBookings && !totalAvailableBookings;
};

export const checkIfNoDashboardDataIsAvailable = (data, dispatchAlert) => {
    if (isEmptyChartData(data.chartData) && isEmptyTableData(data.tableData) && areEmptyTotals({ ...data.totals })) {
        return dispatchAlert({
            message: 'No data available',
            type: 'error',
        });
    }
};

export const numberFormatter = (params) => {
    const value = params.value;
    const numberFormat = getLocalesNumberFormatter(params?.colDef?.valueFormatterParams?.options);

    if (value !== undefined && value !== null && value !== '') {
        return numberFormat.format(value);
    }

    return value;
};

export const checkIsTableRowExpanded = (expandedNode, tableRowNode) => {
    if (expandedNode.key !== tableRowNode.key) return false;
    if (expandedNode.level !== 0 && expandedNode.level !== 1 && expandedNode.level !== 2) return false;

    if (expandedNode.level === 1) return expandedNode?.parent?.key === tableRowNode?.parent?.key;
    else if (expandedNode.level === 2) return expandedNode?.parent?.parent?.key === tableRowNode?.parent?.parent?.key;

    return true;
};

export const createColumnDefsFromSlotDays = (slotDaysInMonth) => {
    let columns = [];

    let headerComponentParams = {
        template:
            '<div class="ag-cell-label-container" role="presentation">' +
            '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
            '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
            '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
            '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
            '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: pre-line;"></span>' +
            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
            '  </div>' +
            '</div>',
    };

    columns = [
        ...columns,
        {
            field: 'slotTime',
            headerName: 'Datum Intervall',
            maxWidth: 140,
            cellClass: 'grid-cell-centered',
            pinned: 'left',
        },
    ];

    for (let i = 0; i < slotDaysInMonth.length; i++) {
        const dayName = new Date(slotDaysInMonth[i].date).toLocaleString('en-us', { weekday: 'short' });
        columns = [
            ...columns,
            {
                field: `day${moment(slotDaysInMonth[i].date).format('DD')}`,
                headerName: `${moment(slotDaysInMonth[i].date).format('DD.MM')}\n${dayName}`,
                minWidth: 75,
                cellClass: 'grid-cell-centered',
                wrapText: true,
                headerComponentParams: headerComponentParams,
                resizable: true,
                comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
                    return integerComparator({ valueA, valueB });
                },
            },
        ];
    }

    return columns;
};

export const addRowsWithDayNamesAsColumnsInMap = (list, rowsMap, calculateTotals, propertyName) => {
    let totals = {};
    for (let i = 0; i < list.length; i++) {
        const key = getSlotTimeRowKey(list[i].slotDate, true);
        const day = moment(new Date(list[i].slotDate)).format('DD');

        if (calculateTotals) {
            totals[`day${day}`]
                ? (totals[`day${day}`] += list[i][propertyName])
                : (totals[`day${day}`] = list[i][propertyName]);
        }

        if (rowsMap.has(key)) {
            rowsMap.set(key, {
                ...rowsMap.get(key),
                [`day${day}`]: list[i][propertyName].toString(),
            });
        } else {
            rowsMap.set(key, {
                [`day${day}`]: list[i][propertyName].toString(),
            });
        }
    }

    if (calculateTotals && list.length) rowsMap.set('Gesamt', totals);

    return rowsMap;
};

// Acceptable date formats: DD.MM.YYYY; DD/MM/YYYY; DD-MM-YYYY
const monthToComparableNumber = (date) => {
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    const yearNumber = parseInt(date.substring(6, 10));
    const monthNumber = parseInt(date.substring(3, 5));
    const dayNumber = parseInt(date.substring(0, 2));
    return yearNumber * 10000 + monthNumber * 100 + dayNumber;
};

export const dateComparator = (date1, date2) => {
    const date1Number = monthToComparableNumber(date1);
    const date2Number = monthToComparableNumber(date2);
    if (date1Number === null && date2Number === null) {
        return 0;
    }
    if (date1Number === null) {
        return -1;
    }
    if (date2Number === null) {
        return 1;
    }
    return date1Number - date2Number;
};

export const getValueFromParamsDataIfKeyPresent = (params, key) => {
    if (params.data && params.data[key]) {
        return params?.data[key].value;
    }

    return null;
};

export const getValueForMonthColumn = (params, monthColumn) => {
    return getValueFromParamsDataIfKeyPresent(params, `${monthColumn.format('YYYY-MM')}`);
};

export const getSlotTimeColumnDef = ({ field = 'slotTime', headerName = '', maxWidth = 50 } = {}) => {
    return { field, headerName, maxWidth, cellClass: 'grid-cell-centered', pinned: 'left' };
};

export const stringToNumberFormatter = (params) => {
    if (params.value && typeof params.value === 'string' && params.value.includes(',')) {
        params.value = params.value.replace(',', '.');
    }
    return numberFormatter(params);
};
