import { useState, useEffect, useCallback } from 'react';
import {
    getAllBookedBookingsByDateRangeWorktypeAndLocation,
    getSumOfAllBookedBookingsByDateRangeWorktypeAndLocation,
    getSumOfBookedBookingsByDateAndLocationGroupByWorktype,
} from '../../services/bookingDelta';
import {
    getAllForecastDeltasByDateRangeWorktypeAndLocation,
    getSumOfAllForecastDeltaValuesByDateRangeWorktypeAndLocation,
} from '../../services/forecastDelta';
import { RecordCreatedAt } from '../../enums/RecordCreatedAt';
import { useIsMounted, useLastLocationDeltaUploadDate } from '../../hooks';
import {
    createTableRows,
    combineChartDataInOneArray,
    checkIfNoDashboardDataIsAvailable,
} from '../../utils/agGridUtils';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import { Grid, Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import DashboardTotals from './DashboardTotals';
import DashboardChart from './DashboardChart';
import DashboardTable from './DashboardTable';
import dayjs from 'dayjs';
import { getLastCapacityReportUploadDatePerLocation } from '../../services/capacityReport';
import CircleIcon from '@mui/icons-material/Circle';

const getTotals = async (dateFrom, dateTo, worktype, location) => {
    const { totalForecastDeltas } = await getSumOfAllForecastDeltaValuesByDateRangeWorktypeAndLocation(
        dateFrom,
        dateTo,
        worktype,
        location
    );
    const { totalBookedBookings } = await getSumOfAllBookedBookingsByDateRangeWorktypeAndLocation(
        dateFrom,
        dateTo,
        worktype,
        location,
        RecordCreatedAt.LATEST
    );
    const totalAvailableBookings = {
        positive: totalForecastDeltas.positive - totalBookedBookings.positive,
        negative: totalForecastDeltas.negative - totalBookedBookings.negative,
    };

    return { totalForecastDeltas, totalBookedBookings, totalAvailableBookings };
};

const getChartData = async (dateFrom, dateTo, worktype, location) => {
    const bookedCalls = await getAllBookedBookingsByDateRangeWorktypeAndLocation(dateFrom, dateTo, worktype, location);
    const forecastDeltaCalls = await getAllForecastDeltasByDateRangeWorktypeAndLocation(
        dateFrom,
        dateTo,
        worktype,
        location
    );

    return { bookedCalls, forecastDeltaCalls };
};

const getTableData = async (dateFrom, dateTo, locationId) => {
    const latesBookedBookingsList = await getSumOfBookedBookingsByDateAndLocationGroupByWorktype(
        dateFrom,
        dateTo,
        locationId,
        RecordCreatedAt.LATEST
    );
    const oldestBookedBookingsList = await getSumOfBookedBookingsByDateAndLocationGroupByWorktype(
        dateFrom,
        dateTo,
        locationId,
        RecordCreatedAt.OLDEST
    );

    return { latesBookedBookingsList, oldestBookedBookingsList };
};

const createMapWithTableRows = (bookingsList) => {
    let rowsMap = new Map();
    rowsMap.set('latestBookings', {});
    rowsMap.set('difference', {});
    rowsMap = addBookingsInMap(bookingsList, rowsMap);

    return rowsMap;
};

const addBookingsInMap = (list, rowsMap) => {
    for (let i = 0; i < list.length; i++) {
        rowsMap.set('latestBookings', {
            ...rowsMap.get('latestBookings'),
            [`worktypeId${list[i].worktypeId}`]: list[i].latestBookings,
        });
        rowsMap.set('difference', {
            ...rowsMap.get('difference'),
            [`worktypeId${list[i].worktypeId}`]: list[i].difference,
        });
    }

    return rowsMap;
};

const combineLatestAndOldestBookings = (latesBookedBookingsList, oldestBookedBookingsList) => {
    let combinedResult = [];
    if (latesBookedBookingsList.length > 0) {
        latesBookedBookingsList.map((latestBooking) => {
            let oldestBookedBooking = oldestBookedBookingsList.find(
                (oldestBooking) => oldestBooking.worktypeId === latestBooking.worktypeId
            );
            let difference = parseInt(latestBooking.bookedBookings) - parseInt(oldestBookedBooking.bookedBookings);
            combinedResult = [
                ...combinedResult,
                {
                    latestBookings: latestBooking.bookedBookings,
                    oldestBookings: oldestBookedBooking.bookedBookings,
                    difference,
                    worktypeId: latestBooking.worktypeId,
                },
            ];

            return combinedResult;
        });
    }

    return combinedResult;
};

const getResults = async (dateFrom, dateTo, worktype, location) => {
    const totals = await getTotals(dateFrom, dateTo, worktype, location);
    const { bookedCalls, forecastDeltaCalls } = await getChartData(dateFrom, dateTo, worktype, location);
    const chartData = combineChartDataInOneArray(bookedCalls, forecastDeltaCalls);
    const tableData = await getTableData(dateFrom, dateTo, location);

    return { totals, chartData, tableData };
};

const createColumnDefs = (worktypes) => {
    let columns = [];

    columns = [
        ...columns,
        {
            field: 'bookingType',
            headerName: '',
            maxWidth: 80,
            cellClass: 'grid-cell-centered',
            pinned: 'left',
            cellRenderer: (params) => {
                if (params.value === 'latestBookings') {
                    return (
                        <span>
                            <PhoneIcon style={{ verticalAlign: 'middle' }}></PhoneIcon>
                        </span>
                    );
                }

                if (params.value === 'difference') {
                    return (
                        <span>
                            <PhoneDisabledIcon
                                style={{ transform: 'rotate(90deg)', verticalAlign: 'middle' }}
                            ></PhoneDisabledIcon>
                        </span>
                    );
                }
            },
        },
    ];

    for (let i = 0; i < worktypes.length; i++) {
        columns = [
            ...columns,
            {
                field: `worktypeId${worktypes[i].id}`,
                headerName: worktypes[i].worktype,
                minWidth: 120,
                cellClass: 'grid-cell-centered',
                cellStyle: {
                    justifyContent: 'center',
                },
                cellRenderer: (params) => {
                    let result =
                        params.data.bookingType === 'latestBookings' ? (
                            <>
                                <Typography
                                    align="left"
                                    variant="h5"
                                    style={{ color: 'var(--primary)', verticalAlign: 'middle' }}
                                >
                                    {params.value}
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Typography align="left" variant="subtitle2" style={{ color: 'gray' }}>
                                    Änderungen
                                </Typography>
                                <Typography
                                    align="left"
                                    variant="h5"
                                    style={{ color: 'gray', verticalAlign: 'middle' }}
                                >
                                    {params.value}
                                </Typography>
                            </>
                        );
                    return result;
                },
            },
        ];
    }
    return columns;
};

function DashboardOSP({ dateFrom, dateTo, worktype, worktypes, loggedInUser, dispatchAlert }) {
    const isMounted = useIsMounted();

    const [totals, setTotals] = useState({
        totalForecastDeltas: { positive: 0, negative: 0 },
        totalBookedBookings: { positive: 0, negative: 0 },
        totalAvailableBookings: { positive: 0, negative: 0 },
    });
    const [chartData, setChartData] = useState([]);
    const [showSlotIntervalOnChart, setShowSlotIntervalOnChart] = useState(false);

    const [tableRows, setTableRows] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);

    const latestLocationDeltaUploadDate = useLastLocationDeltaUploadDate(loggedInUser?.primaryLocation?.id);
    const [latestCapacityReportUploadDates, setLatestCapacityReportUploadDates] = useState([]);

    const setTotalsValues = ({ totalForecastDeltas, totalBookedBookings, totalAvailableBookings }) => {
        setTotals({
            totalForecastDeltas,
            totalBookedBookings,
            totalAvailableBookings,
        });
    };

    const setTableData = useCallback((tableData, worktypesPerLocation) => {
        const columnDefinitions = createColumnDefs(worktypesPerLocation);
        setColumnDefs(columnDefinitions);
        const { latesBookedBookingsList, oldestBookedBookingsList } = tableData;
        const bookingsList = combineLatestAndOldestBookings(latesBookedBookingsList, oldestBookedBookingsList);
        const rowsMap = createMapWithTableRows(bookingsList);
        const rows = createTableRows(rowsMap, 'bookingType');
        setTableRows(rows);
    }, []);

    useEffect(() => {
        if (loggedInUser?.primaryLocation?.id) {
            const loggedInUserLocations = [loggedInUser.primaryLocation, ...loggedInUser.additionalLocations];
            const loggedInUserLocationsIds = loggedInUserLocations.map((location) => location.id);
            getLastCapacityReportUploadDatePerLocation(loggedInUserLocationsIds).then((res) => {
                let capacityReportPerLocation = [];

                for (const capacityReport of res) {
                    const location = loggedInUserLocations.find((loc) => loc.id === capacityReport.locationId);
                    location &&
                        capacityReportPerLocation.push({
                            city: location.city,
                            latestUpload: capacityReport.value
                                ? dayjs(capacityReport.value).format('DD.MM.YYYY HH:mm')
                                : '',
                        });
                }

                setLatestCapacityReportUploadDates(capacityReportPerLocation);
            });
        }
    }, [loggedInUser.primaryLocation, loggedInUser.additionalLocations]);

    useEffect(() => {
        if (dateFrom && dateTo && worktype) {
            getResults(dateFrom, dateTo, worktype, loggedInUser.primaryLocation.id).then((res) => {
                const diffBetweenDateRangeInMs = dateTo.diff(dateFrom);
                const diffBetweenDateRangeInDays = diffBetweenDateRangeInMs / (1000 * 3600 * 24);

                if (isMounted) {
                    diffBetweenDateRangeInDays < 5
                        ? setShowSlotIntervalOnChart(true)
                        : setShowSlotIntervalOnChart(false);

                    setChartData(res.chartData);
                    setTotalsValues({ ...res.totals });
                    setTableData(res.tableData, worktypes);

                    checkIfNoDashboardDataIsAvailable(res, dispatchAlert);
                }
            });
        }
    }, [
        dateFrom,
        dateTo,
        worktype,
        worktypes,
        loggedInUser.primaryLocation.id,
        setTableData,
        isMounted,
        dispatchAlert,
    ]);

    return (
        <>
            <Grid item xs={12} sm={12} md={7} lg={3}>
                <DashboardTotals totals={totals}></DashboardTotals>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={5}>
                <DashboardChart chartData={chartData} showSlotInterval={showSlotIntervalOnChart}></DashboardChart>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2, mb: 2 }}>
                <DashboardTable tableRows={tableRows} columnDefs={columnDefs}></DashboardTable>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography>Letzte Delta Meldung: {latestLocationDeltaUploadDate}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography>Letzte Potenzial Meldung: </Typography>
                <List>
                    {latestCapacityReportUploadDates.map((latesData) => (
                        <ListItem key={latesData.city}>
                            <ListItemIcon>
                                <CircleIcon style={{ color: 'var(--primary)' }} />
                            </ListItemIcon>
                            <ListItemText primary={`${latesData.city}: ${latesData.latestUpload}`} />
                        </ListItem>
                    ))}
                </List>
            </Grid>
        </>
    );
}

export default DashboardOSP;
