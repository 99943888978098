import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../enums/QueryKey';
import { getWorkingHours } from '../../services/workingHours';

export const useWorkingHours = (params) => {
    const worktype = params?.worktype;

    const { data } = useQuery({
        queryKey: [QueryKey.WorkingHours, { worktype }],
        queryFn: () => getWorkingHours({ worktype }),
    });

    return { data };
};
