import BackgroundImage from '../../assets/welcomeScreenBackground.jpg';
import UnetiqLogo from '../../assets/unetiq_logo_horizontal_white.png';
import TelefonicaLogo from '../../assets/telefonica-logo.png';
import './style.css';
import { makeStyles } from '@mui/styles';
import { useAuth0 } from '@auth0/auth0-react';
import Dashboard from '../Dashboard';

const useStyles = makeStyles((theme) => ({
    backgroundImage: {
        background: `url(${BackgroundImage}) no-repeat fixed`,
        paddingTop: theme.mixins.toolbar.minHeight,
        minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
        minWidth: '100vw',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },
}));

function HomePage() {
    const classes = useStyles();

    const { isAuthenticated } = useAuth0();

    return isAuthenticated ? (
        <Dashboard />
    ) : (
        <>
            <div className={classes.backgroundImage}>
                <div className="text">
                    <h1>Welcome to Telefonica Callmart</h1>
                    <h2>For login please click on the top right</h2>
                </div>
                <div className="logoDiv">
                    <div className="unetiqLogo">
                        <img src={UnetiqLogo} alt="Unetiq Logo" />
                    </div>
                    <span>
                        <img src={TelefonicaLogo} alt="Telefonica Logo" />
                    </span>
                </div>
            </div>
        </>
    );
}

export default HomePage;
