import { useAuth0 } from '@auth0/auth0-react';

import LoginButton from '../Buttons/LoginButton';
import LogoutButton from '../Buttons/LogoutButton';

const AuthenticationButton = () => {
    const { isAuthenticated } = useAuth0();

    return isAuthenticated ? <LogoutButton /> : <LoginButton />;
};

export default AuthenticationButton;
