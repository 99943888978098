import { useQuery } from '@tanstack/react-query';
import { getWorktypes, createWorktype, updateWorktype } from '../../services/worktype';
import { QueryKey } from '../../enums/QueryKey';
import { useUpdateRecord, useCreateRecord } from './crudOperations';

export function useWorktypes(params) {
    const location = params?.location;
    const prependAllWorktypesOption = params?.prependAllWorktypesOption;

    const queryKey = location ? [QueryKey.Worktypes, { location }] : [QueryKey.Worktypes];

    const { data: worktypes } = useQuery({
        queryKey,
        queryFn: () => getWorktypes({ location }),
        initialData: [],
    });

    if (worktypes.length && prependAllWorktypesOption) {
        return [
            [
                {
                    id: -1,
                    worktype: 'Alle Worktypes',
                },
                ...worktypes,
            ],
        ];
    }

    return [worktypes];
}

export function useCreateWorktype() {
    return useCreateRecord({
        createRecordFn: createWorktype,
        fetchRecordsQueryKey: `${QueryKey.Worktypes}`,
        recordName: 'Worktype',
    });
}

export function useUpdateWorktype() {
    return useUpdateRecord({
        updateRecordFn: updateWorktype,
        fetchRecordsQueryKey: QueryKey.Worktypes,
        recordName: 'Worktype',
    });
}
