import { CardContent, CardActions, Card, Popover, Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import callmartLogo from '../assets/Callmart logo.png';
import { Link } from 'react-router-dom';
import packageJson from '../../package.json';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    card: {
        overflow: 'auto',
        minWidth: '360px !important',
        textAlign: 'left',
    },
    header: {
        fontSize: '1.5em',
        display: 'inline-block',
        verticalAlign: 'middle',
        paddingLeft: '8px',
    },
    actionsLinks: {
        color: 'var(--darkGrey)',
        fontSize: '0.8em',
    },
    impressum: {
        marginRight: '2px',
    },
    dataPrivacy: {
        marginLeft: '2px',
    },
}));

function VersionNotesImpressumPopover({ anchorEl, onClose }) {
    const classes = useStyles();

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const env = process.env.NODE_ENV;

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Card className={classes.card}>
                <CardContent>
                    <Avatar
                        alt="Callmart"
                        src={callmartLogo}
                        style={{ display: 'inline-block', verticalAlign: 'middle' }}
                    />
                    <span className={classes.header}> Telefonica Callmart</span>
                    <h3 style={{ marginBottom: '0px' }}>Neue Funktionen dieser Version:</h3>
                    <li>WFM Upload</li>
                    <li>Settings für Tasks, Capacities, Channels</li>
                    <li>Strategisches Volumen + Freigabe</li>
                    <li>Excel Upload für Potenziale</li>
                    <li>Archivierung für Deltas älter 6 Monate</li>
                    <li>Archivierung für Potenziale älter 12 Monate</li>

                    <span style={{ color: 'var(--darkGrey)' }}>
                        <p style={{ marginBottom: '0' }}>
                            Version: {packageJson.version}:{env}
                        </p>
                        <span>Problems? Contact: </span>
                        <a href="mailto:support.tef@unetiq.com" style={{ color: 'var(--primary)' }}>
                            support.tef@unetiq.com
                        </a>
                        <br />
                    </span>
                </CardContent>
                <CardActions>
                    <span style={{ color: 'var(--darkGrey)', fontSize: '1.2em' }}>
                        {new Date().getFullYear()} — <strong>&#169;Unetiq</strong>
                    </span>
                    <span style={{ textAlign: 'right !important', float: 'right' }}>
                        <Link className={clsx(classes.actionsLinks, classes.impressum)} to="/impressum" target="_blank">
                            Impressum
                        </Link>
                        <Link
                            className={clsx(classes.actionsLinks, classes.dataPrivacy)}
                            to="/dataprivacy"
                            target="_blank"
                        >
                            Datenschutzerklärung
                        </Link>
                    </span>
                </CardActions>
            </Card>
        </Popover>
    );
}

export default VersionNotesImpressumPopover;
