import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    container: (props) => ({ margin: props.wide ? '50px' : '20px' }),
}));

function Container({ children, wide }) {
    const classes = useStyles({ wide });

    return <div className={classes.container}>{children}</div>;
}

export default Container;
