import { useCallback } from 'react';
import { useMessage } from './useMessage';

export function useAlertMessage() {
    const { setMessage } = useMessage();

    const dispatchAlert = useCallback(
        (action) => {
            const type = action.type || 'info';
            const useSnackbar = action.useSnackbar === false ? action.useSnackbar : true;
            return setMessage({
                show: true,
                type,
                message: action.message,
                autoHideDuration: action?.autoHideDuration,
                useSnackbar,
            });
        },
        [setMessage]
    );

    return { dispatchAlert };
}
