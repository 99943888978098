import React from 'react';
import { TextField, FormControl } from '@mui/material';
import { Controller } from 'react-hook-form';
import ReactHookFormErrorLabel from './ReactHookFormErrorLabel';

const ReactHookFormTextField = ({
    name,
    label,
    control,
    isNumeric,
    defaultValue,
    rules,
    formErrors,
    inputProps,
    isMultiline,
    ...props
}) => {
    const inputType = isNumeric ? 'number' : 'text';

    return (
        <FormControl {...props} fullWidth={true}>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={rules}
                render={({ field, fieldState }) => (
                    <>
                        <TextField
                            {...field}
                            label={label}
                            type={inputType}
                            fullWidth
                            {...(inputProps && { InputProps: inputProps })}
                            multiline={isMultiline}
                            rows={props.rows || 1}
                            {...props}
                        />
                        {fieldState?.error && (
                            <ReactHookFormErrorLabel
                                fieldError={fieldState.error}
                                fieldName={name}
                                formErrors={formErrors}
                            ></ReactHookFormErrorLabel>
                        )}
                    </>
                )}
            />
        </FormControl>
    );
};

export default ReactHookFormTextField;
