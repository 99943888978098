import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
const duration = require('dayjs/plugin/duration');
dayjs.extend(isSameOrAfter);
dayjs.extend(utc);
dayjs.extend(duration);

export const addPadding = (value) => {
    return value.toString().padStart(2, '0');
};

export const formatDate = (date, isoStandard = true) => {
    const year = date.getFullYear();
    const month = addPadding(date.getMonth() + 1);
    const day = addPadding(date.getDate());

    const hours = addPadding(date.getHours());
    const minutes = addPadding(date.getMinutes());
    const seconds = addPadding(date.getSeconds());

    let formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    if (!isoStandard) {
        formattedDate = `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
    }

    return formattedDate;
};

export const getDateFromDatetime = (date, isoStandard = true) => {
    const year = date.getFullYear();
    const month = addPadding(date.getMonth() + 1);
    const day = addPadding(date.getDate());

    let formattedDate = `${year}-${month}-${day}`;
    if (!isoStandard) {
        formattedDate = `${day}.${month}.${year}`;
    }

    return formattedDate;
};

export const getTimeFromDatetime = (datetime) => {
    return datetime.toLocaleTimeString({ hour12: false });
};

export const getMonthAndYearFromDatetime = (date, isoStandard = true) => {
    const year = date.getFullYear();
    const month = addPadding(date.getMonth() + 1);

    let formattedMonthYear = `${year}-${month}`;
    if (!isoStandard) {
        formattedMonthYear = `${month}.${year}`;
    }

    return formattedMonthYear;
};

export const getCreatedAtForHistoryPopover = (createdAtString) => {
    const createdAtDate = dayjs(new Date(createdAtString)).format('DD.MM.');
    const createdAtTime = dayjs(new Date(createdAtString)).format('HH:mm');

    return `${createdAtDate} - ${createdAtTime}`;
};

export const getFirstDayOfMonth = (date) => {
    return dayjs(date).startOf('month').format('YYYY-MM-DD HH:mm:ss');
};

export const getLastDayOfMonth = (date) => {
    return dayjs(date).endOf('month').format('YYYY-MM-DD HH:mm:ss');
};

export const getNextSixMonths = () => {
    const nextSixMonths = [];
    for (let i = 1; i <= 6; i++) {
        nextSixMonths.push(dayjs().add(i, 'month'));
    }

    return nextSixMonths;
};

export const setUploadedMonthAndYearToDate = (date, monthForUploading) => {
    const year = parseInt(monthForUploading.split('-')[0]);
    const month = parseInt(monthForUploading.split('-')[1]);

    return date.year(year).month(month - 1);
};

export const isEndAfterStart = (start, end) => {
    return dayjs(end).isSameOrAfter(dayjs(start)) || 'Das Ende muss nach dem Startzeitpunkt liegen';
};

export const getDateRangeFromStartDateByAddingMonths = (startDate, numOfMonths) => {
    const dateFrom = dayjs.utc(startDate).startOf('month');
    const dateTo = dayjs
        .utc(dateFrom)
        .add(numOfMonths - 1, 'months')
        .endOf('month');

    return { dateFrom, dateTo };
};

export const getUpcomingMonths = (firstMonth, numOfMonths) => {
    let months = [];
    for (var i = 0; i < numOfMonths; i++) {
        months.push(dayjs(firstMonth, 'YYYY-MM-DD').add(i, 'months'));
    }

    return months;
};

export const isStringInYearMonthFormat = (value) => {
    const regex = /^\d{4}-\d{2}$/;
    return regex.test(value);
};

export const getDuration = (start, end) => dayjs.duration(dayjs(end).diff(dayjs(start)));

export const formatLastUploadDate = (date) => {
    if (date) {
        return `${dayjs(date).format('DD.MM.YYYY - HH:mm')} Uhr`;
    }

    return date;
};

export const getAllDatesInAMonth = (date) =>
    new Array(dayjs(date).daysInMonth()).fill(null).map((x, i) => dayjs(date).startOf('month').add(i, 'days'));

export const getAllHourSlotsInATimeRange = ({
    startTime,
    endTime,
    displayHoursInHH = false,
    displayMinutesInmm = false,
}) => {
    let allHourSlots = [];
    for (let i = startTime; i <= endTime; i++) {
        const hour = displayHoursInHH && i < 10 ? `0${i}` : i;
        allHourSlots = [...allHourSlots, `${hour}:${displayMinutesInmm ? '00' : '0'}`, `${hour}:30`];
    }

    return allHourSlots;
};

export const getShortDayNameFromDate = (date, locale) =>
    dayjs(date, 'YYYY-MM-DD')
        .locale(locale || 'en')
        .format('ddd');

export const getStartAndEndOfAMonth = (date) => {
    const dateFrom = dayjs(date).startOf('month');
    const dateTo = dayjs(date).endOf('month');
    return { dateFrom, dateTo };
};

export const weekDays = [
    { name: 'Montag', value: 1 },
    { name: 'Dienstag', value: 2 },
    { name: 'Mittwoch', value: 3 },
    { name: 'Donnerstag', value: 4 },
    { name: 'Freitag', value: 5 },
    { name: 'Samstag', value: 6 },
    { name: 'Sonntag', value: 7 },
];
