import { getStorage } from './index';
import { UserRole } from '../enums/UserRole';

export const setToken = async () => {
    const user = getStorage('user');

    if (user) {
        const token = user.token;
        return { headers: { Authorization: `Bearer ${token}` } };
    } else {
        throw Error('No logged in user found.');
    }
};

export const getToken = () => {
    const user = getStorage('user');

    if (user) {
        const token = user.token;
        return token;
    } else {
        throw Error('No logged in user found.');
    }
};

export const getUserRole = (user) => {
    if (!user) return null;

    return user.role.callmart_tef ? UserRole.TEF : UserRole.OSP;
};

export const isOSPUser = (user) => !user?.role?.callmart_tef && user?.role?.callmart_osp;
