import { Grid, FormControl, Typography, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import ReactHookFormSelect from '../ReactHookForm/ReactHookFormSelect';
import { Controller } from 'react-hook-form';
import { fieldIsRequiredMsg } from '../../utils/componentUtils';
import { RepetitionIntervalType, WeekDays } from './utils/serieDialogUtils';

const RepetitionIntervalSelector = ({
    currentMonthDates,
    ordinalNumbersForDayInMonth,
    control,
    name,
    defaultValue,
    rules,
    formErrors,
    ...props
}) => {
    return (
        <FormControl {...props} fullWidth={true}>
            <Controller
                name="repetitionIntervalType"
                control={control}
                defaultValue={defaultValue}
                rules={rules}
                render={({ field, fieldState }) => (
                    <RadioGroup {...field}>
                        <Grid container spacing={1}>
                            <Grid item sm={2}>
                                <FormControlLabel
                                    value={RepetitionIntervalType.DAY_NUMBER_REPETITION}
                                    control={<Radio color="primary" />}
                                    label=""
                                />
                            </Grid>
                            <Grid item sm={8}>
                                <ReactHookFormSelect
                                    name="repetitionIntervalDayNumber"
                                    control={control}
                                    label=""
                                    mainProp="text"
                                    valueProp="value"
                                    options={currentMonthDates?.map((date) => ({
                                        value: parseInt(date.format('DD')),
                                        text: parseInt(date.format('DD')),
                                    }))}
                                    rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                                    formErrors={formErrors}
                                />
                            </Grid>
                            <Grid item sm={2}>
                                <Typography variant="body1">Tag</Typography>
                            </Grid>
                            <Grid item sm={2} alignItems="flex-start">
                                <FormControlLabel
                                    value={RepetitionIntervalType.DAY_ORDINAL_NUMBER_REPETITION}
                                    control={<Radio color="primary" />}
                                    label=""
                                />
                            </Grid>
                            <Grid item sm={5}>
                                <ReactHookFormSelect
                                    name="repetitionIntervalDayOrdinalNumber"
                                    control={control}
                                    label=""
                                    mainProp="text"
                                    valueProp="value"
                                    options={ordinalNumbersForDayInMonth?.map((ordinalNumber) => ({
                                        value: ordinalNumber,
                                        text: ordinalNumber,
                                    }))}
                                    rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                                    formErrors={formErrors}
                                />
                            </Grid>
                            <Grid item sm={5}>
                                <ReactHookFormSelect
                                    name="repetitionIntervalDayOfWeek"
                                    control={control}
                                    label=""
                                    mainProp="text"
                                    valueProp="value"
                                    options={WeekDays}
                                    rules={{ required: { value: true, message: 'Day of week is required' } }}
                                />
                            </Grid>
                        </Grid>
                    </RadioGroup>
                )}
            />
        </FormControl>
    );
};

export default RepetitionIntervalSelector;
