import { Grid } from '@mui/material';
import LocationSelect from '../Selects/LocationSelect';

const UserRoleLocation = ({ selectedLocation, locationOptions, onChange }) => {
    return (
        <Grid item xs={12} md={4} lg={3}>
            <LocationSelect
                selectedLocation={selectedLocation}
                locationOptions={locationOptions}
                onChange={onChange}
            ></LocationSelect>
        </Grid>
    );
};

export default UserRoleLocation;
