import { Button, DialogActions, Typography } from '@mui/material';
import clsx from 'clsx';
import ResponsiveDialog from '../UI/Dialogs/ResponsiveDialog';
import { useCustomStyles } from '../../hooks';

const EditDeleteRecordOrSerieDialog = ({ showDialog, onClose, onConfirm, isDelete, recordName, serieId }) => {
    const classes = useCustomStyles();
    return (
        <ResponsiveDialog
            open={showDialog}
            onClose={onClose}
            actionButtons={
                <DialogActions className={clsx(classes.dialogActions, classes.marginBottom)}>
                    <Button onClick={onClose} variant="outlined">
                        Abbrechen
                    </Button>
                    {isDelete ? (
                        serieId ? (
                            <>
                                <Button onClick={() => onConfirm(true)} variant="outlined">
                                    Serie Löschen
                                </Button>
                                <Button onClick={() => onConfirm(false)} variant="outlined">
                                    Serienelement Löschen
                                </Button>
                            </>
                        ) : (
                            <Button onClick={() => onConfirm(false)} variant="outlined">
                                Bestätigen
                            </Button>
                        )
                    ) : (
                        <>
                            <Button onClick={() => onConfirm(false)} variant="outlined">
                                Serie Bearbeiten
                            </Button>
                            <Button onClick={() => onConfirm(true)} variant="outlined">
                                Serienelement Bearbeiten
                            </Button>
                        </>
                    )}
                </DialogActions>
            }
        >
            <Typography variant="body1">
                {isDelete
                    ? `Möchtest du dieses ${recordName} wirklich löschen ?`
                    : 'Möchtest du das Serienelement oder die gesamte Serie bearbeiten?'}
            </Typography>
        </ResponsiveDialog>
    );
};

export default EditDeleteRecordOrSerieDialog;
