import api from './index';
import { setToken } from '../utils/authUtils';
import { fetchReadableStream } from './fetchReadableStream';
import { appendQueryParamsToUrl } from '../utils';

const BASE = '/locationDelta';

export const uploadLocationDeltas = async ({ dataToUpload, locations, monthForUploading, setPercentage }) => {
    return await fetchReadableStream(`${BASE}`, { dataToUpload, locations, monthForUploading }, setPercentage);
};

export const getAllLocationDeltaByFilters = async (dateFrom, dateTo, location, worktype, channel) => {
    return api
        .get(
            `${BASE}/getAllLocationDeltaByFilters?dateFrom=${dateFrom}&dateTo=${dateTo}&location=${location}&worktype=${worktype}&channel=${channel}`,
            await setToken()
        )
        .then((res) => res.data);
};

export const getAllLocationDeltaByBookingId = async (bookingId) => {
    return api
        .get(`${BASE}/getAllLocationDeltaByBookingId?bookingId=${bookingId}`, await setToken())
        .then((res) => res.data);
};

export const getLocationDeltaForExcelExport = async (dateFrom, dateTo) => {
    return api
        .get(`${BASE}/getLocationDeltaForExcelExport?dateFrom=${dateFrom}&dateTo=${dateTo}`, await setToken())
        .then((res) => res.data);
};

export const getLocationDeltaByDateRangeLocationAndWorktype = async (dateFrom, dateTo, location, worktype) => {
    return api
        .get(
            `${BASE}/getLocationDeltaByDateRangeLocationAndWorktype?dateFrom=${dateFrom}&dateTo=${dateTo}&location=${location}&worktype=${worktype}`,
            await setToken()
        )
        .then((res) => res.data);
};

export const getLastLocationDeltaUploadDate = async (location) => {
    return api.get(`${BASE}/lastUploadDate?location=${location}`, await setToken()).then((res) => res.data);
};

export const getLatestLocationDeltaUploadDateForAllLocations = async () => {
    return api.get(`${BASE}/latestLocationDeltaUploadForAllLocations`, await setToken()).then((res) => res.data);
};

export const getLatestLocationDeltas = async ({ dateFrom, dateTo, location, worktype, channel }) => {
    let url = `${BASE}/latest`;
    url = appendQueryParamsToUrl(url, { dateFrom, dateTo, location, worktype, channel });
    return api.get(url, await setToken()).then((res) => res.data);
};
