import api from './index';
import { setToken } from '../utils/authUtils';
import { fetchReadableStream } from './fetchReadableStream';

const BASE = '/forecastDelta';

export const uploadForecast = async ({ dataToUpload, monthForUploading, setPercentage }) => {
    return await fetchReadableStream(`${BASE}`, { data: dataToUpload, monthForUploading }, setPercentage);
};

export const getTotalForecastDeltaValuesByDateRangeAndWorktype = async (dateFrom, dateTo, worktype) => {
    return api
        .get(
            `${BASE}/getTotalForecastDeltaValuesByDateRangeAndWorktype?dateFrom=${dateFrom}&dateTo=${dateTo}&worktype=${worktype}`,
            await setToken()
        )
        .then((res) => res.data);
};

export const getAllForecastDeltasByDateRangeAndWorktype = async (dateFrom, dateTo, worktype) => {
    return api
        .get(
            `${BASE}/getAllForecastDeltasByDateRangeAndWorktype?dateFrom=${dateFrom}&dateTo=${dateTo}&worktype=${worktype}`,
            await setToken()
        )
        .then((res) => res.data);
};

export const getSumOfAllForecastDeltaValuesByDateRangeWorktypeAndLocation = async (
    dateFrom,
    dateTo,
    worktype,
    location
) => {
    return api
        .get(
            `${BASE}/getSumOfAllForecastDeltaValuesByDateRangeWorktypeAndLocation?dateFrom=${dateFrom}&dateTo=${dateTo}&worktype=${worktype}&location=${location}`,
            await setToken()
        )
        .then((res) => res.data);
};

export const getAllForecastDeltasByDateRangeWorktypeAndLocation = async (dateFrom, dateTo, worktype, location) => {
    return api
        .get(
            `${BASE}/getAllForecastDeltasByDateRangeWorktypeAndLocation?dateFrom=${dateFrom}&dateTo=${dateTo}&worktype=${worktype}&location=${location}`,
            await setToken()
        )
        .then((res) => res.data);
};

export const getLastForecastDeltaUploadDate = async (worktype) => {
    return api.get(`${BASE}/lastUploadDate?worktype=${worktype}`, await setToken()).then((res) => res.data);
};
