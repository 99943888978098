import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../enums/QueryKey';
import { getAvailableAndBookedBookings, getAllAvailableBookingsByDateAndWorktype } from '../../services/bookingDelta';
import dayjs from 'dayjs';
import { ifDateAndWorktype, ifDateLocationAndWorktype } from '../../utils/componentUtils';

export function useAllAvailableBookingsByDateAndWorktype(date, worktype) {
    const dateFrom = dayjs.utc(date).startOf('month');
    const dateTo = dayjs.utc(date).endOf('month');

    const { data, refetch } = useQuery({
        queryKey: [QueryKey.AllAvailableBookingsByDateAndWorktype, { dateFrom, dateTo, worktype }],
        queryFn: () => getAllAvailableBookingsByDateAndWorktype(dateFrom, dateTo, worktype),
        enabled: ifDateAndWorktype({ date, worktype }),
    });

    return { data, refetch };
}

export function useAvailableAndBookedBookings({ date, location, worktype }) {
    const dateFrom = dayjs(date).startOf('month');
    const dateTo = dayjs(date).endOf('month');

    const { data } = useQuery({
        queryKey: [QueryKey.AvailableAndBookedBookings, { dateFrom, dateTo, location, worktype }],
        queryFn: () => getAvailableAndBookedBookings({ dateFrom, dateTo, location, worktype }),
        enabled: ifDateLocationAndWorktype({ date, location, worktype }),
    });

    return { data };
}
