import { useState, useEffect, useContext } from 'react';
import SelectExportDateRangeDialog from '../Dialogs/SelectExportDateRangeDialog';
import { getStrategicVolumeReport } from '../../services/strategicVolumeReport';
import dayjs from 'dayjs';
import UserContext from '../../store/User/UserContext';
import { orderBy } from 'lodash';
import { StrategicVolumeReportRelease } from '../../enums/StrategicVolumeReportRelease';

const exportColumnNames = [
    'Stadt',
    'Worktype',
    'Kanal',
    'Forecaster',
    'Kontaktart',
    'Anmerkungen',
    'Einheit',
    'Datum',
    'Strategic Volume Value',
];

const StrategicVolumeExportDialog = ({ open, onClose, locations }) => {
    const loggedInUser = useContext(UserContext);

    const [startDateOptions, setStartDateOptions] = useState([]);
    const [endDateOptions, setEndDateOptions] = useState([]);
    const [selectedStartDate, setSelectedStartDate] = useState('');
    const [selectedEndDate, setSelectedEndDate] = useState('');

    const getDataFromDb = async () => {
        const dateFrom = dayjs(selectedStartDate).startOf('month').format('YYYY-MM-DD 00:00:00');
        const dateTo = dayjs(selectedEndDate, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD 23:59:59');

        const location = loggedInUser.role.callmart_tef ? null : locations.map((location) => location.id);
        const releaseType = loggedInUser.role.callmart_tef ? null : StrategicVolumeReportRelease.RELEASED;

        return await getStrategicVolumeReport(location, dateFrom, dateTo, loggedInUser.id, releaseType);
    };

    const getExportData = (data) => {
        let exportData = [];
        data.forEach((strategicVolume) => {
            const location = locations.find(
                (location) => location.id === strategicVolume.workingUnit.worktypeLocation.locationId
            );

            const reports = orderBy(strategicVolume.strategicVolumeReports, ['date'], ['asc']);
            reports.forEach((strategicVolumeReport) => {
                exportData.push({
                    strategicVolumeId: strategicVolume.id,
                    forecasterNames: strategicVolume.forecasterNames.join(','),
                    capacityTypeUnit: strategicVolume.capacityType.unit,
                    reportValue: strategicVolumeReport?.value ?? null,
                    code: location?.code ?? null,
                    city: location?.city ?? null,
                    worktype: strategicVolume?.workingUnit?.worktypeLocation?.worktype?.worktype,
                    channel: strategicVolume?.workingUnit?.channel?.name,
                    date: strategicVolumeReport.date,
                });
            });
        });

        return exportData;
    };

    const createExcelRowForExport = (exportDataRow) => {
        let excelRow = [];

        excelRow = [
            exportDataRow.code + '-' + exportDataRow.city,
            exportDataRow.worktype,
            exportDataRow.channel,
            exportDataRow.forecasterNames,
            exportDataRow.contact,
            exportDataRow.comment,
            exportDataRow.capacityTypeUnit,
            dayjs(exportDataRow.date).startOf('month').format('DD.MM.YYYY'),
            exportDataRow.reportValue,
        ];

        return excelRow;
    };

    useEffect(() => {
        const previousMonths = [];
        const nextMonths = [];
        const numOfNextMonths = loggedInUser?.role.callmart_tef ? 12 : 6;
        for (let i = 0; i <= 12; i++) {
            previousMonths.push({
                month: dayjs().subtract(i, 'months'),
                monthName: dayjs().subtract(i, 'months').format('MMMM YYYY'),
            });
        }

        for (let i = 1; i <= numOfNextMonths; i++) {
            nextMonths.push({
                month: dayjs().add(i, 'months'),
                monthName: dayjs().add(i, 'months').format('MMMM YYYY'),
            });
        }

        setStartDateOptions(previousMonths);
        setEndDateOptions(nextMonths);
        setSelectedStartDate(previousMonths[0].month);
        setSelectedEndDate(nextMonths[1].month);
    }, [loggedInUser?.role.callmart_tef]);

    return (
        <SelectExportDateRangeDialog
            open={open}
            onClose={onClose}
            selectedStartDate={selectedStartDate}
            onStartDateChange={setSelectedStartDate}
            selectedEndDate={selectedEndDate}
            onEndDateChange={setSelectedEndDate}
            startDateOptions={startDateOptions}
            endDateOptions={endDateOptions}
            createExcelRowForExport={createExcelRowForExport}
            getExportData={getExportData}
            getDataFromDb={getDataFromDb}
            createRowFromExportData={createExcelRowForExport}
            exportedDataType="Strategischen Forecasts"
            dialogTitle="Export des Strategischen Forecasts"
            exportColumnNames={exportColumnNames}
            dialogText="Bitte wähle aus für welchen Zeitraum der strategische Forecast exportiert werden soll:"
        ></SelectExportDateRangeDialog>
    );
};

export default StrategicVolumeExportDialog;
