import * as io from 'socket.io-client';
import { SocketEvents } from '../enums/SocketEvents';

const getSocketURL = () => {
    const baseURL = process.env.REACT_APP_URL;
    const socket = io.connect(baseURL);
    return { socket, SocketEvents };
};

export default getSocketURL;
