import { getWFMValues } from '../../services/wfmValue';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../enums/QueryKey';
import { getDateRangeFromStartDateByAddingMonths } from '../../utils/dateUtils';

export const useCustomerBaseWFMs = (firstMonth, KPINames) => {
    const { dateFrom, dateTo } = getDateRangeFromStartDateByAddingMonths(firstMonth, 6);

    const { data } = useQuery({
        queryKey: [QueryKey.WFMs, { dateFrom, dateTo, kpiNames: KPINames }],
        queryFn: () => getWFMValues({ dateFrom, dateTo, KPINames }),
        disabled: !!firstMonth || !!KPINames || KPINames.length <= 0,
    });

    return [data];
};
