import CardPopover from '../UI/CardPopover';
import Table from '../AgGrid';
import { Grid, Typography } from '@mui/material';

const LastUploadCapacityReportPopup = ({
    anchorEl,
    onPopoverClose,
    lastUploadDate,
    lastUploadUser,
    tableRows,
    columnDefs,
}) => {
    return (
        <CardPopover anchorEl={anchorEl} onClose={onPopoverClose} className="lastUploadPopover">
            <Grid item xs={12}>
                {lastUploadDate && lastUploadUser && (
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {lastUploadDate?.endsWith('Uhr')
                            ? lastUploadDate?.substring(0, lastUploadDate?.length - 3)
                            : lastUploadDate}
                        von {lastUploadUser?.name}
                    </Typography>
                )}
            </Grid>
            <Grid item xs={12}>
                <Table
                    rowData={tableRows}
                    defaultColDef={{
                        editable: true,
                        filter: true,
                        sortable: true,
                    }}
                    columnDefs={columnDefs}
                ></Table>
            </Grid>
        </CardPopover>
    );
};

export default LastUploadCapacityReportPopup;
