import api from './index';
import { setToken } from '../utils/authUtils';

const BASE = '/worktypeLocation';

export const getAllLocationsByWorktype = async (worktype) => {
    return api.get(`${BASE}/location?worktype=${worktype}`, await setToken()).then((res) => res.data);
};

export const getAllDistinctLocations = async () => {
    return api.get(`${BASE}/locations`, await setToken()).then((res) => res.data);
};

export const getAllWorktypesByLocation = async (location) => {
    return api.get(`${BASE}/worktype?location=${location}`, await setToken()).then((res) => res.data);
};

export const getAllHEMTLocationsWithWorktypes = async () => {
    return api.get(`${BASE}/HEMTLocationsWithWorktypes`, await setToken()).then((res) => res.data);
};

export const postLocationWorktype = async (data) => {
    return api.post(`${BASE}/locationWorktype`, { data }, await setToken()).then((res) => res.data);
};

export const getWorktypeLocations = async () => {
    return api.get(`${BASE}`, await setToken()).then((res) => res.data);
};
