import { Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    totalDiv: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    totalValue: {
        textAling: 'center !important',
    },
}));

const DailyOSPBookingsTotals = ({ bookedCalls, availableCalls, forecastCalls }) => {
    const classes = useStyles();

    return (
        <Stack direction="row">
            <div className={classes.totalDiv}>
                <Typography variant="body1">Gebuchte Calls</Typography>
                <Typography variant="h5" className={classes.totalValue}>
                    {bookedCalls}
                </Typography>
            </div>
            <div className={classes.totalDiv}>
                <Typography variant="body1">Verbleibende Calls</Typography>
                <Typography variant="h5" className={classes.totalValue}>
                    {availableCalls}
                </Typography>
            </div>
            <div className={classes.totalDiv}>
                <Typography variant="body1">Forecast Calls</Typography>
                <Typography variant="h5" className={classes.totalValue}>
                    {forecastCalls}
                </Typography>
            </div>
        </Stack>
    );
};

export default DailyOSPBookingsTotals;
