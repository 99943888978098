import { useQuery } from '@tanstack/react-query';
import { createCapacityType, getCapacityTypes, updateCapacityType } from '../../services/capacityType';
import { QueryKey } from '../../enums/QueryKey';
import { useUpdateRecord, useCreateRecord } from './crudOperations';

export function useCapacityTypes() {
    const { data: capacityTypes } = useQuery({
        queryKey: [QueryKey.CapacityTypes],
        queryFn: getCapacityTypes,
        initialData: [],
    });

    return [capacityTypes];
}

export function useCreateCapacityType() {
    return useCreateRecord({
        createRecordFn: createCapacityType,
        fetchRecordsQueryKey: QueryKey.CapacityTypes,
        recordName: 'Capacity Type',
    });
}

export function useUpdateCapacityType() {
    return useUpdateRecord({
        updateRecordFn: updateCapacityType,
        fetchRecordsQueryKey: QueryKey.CapacityTypes,
        recordName: 'Capacity Type',
    });
}
