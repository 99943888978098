import { useState, useEffect, useCallback, forwardRef, useMemo } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { checkIsTableRowExpanded, getValueForMonthColumn, stringToNumberFormatter } from '../../utils/agGridUtils';
import ButtonIconRenderer from '../AgGrid/ButtonIconRenderer/ButtonIconRenderer';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { CapacityPlausibilityCheck } from '../../enums/CapacityPlausibilityCheck';
import dayjs from 'dayjs';
import TableGridItem from '../UI/TableGridItem';

const getHeaderName = (date) => `${date.format('MMM')}\n${date.format('YY')}`;

const CapacityHorizontalScrollTable = forwardRef(
    (
        {
            firstMonth,
            onFirstMonthChanged,
            tableRows,
            expandedRows,
            onExpandedRowsChanged,
            getExpandedRowsInTable,
            tableType,
            calculateSum,
            getMonthColumns,
            ...props
        },
        ref
    ) => {
        const [columnDefs, setColumnDefs] = useState([]);

        const goBackMonth = useCallback(
            (numOfMonths) => {
                onExpandedRowsChanged(getExpandedRowsInTable());

                const previousMonth = dayjs(firstMonth, 'YYYY-MM-DD').subtract(numOfMonths, 'months');
                onFirstMonthChanged(previousMonth);
            },
            [firstMonth, getExpandedRowsInTable, onFirstMonthChanged, onExpandedRowsChanged]
        );

        const goForwardMonth = useCallback(
            (numOfMonths) => {
                onExpandedRowsChanged(getExpandedRowsInTable());

                const nextMonth = dayjs(firstMonth, 'YYYY-MM-DD').add(numOfMonths, 'months');
                onFirstMonthChanged(nextMonth);
            },
            [firstMonth, getExpandedRowsInTable, onFirstMonthChanged, onExpandedRowsChanged]
        );

        const createColumnDefs = useCallback(
            (month) => {
                const monthColumns = getMonthColumns(month);
                const columns = [];
                const commonColumnDefs = [
                    {
                        field: 'worktype.name',
                        rowGroup: true,
                        hide: true,
                    },
                    {
                        field: 'channel.name',
                        rowGroup: true,
                        hide: true,
                    },
                    {
                        field: 'task.name',
                        rowGroup: true,
                        hide: true,
                    },
                    {
                        field: 'id',
                        headerName: 'Capacity',
                        valueGetter: (params) => `${params?.data?.capacityType.name ?? ''}`,
                        editable: false,
                        suppressMovable: true,
                        minWidth: 80,
                    },
                ];

                const columnDefsByTable = [];

                if (tableType === 'CAPACITY_ANALYSIS') {
                    columnDefsByTable.push(
                        {
                            field: 'rowsHeader',
                            rowGroup: true,
                            hide: true,
                            cellClass: 'empty-row',
                        },
                        ...commonColumnDefs
                    );

                    for (const monthColumn of monthColumns) {
                        columnDefsByTable.push({
                            field: `${monthColumn.format('YYYY-MM')}`,
                            headerName: getHeaderName(monthColumn),
                            valueGetter: (params) => getValueForMonthColumn(params, monthColumn),
                            valueFormatter: stringToNumberFormatter,
                            suppressMovable: true,
                            aggFunc: 'sum',
                        });
                    }
                } else if (tableType === 'CAPACITY_REPORT') {
                    columnDefsByTable.push(...commonColumnDefs, {
                        field: 'unit',
                        headerName: 'Einheit',
                        suppressMovable: true,
                        cellRenderer: (params) => `${params?.data?.capacityType.unit ?? ''}`,
                        editable: false,
                    });

                    for (const monthColumn of monthColumns) {
                        columnDefsByTable.push({
                            field: `${monthColumn.format('YYYY-MM')}`,
                            headerName: getHeaderName(monthColumn),
                            wrapText: true,
                            cellRenderer: (params) => {
                                let textColor = {};
                                if (params?.data) {
                                    if (
                                        params?.data[`${monthColumn.format('YYYY-MM')}`]?.checked ===
                                        CapacityPlausibilityCheck.WRONG
                                    ) {
                                        textColor = { color: 'red' };
                                    } else if (
                                        params?.data[`${monthColumn.format('YYYY-MM')}`]?.checked ===
                                        CapacityPlausibilityCheck.CORRECT
                                    ) {
                                        textColor = { color: 'green' };
                                    }
                                }

                                return <span style={{ ...textColor }}>{params.value}</span>;
                            },
                            valueGetter: (params) => getValueForMonthColumn(params, monthColumn),
                            valueSetter: (params) => {
                                params.data[`${monthColumn.format('YYYY-MM')}`].value =
                                    params.newValue === '' ? 0 : params.newValue;
                                return true;
                            },
                            valueFormatter: stringToNumberFormatter,
                            suppressMovable: true,
                            aggFunc: (params) => {
                                return calculateSum(params);
                            },
                        });
                    }
                }

                const defaultArrowColumnDefs = {
                    field: ``,
                    cellClass: 'grid-cell-centered',
                    headerComponent: ButtonIconRenderer,
                    maxWidth: 25,
                    editable: false,
                    sortable: false,
                    resizable: false,
                    filter: false,
                    suppressMovable: true,
                };

                columns.push(
                    {
                        ...defaultArrowColumnDefs,
                        headerComponent: ButtonIconRenderer,
                        headerComponentParams: {
                            icon: KeyboardDoubleArrowLeftIcon,
                            onClick: () => goBackMonth(12),
                            iconStyle: { width: '22px', height: '22px' },
                        },
                        pinned: 'left',
                        maxWidth: 30,
                    },
                    {
                        ...defaultArrowColumnDefs,
                        headerComponent: ButtonIconRenderer,
                        headerComponentParams: {
                            icon: ArrowBackIosIcon,
                            onClick: () => goBackMonth(1),
                            iconStyle: { width: '14px', height: '14px' },
                        },
                        pinned: 'left',
                    },
                    ...columnDefsByTable,
                    {
                        ...defaultArrowColumnDefs,

                        headerComponentParams: {
                            icon: ArrowForwardIosIcon,
                            onClick: () => goForwardMonth(1),
                            iconStyle: { width: '14px', height: '14px' },
                        },
                        pinned: 'right',
                    },
                    {
                        ...defaultArrowColumnDefs,
                        headerComponentParams: {
                            icon: KeyboardDoubleArrowRightIcon,
                            onClick: () => goForwardMonth(12),
                            iconStyle: { width: '22px', height: '22px' },
                        },
                        pinned: 'right',
                        maxWidth: 30,
                    }
                );

                setColumnDefs(columns);
            },
            [goBackMonth, goForwardMonth, getMonthColumns, tableType, calculateSum]
        );

        useEffect(() => {
            if (tableRows?.length && expandedRows.length) {
                ref.current.api.forEachNode((node) => {
                    const isTableRowExpanded = expandedRows.find((n) => checkIsTableRowExpanded(n, node));
                    if (isTableRowExpanded) {
                        node.setExpanded(true);
                    }
                });
            }
        }, [tableRows, expandedRows, ref]);

        useEffect(() => {
            if (firstMonth) {
                createColumnDefs(firstMonth);
            }
        }, [firstMonth, createColumnDefs]);

        const autoGroupColumnDef = useMemo(() => {
            return {
                minWidth: 230,
                headerName: '',
                resizable: true,
            };
        }, []);

        return (
            <TableGridItem
                ref={ref}
                columnDefs={columnDefs}
                animateRows={true}
                firstMonth={firstMonth}
                onFirstMonthChanged={onFirstMonthChanged}
                rowData={tableRows}
                expandedRows={expandedRows}
                onExpandedRowsChanged={onExpandedRowsChanged}
                getExpandedRowsInTable={getExpandedRowsInTable}
                autoGroupColumnDef={autoGroupColumnDef}
                {...props}
            ></TableGridItem>
        );
    }
);

export default CapacityHorizontalScrollTable;
