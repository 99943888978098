import { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import Container from '../components/Layout/Container';
import { Grid, Chip } from '@mui/material';
import { useAllHEMTLocationsWithWorktypes, useWorktypes } from '../hooks';
import { postLocationWorktype } from '../services/worktypeLocation';
import Table from '../components/AgGrid';
import MultipleWorktypeSelectDropdown from '../components/WorktypeLocation/MultipleWorktypeSelectDropdown';
import { useMutation } from '@tanstack/react-query';

const WorktypeLocations = () => {
    const gridRef = useRef(null);

    const [locations] = useAllHEMTLocationsWithWorktypes();
    const [allWorktypes] = useWorktypes();
    const [tableRows, setTableRows] = useState([]);

    const { mutateAsync: handlePostLocationWorktype } = useMutation({
        mutationFn: postLocationWorktype,
    });

    const addWorktypeToLocation = useCallback(
        async (worktype, locationWorktypes, params) => {
            handlePostLocationWorktype({ worktypeId: worktype.id, locationId: locationWorktypes.id }).then(() => {
                const worktypes = params.data.worktypes;
                let newWorktypes = [];
                if (worktypes.find((w) => w.id === worktype.id)) {
                    newWorktypes = worktypes.filter((w) => w.id !== worktype.id);
                } else {
                    const worktypeLocation = worktype.worktypeLocations.find(
                        (wl) => (wl.locationId = locationWorktypes)
                    );
                    const newWorktype = {
                        id: worktype.id,
                        disabled: worktypeLocation.disabled,
                        worktypeLocationId: worktypeLocation.id,
                        worktype: worktype.worktype,
                    };
                    newWorktypes = [...worktypes, newWorktype];
                }
                params.node.setData({ ...params.node.data, worktypes: newWorktypes });
            });
        },
        [handlePostLocationWorktype]
    );

    const columnDefs = useMemo(() => {
        return [
            {
                field: 'id',
                headerName: 'ID',
                maxWidth: 60,
                sortable: true,
            },
            {
                field: 'location',
                headerName: 'Standort',
                valueGetter: (params) => {
                    return `${params?.data.code} ${params?.data.city}`;
                },
                maxWidth: 250,
            },
            {
                field: 'worktypes',
                headerName: 'Worktypes',
                initialFlex: 1,
                autoHeight: true,
                cellRenderer: (params) => {
                    const worktypes = params?.data.worktypes;

                    return (
                        <>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <MultipleWorktypeSelectDropdown
                                    worktypes={allWorktypes}
                                    worktypesLocations={[]}
                                    locationWorktypes={params.data}
                                    onSelectWorktype={addWorktypeToLocation}
                                    params={params}
                                ></MultipleWorktypeSelectDropdown>
                                {worktypes?.map((worktype) => (
                                    <Chip
                                        style={{ margin: '5px' }}
                                        key={worktype.id}
                                        label={`${worktype.worktypeLocationId} - ${worktype.worktype}`}
                                        color="primary"
                                    />
                                ))}
                            </div>
                        </>
                    );
                },
            },
        ];
    }, [allWorktypes, addWorktypeToLocation]);

    useEffect(() => {
        if (locations.length) {
            setTableRows(locations);
        }
    }, [locations]);

    return (
        <Container>
            <Grid item xs={12}>
                <Table
                    ref={gridRef}
                    rowData={tableRows}
                    defaultColDef={{
                        editable: false,
                        filter: false,
                        sortable: false,
                        resizable: true,
                    }}
                    columnDefs={columnDefs}
                    headerHeight={80}
                ></Table>
            </Grid>
        </Container>
    );
};

export default WorktypeLocations;
