import { FormControl, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import ReactHookFormErrorLabel from './ReactHookFormErrorLabel';

const ReactHookFormDatepicker = ({ name, label, control, defaultValue, rules, formErrors, mindatetime, ...props }) => {
    return (
        <FormControl {...props} fullWidth={true}>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={rules}
                render={({ field, fieldState }) => (
                    <>
                        <LocalizationProvider adapterLocale="de" dateAdapter={AdapterDayjs}>
                            <DatePicker
                                {...field}
                                name={name}
                                renderInput={(props) => <TextField {...props} fullWidth />}
                                label={label}
                                ampm={false}
                                inputFormat="DD.MM.YYYY"
                                mask="__.__.____"
                                minDateTime={mindatetime}
                                {...props}
                            />
                        </LocalizationProvider>
                        {fieldState?.error && (
                            <ReactHookFormErrorLabel
                                fieldError={fieldState.error}
                                fieldName={name}
                                formErrors={formErrors}
                            ></ReactHookFormErrorLabel>
                        )}
                    </>
                )}
            />
        </FormControl>
    );
};

export default ReactHookFormDatepicker;
