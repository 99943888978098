import CookieSelect from './CookieSelect';

function LocationSelect({ selectedLocation, locationOptions, onChange, ...props }) {
    return (
        <CookieSelect
            selectedValue={selectedLocation}
            options={locationOptions}
            onChange={onChange}
            valueKey={'id'}
            labelKey={'code'}
            secondLabelKey={'city'}
            label={'Location'}
            cookieName={'location'}
            {...props}
        ></CookieSelect>
    );
}

export default LocationSelect;
