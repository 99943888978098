import { useEffect, useState } from 'react';
import DashboardCard from './DashboardCard';
import { AreaChart, XAxis, YAxis, CartesianGrid, Area, Tooltip, ResponsiveContainer } from 'recharts';
import { min, max } from 'lodash';
import dayjs from 'dayjs';

const getYAxisMinAndMaxValues = (chartData) => {
    const allCallsValues = chartData.reduce((allValues, item) => {
        return [...allValues, parseInt(item.bookedBookings), parseInt(item.forecastDeltas)];
    }, []);

    const minValue = min(allCallsValues);
    const maxValue = max(allCallsValues);

    return { minValue, maxValue };
};

function DashboardChart({ chartData, showSlotInterval }) {
    const [YAxisMinValue, setYAxisMinValue] = useState(0);
    const [YAxisMaxValue, setYAxisMaxValue] = useState(0);

    const renderDateTick = (tickProps) => {
        if (tickProps) {
            const { x, y, payload } = tickProps;
            const { value, offset } = payload;
            const slotDate = dayjs(value).format('DD.MM.YYYY HH:mm:ss');
            if (slotDate && chartData) {
                const date = slotDate.split(' ')[0];

                const slotInChartData = chartData.find((item) => item.slotDate === slotDate);
                if (slotInChartData) {
                    const indexOfSlotInChartData = chartData.indexOf(slotInChartData);
                    let pathX = Math.floor(x - offset) + 0.5;

                    if (indexOfSlotInChartData === 0) {
                        return (
                            <g>
                                <path d={`M${pathX},${y - 4}v${-35}`} stroke="gray" />;
                            </g>
                        );
                    } else if (indexOfSlotInChartData === chartData.length - 1) {
                        pathX = Math.floor(x + offset) + 25;
                        return (
                            <g>
                                <text x={x} y={y + 15} textAnchor="end">
                                    {dayjs(value).format('DD.MM')}
                                </text>
                                ;
                                <path d={`M${pathX},${y - 4}v${-35}`} stroke="gray" />;
                            </g>
                        );
                    } else if (indexOfSlotInChartData !== -1) {
                        const nextSlotInChartData = chartData[indexOfSlotInChartData + 1];
                        if (nextSlotInChartData) {
                            const dayOfNextSlotInChartData =
                                chartData[indexOfSlotInChartData + 1].slotDate.split(' ')[0];
                            if (date !== dayOfNextSlotInChartData) {
                                const pathX = Math.floor(x - offset) + 15;
                                return (
                                    <g>
                                        <text x={x} y={y + 15} textAnchor="end">
                                            {dayjs(value).format('DD.MM')}
                                        </text>
                                        ;
                                        <path d={`M${pathX},${y - 4}v${-35}`} stroke="gray" />;
                                    </g>
                                );
                            }
                        }
                    }
                }
            }
        }

        return null;
    };

    useEffect(() => {
        const { minValue, maxValue } = getYAxisMinAndMaxValues(chartData);
        setYAxisMinValue(minValue);
        setYAxisMaxValue(maxValue);
    }, [chartData]);

    return (
        <DashboardCard>
            <ResponsiveContainer width="100%" height={400}>
                <AreaChart data={chartData} margin={{ top: 50, right: 30, left: 0, bottom: 0 }}>
                    <defs>
                        <linearGradient id="colorForecastDeltas" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#0066ff" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#0066ff" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorBookedBookings" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#f4f4f4" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#f4f4f4" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    {showSlotInterval && (
                        <>
                            <XAxis
                                xAxisId={0}
                                dataKey="slotInterval"
                                scale="time"
                                domain={['auto', 'auto']}
                                tickFormatter={(slotDate) => dayjs(slotDate).format('HH:mm')}
                                type="number"
                                angle={-90}
                                tickMargin={15}
                                tick={{ fontSize: 12 }}
                            />
                            <XAxis
                                dataKey="slotInterval"
                                xAxisId={1}
                                tickFormatter={(slotDate) => dayjs(slotDate).format('DD.MM')}
                                axisLine={false}
                                domain={['auto', 'auto']}
                                height={25}
                                scale="time"
                                interval={0}
                                type="number"
                                tickLine={false}
                                tick={renderDateTick}
                            />
                        </>
                    )}
                    {!showSlotInterval && (
                        <XAxis dataKey="slotInterval" tickFormatter={(slotDate) => dayjs(slotDate).format('DD.MM')} />
                    )}
                    <YAxis
                        type="number"
                        domain={[YAxisMinValue, YAxisMaxValue]}
                        label={{ value: 'Calls', position: 'insideTopRight', dy: -40 }}
                    />
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <Tooltip labelFormatter={(t) => dayjs(t).format('DD.MM - HH:mm')} />

                    <Area
                        type="monotone"
                        dataKey="forecastDeltas"
                        stroke="#0066ff"
                        fillOpacity={1}
                        fill="url(#colorForecastDeltas)"
                        strokeDasharray="3 3"
                    />
                    <Area
                        type="monotone"
                        dataKey="bookedBookings"
                        stroke="#A9A9A9	"
                        fillOpacity={1}
                        fill="url(#colorBookedBookings)"
                    />
                </AreaChart>
            </ResponsiveContainer>
        </DashboardCard>
    );
}

export default DashboardChart;
