import { useState } from 'react';
import { Menu, MenuItem, Checkbox, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const MultipleWorktypeSelectDropdown = ({ worktypes, locationWorktypes, onSelectWorktype, params }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                id="multiple-worktypes-select-button"
                icon={AddIcon}
                onClick={handleClick}
                aria-controls={open ? 'multiple-worktypes-select-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                <AddIcon />
            </IconButton>
            <Menu
                id="multiple-worktypes-select-menu"
                aria-labelledby="multiple-worktypes-select-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {worktypes.map((worktype) => (
                    <MenuItem key={worktype.id}>
                        <Checkbox
                            checked={locationWorktypes.worktypes.map((worktype) => worktype.id).includes(worktype.id)}
                            onChange={() => onSelectWorktype(worktype, locationWorktypes, params)}
                        />
                        {worktype.worktype}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default MultipleWorktypeSelectDropdown;
