import { useState, useContext } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Button, InputAdornment } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Portal } from '@mui/base/Portal';
import { fieldIsRequiredMsg } from '../../utils/componentUtils';
import ReactHookFormTextField from '../ReactHookForm/ReactHookFormTextField';
import ReactHookFormSelect from '../ReactHookForm/ReactHookFormSelect';
import dayjs from 'dayjs';
import { useCustomStyles, useWorkingUnits } from '../../hooks';
import PercentIcon from '@mui/icons-material/Percent';
import ReactHookFormCheckbox from '../ReactHookForm/ReactHookFormCheckbox';
import ReactHookFormAutoComplete from '../ReactHookForm/ReactHookFormAutoComplete';
import ReactHookFormDateTimePicker from '../ReactHookForm/ReactHookFormDateTimePicker';
import { isEndAfterStart, getDuration } from '../../utils/dateUtils';
import { RecurringOptions, getRepetitionIntervalType } from './utils/serieDialogUtils';
import EventReductionSerieDialog from './EventReductionSerieDialog';
import EventRecurringInfo from './EventRecurringInfo';
import UserContext from '../../store/User/UserContext';

const EventReductionDialog = ({
    showDialog,
    onShowDialog,
    record,
    location,
    locationOptions,
    onRowSelect,
    postRecord,
    dialogTitle,
}) => {
    const classes = useCustomStyles();
    const loggedInUser = useContext(UserContext);

    const [showSerieDialog, setShowSerieDialog] = useState(false);

    const {
        watch,
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        resetField,
        getValues,
    } = useForm({
        defaultValues: {
            id: record?.id || null,
            name: record?.name || '',
            reduction: record?.reduction || '',
            start: record ? dayjs(record.start) : dayjs(),
            end: record ? dayjs(record.end) : dayjs(),
            location:
                record && record?.workingUnits
                    ? record.workingUnits[0].worktypeLocation.locationId
                    : location
                    ? location
                    : '',
            user: loggedInUser?.id || null,
            workingUnits:
                record && record.workingUnits
                    ? record.workingUnits.map((wu) => ({
                          ...wu,
                          label: `${wu.worktypeLocation.worktype.worktype} - ${wu.channel?.name} - ${wu.task?.name}`,
                      }))
                    : [],
            comment: record?.comment || '',
            productive: record?.productive || false,
            increase: record?.increase || false,
            recurringOption: record?.serie?.frequency || RecurringOptions[0].value,
            serie: record?.serie
                ? { ...record.serie, repetitionIntervalType: getRepetitionIntervalType(record.serie) }
                : null,
            serieId: record?.serieId || null,
            editOnlyRecord: record?.editOnlyRecord,
        },
    });
    const selectedStart = watch('start');
    const selectedRecurringOption = watch('recurringOption');
    const selectedLocation = watch('location');
    const [workingUnits] = useWorkingUnits({ location: selectedLocation });
    const workingUnitOptions = workingUnits.map((wu) => ({
        ...wu,
        label: `${wu.worktypeLocation.worktype.worktype} - ${wu.channel?.name} - ${wu.task?.name}`,
    }));

    const showSerieInfo = record?.serie && selectedRecurringOption !== RecurringOptions[0].value;

    const getRecordForSerieDialog = () => {
        const recurringOption = getValues('recurringOption');
        const start = getValues('start');
        const end = getValues('end');

        return {
            ...(record || {}),
            start,
            end,
            recurringOption,
        };
    };

    const submitEventRecurringDialog = (values) => {
        setValue('recurringOption', values.frequency);
        setValue('serie', values);

        const duration = getDuration(selectedStart, getValues('end'));
        const eventStart = values.start;
        const eventEnd = values.start.add(duration);
        setValue('start', eventStart);
        setValue('end', eventEnd);

        onRowSelect((prevValues) => ({
            ...(prevValues || {}),
            start: eventStart,
            end: eventEnd,
            serie: values,
        }));
        setShowSerieDialog(false);
    };

    const onSubmit = async (values) => {
        if (values.serie) {
            values.serie.frequency = selectedRecurringOption;
        }
        postRecord(values);
    };

    const handleClose = () => {
        onRowSelect(null);
        onShowDialog(false);
    };

    const handleSerieDialogClose = () => {
        setShowSerieDialog(false);
        resetField('recurringOption');
    };

    const onRecurringOptionClick = () => {
        if (!record?.editOnlyRecord && ['week', 'month', 'year'].includes(getValues('recurringOption'))) {
            setShowSerieDialog(true);
        } else {
            setShowSerieDialog(false);
        }
    };

    const handleLocationChange = () => {
        setValue('workingUnits', []);
    };

    return (
        <Portal>
            <Dialog open={showDialog} onClose={handleClose}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle>{dialogTitle}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} sx={{ pt: 2 }}>
                            <Grid item xs={12}>
                                <ReactHookFormTextField
                                    name="name"
                                    control={control}
                                    label="Name"
                                    formErrors={errors}
                                ></ReactHookFormTextField>
                            </Grid>
                            {locationOptions && (
                                <Grid item xs={12}>
                                    <ReactHookFormSelect
                                        name="location"
                                        control={control}
                                        label="Standort *"
                                        options={locationOptions}
                                        mainProp="code"
                                        secondProp="city"
                                        rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                                        formErrors={errors}
                                        onItemClick={handleLocationChange}
                                    ></ReactHookFormSelect>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <ReactHookFormAutoComplete
                                    name="workingUnits"
                                    control={control}
                                    label="Working Unit *"
                                    mainProp="label"
                                    options={workingUnitOptions}
                                    multiple
                                    rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                                    formErrors={errors}
                                ></ReactHookFormAutoComplete>
                            </Grid>
                            <Grid item xs={12}>
                                <ReactHookFormTextField
                                    name="reduction"
                                    control={control}
                                    label="Aufschlag/Abschlag *"
                                    isNumeric={true}
                                    inputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PercentIcon edge="end" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                                    formErrors={errors}
                                ></ReactHookFormTextField>
                            </Grid>
                            <Grid item xs={12}>
                                <ReactHookFormCheckbox
                                    name="increase"
                                    control={control}
                                    label="Aufschlag"
                                    labelPlacement="end"
                                ></ReactHookFormCheckbox>
                            </Grid>
                            <Grid item xs={6}>
                                <ReactHookFormDateTimePicker
                                    name="start"
                                    control={control}
                                    label="Start"
                                    rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                                    formErrors={errors}
                                ></ReactHookFormDateTimePicker>
                            </Grid>
                            <Grid item xs={6}>
                                <ReactHookFormDateTimePicker
                                    name="end"
                                    control={control}
                                    label="End"
                                    rules={{
                                        required: { value: true, message: fieldIsRequiredMsg },
                                        validate: (value) => isEndAfterStart(selectedStart, value),
                                    }}
                                    mindatetime={selectedStart}
                                    formErrors={errors}
                                ></ReactHookFormDateTimePicker>
                            </Grid>
                            <Grid item xs={12}>
                                <ReactHookFormTextField
                                    name="comment"
                                    control={control}
                                    label="Kommentar"
                                    isMultiline={true}
                                    rows={4}
                                ></ReactHookFormTextField>
                            </Grid>
                            <Grid item xs={6}>
                                <ReactHookFormSelect
                                    name="recurringOption"
                                    control={control}
                                    label=""
                                    mainProp="text"
                                    valueProp="value"
                                    options={RecurringOptions}
                                    formErrors={errors}
                                    disabled={record?.editOnlyRecord}
                                    onItemClick={onRecurringOptionClick}
                                ></ReactHookFormSelect>
                            </Grid>
                            <Grid item xs={6}>
                                <ReactHookFormCheckbox
                                    name="productive"
                                    control={control}
                                    label="Produktiv"
                                    labelPlacement="end"
                                ></ReactHookFormCheckbox>
                            </Grid>
                        </Grid>
                        {showSerieInfo && <EventRecurringInfo eventSerie={record.serie} />}
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button onClick={handleClose} variant="outlined">
                            Abbrechen
                        </Button>
                        <Button type="submit" variant="contained">
                            Speichern
                        </Button>
                    </DialogActions>
                </form>
                {showSerieDialog && (
                    <EventReductionSerieDialog
                        showDialog={showSerieDialog}
                        onClose={handleSerieDialogClose}
                        onSubmit={submitEventRecurringDialog}
                        record={getRecordForSerieDialog()}
                    ></EventReductionSerieDialog>
                )}
            </Dialog>
        </Portal>
    );
};

export default EventReductionDialog;
