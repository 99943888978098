import Container from '../components/Layout/Container';
import { Grid, Button } from '@mui/material';
import UserContext from '../store/User/UserContext';
import { useState, useEffect, useContext, useCallback, useRef } from 'react';
import {
    useIsMounted,
    useAlertMessage,
    useLocationsByLoggedInUserRole,
    useCookieLocation,
    useHEMTLocations,
    useCustomStyles,
} from '../hooks';
import {
    createCapacityReport,
    getCapacityReport,
    getLastCapacityReportUploadDate,
    getLastCapacityReportUploadUserAndDate,
    getLatestCapacityReportByWorktypes,
    createOneCapacityReport,
    checkCapacityReportPlausibility,
    uploadCapacityReports,
} from '../services/capacityReport';
import { createTableRows } from '../utils/agGridUtils';
import SelectExportCapacitiesDateRangeDialog from '../components/CapacityReport/SelectExportCapacitiesDateRangeDialog';
import { NUM_OF_MONTH_COLUMNS_FOR_CAPACITY_REPORT } from '../components/CapacityReport/helpers/constants';
import {
    setCommonStateAfterDataUpload,
    showSuccessfulUploadNotification,
    showErrorUploadNotification,
} from '../utils/componentUtils';
import LastUpload from '../components/General/LastUpload';
import LastUploadCapacityReportPopup from '../components/CapacityReport/LastUploadCapacityReportPopup';
import { ReactComponent as FileExport } from '../assets/file-export.svg';
import { getDateRangeFromStartDateByAddingMonths, formatLastUploadDate } from '../utils/dateUtils';
import { getMonthColumns, addRowsInMap } from '../components/CapacityReport/helpers/utils';
import { plausibilityCheckOptions } from '../components/CapacityReport/helpers/constants';
import CapacityReportTable from '../components/CapacityReport/CapacityReportTable';
import PlausibilityCheckSelect from '../components/Selects/PlausibilityCheckSelect';
import { CapacityReportType } from '../enums/CapacityReportType';
import dayjs from 'dayjs';
import UserRoleLocation from '../components/General/UserRoleLocation';
import CapacityReportEmptyTemplateDownloadButton from '../components/CapacityReport/CapacityReportEmptyTemplateDownloadButton';
import { parseXLSXData } from '../utils/xlsxUtils';
import UploadXlsxFileButton from '../components/Buttons/UploadXlsxFileButton';
import PercentageLoadingOverlay from '../components/Loaders/PercentageLoadingOverlay';
import CapacityReportCurrentDataDownloadButton from '../components/CapacityReport/CapacityReportCurrentDataDownloadButton';
import * as Sentry from '@sentry/react';
import { isStringValidJSON } from '../utils';
import GridContainer from '../components/Layout/GridContainer';

const fetchTableData = async (firstMonth, location, plausibilityCheck) => {
    const { dateFrom, dateTo } = getDateRangeFromStartDateByAddingMonths(
        firstMonth,
        NUM_OF_MONTH_COLUMNS_FOR_CAPACITY_REPORT
    );

    const capacityReport = await getCapacityReport({ location, dateFrom, dateTo, plausibilityCheck });
    const monthColumns = getMonthColumns(firstMonth);
    const rowsMap = addRowsInMap(capacityReport, monthColumns, 'capacityReports');
    const rows = createTableRows(rowsMap, 'id');

    return rows;
};

function CapacityReport() {
    const classes = useCustomStyles();

    const isMounted = useIsMounted();
    const { dispatchAlert } = useAlertMessage();
    const loggedInUser = useContext(UserContext);

    const gridRef = useRef(null);

    const [allLocationsFromHEMT] = useHEMTLocations();
    const [locationOptions] = useLocationsByLoggedInUserRole();
    const [selectedLocation, setSelectedLocation] = useCookieLocation(locationOptions);
    const [selectedPlausibilityCheck, setSelectedPlausibilityCheck] = useState(plausibilityCheckOptions[0].value);

    const [firstMonth, setFirstMonth] = useState(dayjs());

    const [tableRows, setTableRows] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);

    const [openExportDialog, setOpenExportDialog] = useState(false);

    const [lastUploadDate, setLastUploadDate] = useState();
    const [lastUploadUser, setLastUploadUser] = useState();
    const [lastUploadPopoverAnchorEl, setLastUploadPopoverAnchorEl] = useState(null);
    const [lastUploadTableRows, setLastUploadTableRows] = useState([]);
    const [lastUploadColumnDefs, setLastUploadColumnDefs] = useState([]);

    const [monthForUploading, setMonthForUploading] = useState(dayjs().format('YYYY-MM'));
    const [loading, setLoading] = useState(false);
    const [percentage, setPercentage] = useState(0);

    const getExpandedRowsInTable = () => {
        const expandedRows = [];
        gridRef.current.api.forEachNode((node) => {
            if (node.displayed && node.expanded) {
                expandedRows.push(node);
            }
        });

        return expandedRows;
    };

    const setTableData = async (firstMonth, selectedLocation, selectedPlausibilityCheck) => {
        gridRef?.current?.api?.showLoadingOverlay();
        const rows = await fetchTableData(firstMonth, selectedLocation, selectedPlausibilityCheck);
        setTableRows(rows);
        if (rows.length) {
            gridRef?.current?.api?.hideOverlay();
        } else {
            gridRef?.current?.api?.showNoRowsOverlay();
        }
    };

    const saveCapacityReport = useCallback(
        async ({ tableRows, loggedInUserId, firstMonth, selectedLocation, selectedPlausibilityCheck, cellParams }) => {
            try {
                let numOfNewReports = 0;
                if (cellParams) {
                    const capacityReportValue = cellParams.newValue;
                    let data = {
                        changedCapacity: {
                            capacityId: parseInt(cellParams.data.id),
                            capacityTypeName: cellParams.data.capacityType.name,
                            date: cellParams.column.colId,
                            value: capacityReportValue,
                            capacityType: cellParams.data.capacityType,
                        },
                        userId: loggedInUserId,
                    };

                    await createOneCapacityReport(data);
                    numOfNewReports = 1;
                } else {
                    const result = await createCapacityReport(tableRows, loggedInUserId);
                    numOfNewReports = result.numOfNewReports;
                }

                setExpandedRows(getExpandedRowsInTable());

                if (numOfNewReports === 0) {
                    dispatchAlert({
                        message: 'Geben Sie gültige Daten ein',
                        type: 'error',
                    });
                } else {
                    dispatchAlert({
                        message: 'Potenzialmeldung erfolgreich erstellt',
                        type: 'success',
                    });
                }
            } catch (error) {
                dispatchAlert({
                    message: 'Error occured',
                    type: 'error',
                });
            }

            setTableData(firstMonth, selectedLocation, selectedPlausibilityCheck);
        },
        [dispatchAlert]
    );

    const handleExportDialogClose = () => {
        setOpenExportDialog(false);
    };

    const handleExportDialogOpen = () => {
        setOpenExportDialog(true);
    };

    const uploadFiles = (files, closeDropzoneDialog) => {
        setLoading(true);

        const file = files[0];

        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = parseXLSXData(e);

            uploadCapacityReports({ data, user: loggedInUser?.id }, setPercentage)
                .then(async (res) => {
                    const response = res[0];
                    if (!response || !isStringValidJSON(response)) {
                        console.log('Capacity report upload response: ', res);
                    }
                    const resData = JSON.parse(response);
                    const { success, errorMessage } = resData;

                    if (success) {
                        showSuccessfulUploadNotification(dispatchAlert);
                        setTableData(firstMonth, selectedLocation, selectedPlausibilityCheck);
                    } else {
                        showErrorUploadNotification({
                            dispatchAlert,
                            errorMessage,
                        });
                    }
                })
                .catch(async (error) => {
                    Sentry.captureException(error);
                    showErrorUploadNotification({
                        dispatchAlert,
                        errorMessage: error.message,
                    });
                })
                .finally(() => {
                    setCommonStateAfterDataUpload(setLoading, setPercentage, closeDropzoneDialog);
                });
        };
        reader.readAsArrayBuffer(file);
    };

    useEffect(() => {
        if (selectedLocation && firstMonth) {
            setTableData(firstMonth, selectedLocation, selectedPlausibilityCheck);
        }
    }, [selectedLocation, firstMonth, isMounted, selectedPlausibilityCheck]);

    useEffect(() => {
        if (selectedLocation) {
            getLastCapacityReportUploadDate(selectedLocation).then((res) => {
                const lastUpload = formatLastUploadDate(res);
                setLastUploadDate(lastUpload);
            });
        }
    }, [selectedLocation]);

    const setLastUploadData = (data) => {
        const { allMonths, capacityReport } = data;

        let columns = [
            {
                field: 'worktype',
                headerName: '',
                minWidth: 120,
            },
        ];
        for (let i = 0; i < allMonths.length; i++) {
            columns = [
                ...columns,
                {
                    field: `${dayjs(allMonths[i]).format('YYYY-MM')}`,
                    headerName: `${dayjs(allMonths[i]).format('MMM YY')}`,
                    cellClass: 'grid-cell-centered',
                },
            ];
        }

        let rowsMap = new Map();
        let rows = [];
        for (let i = 0; i < capacityReport.length; i++) {
            const key = capacityReport[i].worktype;
            const month = [dayjs(capacityReport[i].date).format('YYYY-MM')];
            if (rowsMap.has(key)) {
                rowsMap.set(key, {
                    ...rowsMap.get(key),
                    [month]: capacityReport[i].sum,
                });
            } else {
                rowsMap.set(key, {
                    [month]: capacityReport[i].sum,
                });
            }

            rows = createTableRows(rowsMap, 'worktype');
        }

        setLastUploadColumnDefs(columns);
        setLastUploadTableRows(rows);
    };

    const showLastUploadData = (event) => {
        setLastUploadPopoverAnchorEl(event.currentTarget);

        getLastCapacityReportUploadUserAndDate(selectedLocation).then((res) => {
            const { user, date } = res;
            const lastUpload = formatLastUploadDate(date);
            setLastUploadDate(lastUpload);
            setLastUploadUser(user);

            if (selectedLocation && user) {
                getLatestCapacityReportByWorktypes({ location: selectedLocation, user: user?.id }).then((res) => {
                    setLastUploadData(res);
                });
            }
        });
    };

    const hideLastUploadData = () => {
        setLastUploadPopoverAnchorEl(null);
    };

    const handlePlausibilityCheck = async () => {
        await checkCapacityReportPlausibility(tableRows, selectedLocation);
        setTableData(firstMonth, selectedLocation, selectedPlausibilityCheck);
    };

    return (
        <Container>
            <GridContainer>
                {loggedInUser && (
                    <UserRoleLocation
                        loggedInUser={loggedInUser}
                        selectedLocation={selectedLocation}
                        locationOptions={locationOptions}
                        onChange={setSelectedLocation}
                    ></UserRoleLocation>
                )}
                <Grid item xs={12} md={3} lg={2}>
                    <PlausibilityCheckSelect
                        selectedValue={selectedPlausibilityCheck}
                        onChange={setSelectedPlausibilityCheck}
                    ></PlausibilityCheckSelect>
                </Grid>
                <Grid item xs={10} md={4} lg={3}>
                    <LastUpload
                        showInfoIcon={true}
                        onClick={(e) => showLastUploadData(e)}
                        date={lastUploadDate}
                        setLastUploadPopoverAnchorEl={setLastUploadPopoverAnchorEl}
                    ></LastUpload>
                </Grid>
                <Grid item xs={2} md={1} lg={4} className={classes.btnRightAligned}>
                    <Button onClick={handleExportDialogOpen} variant="contained">
                        <FileExport />
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button onClick={handlePlausibilityCheck} variant="outlined" style={{ marginRight: '5px' }}>
                        Plausibilitäts-Check
                    </Button>
                    <CapacityReportEmptyTemplateDownloadButton
                        months={getMonthColumns(firstMonth)}
                        location={selectedLocation}
                    ></CapacityReportEmptyTemplateDownloadButton>
                    <CapacityReportCurrentDataDownloadButton
                        startDate={firstMonth}
                        selectedLocation={selectedLocation}
                        selectedPlausibilityCheck={selectedPlausibilityCheck}
                        allLocationsFromHEMT={allLocationsFromHEMT}
                    ></CapacityReportCurrentDataDownloadButton>
                    <UploadXlsxFileButton
                        buttonName="Upload"
                        monthForUploading={monthForUploading}
                        onChangeMonthForUploading={setMonthForUploading}
                        uploadFiles={uploadFiles}
                        dialogTitle="Potenziale hochladen"
                    ></UploadXlsxFileButton>
                </Grid>
                <Grid item xs={12}>
                    <CapacityReportTable
                        ref={gridRef}
                        firstMonth={firstMonth}
                        onFirstMonthChanged={setFirstMonth}
                        tableRows={tableRows}
                        expandedRows={expandedRows}
                        onExpandedRowsChanged={setExpandedRows}
                        getExpandedRowsInTable={getExpandedRowsInTable}
                        saveCapacityReport={(params) =>
                            saveCapacityReport({
                                tableRows,
                                loggedInUserId: loggedInUser?.id,
                                firstMonth,
                                selectedLocation,
                                ...params,
                            })
                        }
                    ></CapacityReportTable>
                </Grid>
                <Grid item sm={6} md={8} lg={9}></Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} className={classes.btnRightAligned}>
                    <Button
                        variant="outlined"
                        onClick={() =>
                            saveCapacityReport({
                                tableRows,
                                loggedInUserId: loggedInUser?.id,
                                firstMonth,
                                selectedLocation,
                            })
                        }
                    >
                        Speichern
                    </Button>
                </Grid>

                {openExportDialog && (
                    <SelectExportCapacitiesDateRangeDialog
                        open={openExportDialog}
                        onClose={handleExportDialogClose}
                        locations={locationOptions}
                        reportType={CapacityReportType.CAPACITY_REPORT}
                    ></SelectExportCapacitiesDateRangeDialog>
                )}
                {lastUploadPopoverAnchorEl && (
                    <LastUploadCapacityReportPopup
                        anchorEl={lastUploadPopoverAnchorEl}
                        onPopoverClose={hideLastUploadData}
                        location={selectedLocation}
                        lastUploadDate={lastUploadDate}
                        lastUploadUser={lastUploadUser}
                        tableRows={lastUploadTableRows}
                        columnDefs={lastUploadColumnDefs}
                    ></LastUploadCapacityReportPopup>
                )}
                <PercentageLoadingOverlay loading={loading} percentage={percentage}></PercentageLoadingOverlay>
            </GridContainer>
        </Container>
    );
}

export default CapacityReport;
