import { useCallback } from 'react';
import { useDispatchSuccessNotification } from '.';

export function useDispatchSuccessfullySavedRecord() {
    const { dispatchSuccessNotification } = useDispatchSuccessNotification();
    const dispatchSuccessfullySavedRecord = useCallback(
        (recordName) => dispatchSuccessNotification({ message: `${recordName} erfolgreich gespeichert` }),
        [dispatchSuccessNotification]
    );

    return { dispatchSuccessfullySavedRecord };
}
