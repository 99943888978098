import { useState } from 'react';
import { appendSheetToWorkbook, downloadXLSXFile } from '../../utils/xlsxUtils';
import Button from '@mui/material/Button';
import { ReactComponent as FileExport } from '../../assets/file-export.svg';
import CustomBackdrop from '../UI/CustomBackdrop';
import { write, utils } from 'xlsx';

function ExportToExcelButton({
    columnNames,
    getColumnNames,
    createRowFromExportData,
    sheetName,
    getFileName,
    wbTitle,
    wbSubject,
    exportData,
    getExportData,
    getDataFromDb,
}) {
    const [showBackdrop, setShowBackdrop] = useState(false);

    const getXLSXSheet = (wb, exportData) => {
        let ws_data = [];

        const columns = getColumnNames ? getColumnNames(exportData) : columnNames;
        const rows = createRowFromExportData ? exportData.map(createRowFromExportData) : exportData;
        ws_data = [columns, ...rows];

        let ws = utils.aoa_to_sheet(ws_data);
        appendSheetToWorkbook(wb, ws, sheetName);

        return wb;
    };

    const exportEventsInExcelFile = (exportData) => {
        if (exportData?.length > 0) {
            let wb = utils.book_new();

            wb.Props = {
                Title: wbTitle,
                Subject: wbSubject,
                Author: 'Unetiq',
                CreatedDate: new Date(),
            };

            wb = getXLSXSheet(wb, exportData);
            let wbout = write(wb, { bookType: 'xlsx', type: 'binary' });

            const fileName = getFileName();
            downloadXLSXFile(wbout, fileName);
        }

        setShowBackdrop(false);
    };

    const onClick = async () => {
        setShowBackdrop(true);
        let data = exportData;
        if (getDataFromDb) {
            data = await getDataFromDb();
        }
        if (getExportData) {
            if (getDataFromDb) {
                data = getExportData(data);
            } else {
                data = getExportData();
            }
        }

        exportEventsInExcelFile(data);
    };

    return (
        <>
            <Button onClick={onClick} variant="contained">
                <FileExport />
            </Button>
            {showBackdrop && <CustomBackdrop open={showBackdrop}></CustomBackdrop>}
        </>
    );
}

export default ExportToExcelButton;
