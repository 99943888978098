import api from './index';
import { setToken } from '../utils/authUtils';

const BASE = '/task';

export const getTasks = async () => {
    const url = `${BASE}`;

    return api.get(url, await setToken()).then((res) => res.data);
};

export const getAllTasksFromCapacitiesByFilters = async (location, worktype, channel) => {
    return api
        .get(
            `${BASE}/allFromCapacitiesByFilters?location=${location}&worktype=${worktype}&channel=${channel}`,
            await setToken()
        )
        .then((res) => res.data);
};

export const getAllTasks = async () => {
    return api.get(`${BASE}`, await setToken()).then((res) => res.data);
};

export const createTask = async (data) => {
    return api.post(`${BASE}`, { data }, await setToken()).then((res) => res.data);
};

export const updateTask = async (data) => {
    const { id } = data;
    return api.patch(`${BASE}/${id}`, { data }, await setToken()).then((res) => res.data);
};
