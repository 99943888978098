import Typography from '@mui/material/Typography';
import { getCreatedAtForHistoryPopover } from '../../utils/dateUtils';

function LocationDeltasHistory({ locationDeltasHistory }) {
    return (
        <>
            <Typography variant="h5" style={{ textAling: 'center', marginBottom: '1em' }}>
                Änderungen
            </Typography>

            {locationDeltasHistory &&
                locationDeltasHistory.map((locationDelta) => (
                    <div key={locationDelta.id}>
                        <Typography variant="subtitle1">
                            <span style={{ color: 'var(--grey)' }}>
                                {getCreatedAtForHistoryPopover(locationDelta.createdAt)}:{' '}
                            </span>
                            <span style={{ color: 'var(--primary)' }}>{locationDelta.value}</span>
                            <span>({locationDelta.reason})</span>
                        </Typography>
                    </div>
                ))}
        </>
    );
}

export default LocationDeltasHistory;
