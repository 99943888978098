import { useEffect, useState } from 'react';

export function useIsMounted() {
    const [isMounted, setIsMouted] = useState(false);
    useEffect(() => {
        setIsMouted(true);
        return () => setIsMouted(false);
    }, []);
    return isMounted;
}
