import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Button } from '@mui/material';
import { useState, useEffect } from 'react';
import { getAllWorktypeByLocation } from '../../services/worktype';
import { useForm } from 'react-hook-form';
import ReactHookFormSelect from '../ReactHookForm/ReactHookFormSelect';
import { Portal } from '@mui/base/Portal';
import { useCustomStyles, useHEMTLocations } from '../../hooks';
import { fieldIsRequiredMsg } from '../../utils/componentUtils';

const CreateCapacityDialog = ({ showDialog, channelOptions, taskOptions, capacityTypeOptions, onSubmit, onClose }) => {
    const classes = useCustomStyles();

    const [allLocationsFromHEMT] = useHEMTLocations();
    const [worktypeOptions, setWorktypeOptions] = useState(null);

    const {
        watch,
        handleSubmit,
        formState: { errors },
        resetField,
        control,
    } = useForm({
        defaultValues: {
            location: '',
            worktype: '',
            channel: '',
            task: '',
            capacityTypes: [],
        },
    });
    const selectedLocation = watch('location');

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        if (selectedLocation) {
            getAllWorktypeByLocation(selectedLocation).then((res) => {
                setWorktypeOptions(res);
                resetField('worktype');
            });
        }
    }, [selectedLocation, resetField]);

    return (
        <Portal>
            <Dialog open={showDialog} onClose={handleClose} PaperProps={{ style: { minWidth: '600px' } }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle>Neue Capacities anlegen</DialogTitle>
                    <DialogContent style={{ marginTop: '1rem' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <ReactHookFormSelect
                                    name="location"
                                    options={allLocationsFromHEMT}
                                    mainProp="code"
                                    secondProp="city"
                                    label="Standort"
                                    control={control}
                                    rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                                    formErrors={errors}
                                ></ReactHookFormSelect>
                            </Grid>
                            <Grid item xs={12}>
                                <ReactHookFormSelect
                                    name="worktype"
                                    options={worktypeOptions}
                                    mainProp="worktype"
                                    label="Worktype"
                                    control={control}
                                    rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                                    formErrors={errors}
                                ></ReactHookFormSelect>
                            </Grid>
                            <Grid item xs={12}>
                                <ReactHookFormSelect
                                    name="channel"
                                    options={channelOptions}
                                    mainProp="name"
                                    label="Channel"
                                    control={control}
                                    rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                                    formErrors={errors}
                                ></ReactHookFormSelect>
                            </Grid>
                            <Grid item xs={12}>
                                <ReactHookFormSelect
                                    name="task"
                                    options={taskOptions}
                                    mainProp="name"
                                    label="Task"
                                    control={control}
                                    rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                                    formErrors={errors}
                                ></ReactHookFormSelect>
                            </Grid>
                            <Grid item xs={12}>
                                <ReactHookFormSelect
                                    name="capacityTypes"
                                    options={capacityTypeOptions}
                                    mainProp="name"
                                    label="Capacity Types"
                                    control={control}
                                    rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                                    formErrors={errors}
                                    isMultiSelect={true}
                                ></ReactHookFormSelect>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button onClick={handleClose} variant="outlined">
                            Abbrechen
                        </Button>
                        <Button type="submit" variant="contained">
                            Anlegen
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Portal>
    );
};

export default CreateCapacityDialog;
