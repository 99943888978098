import { createContext } from 'react';

const initialMessage = {
    show: false,
    type: 'info',
    message: '',
    autoHideDuration: 2000,
    useSnackbar: true,
};

const initialContextValue = {
    message: initialMessage,
    setMessage: () => undefined,
};

export const MessageContext = createContext(initialContextValue);
