import { useQuery, useMutation } from '@tanstack/react-query';
import { QueryKey } from '../../enums/QueryKey';
import { queryClient } from '../../services/queryClient';
import dayjs from 'dayjs';
import { useDeleteRecord } from './crudOperations';
import { useDispatchErrorNotification } from '../useDispatchErrorNotification';
import { useDispatchSuccessfullySavedRecord } from '../useDispatchSuccessfullySavedRecord';
import { deleteEvent, getEvents, postEvent } from '../../services/event';

export const getEventsQueryKey = (filterParams) => {
    const { location, worktype, startDate, endDate } = filterParams;

    const start = dayjs(startDate).startOf('month').format('YYYY-MM-DD 00:00:00');
    const end = dayjs(endDate).endOf('month').format('YYYY-MM-DD 23:59:59');

    return [
        QueryKey.Events,
        {
            start,
            end,
            ...(location && { location }),
            ...(worktype && { worktype }),
        },
    ];
};

export function useEvents(params) {
    const { location, worktype, startDate, endDate } = params;
    const start = dayjs(startDate).startOf('month').format('YYYY-MM-DD 00:00:00');
    const end = dayjs(endDate).endOf('month').format('YYYY-MM-DD 23:59:59');

    const { data } = useQuery({
        queryKey: getEventsQueryKey(params),
        queryFn: () =>
            getEvents({
                location,
                worktype,
                start,
                end,
            }),
        initialData: [],
        enabled:
            !!start &&
            !!end &&
            location !== '' &&
            location !== undefined &&
            location !== null &&
            worktype !== '' &&
            worktype !== undefined &&
            worktype !== null,
    });

    const { events, durationTotal, reductionTotal } = data;
    return { events, durationTotal, reductionTotal };
}

export function useDeleteEvent(filterParams) {
    return useDeleteRecord({
        deleteRecordFn: deleteEvent,
        fetchRecordsQueryKey: getEventsQueryKey(filterParams),
        recordName: 'Event',
    });
}

export function usePostEvent({ filterParams, onSuccess }) {
    const { dispatchErrorNotification } = useDispatchErrorNotification();
    const { dispatchSuccessfullySavedRecord } = useDispatchSuccessfullySavedRecord();

    return useMutation({
        mutationFn: postEvent,
        onSuccess: () => {
            queryClient.invalidateQueries(getEventsQueryKey(filterParams));
            onSuccess();
            dispatchSuccessfullySavedRecord('Event');
        },
        onError: (error) => {
            dispatchErrorNotification({ message: error.message });
        },
    });
}
