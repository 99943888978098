import { useState, useRef, useContext, useCallback, useEffect } from 'react';
import { Grid, Checkbox, Button } from '@mui/material';
import Container from '../components/Layout/Container';
import UserContext from '../store/User/UserContext';
import {
    useLocationsByLoggedInUserRole,
    useCookieLocation,
    useAlertMessage,
    useIsMounted,
    useCustomStyles,
} from '../hooks';
import ButtonIconRenderer from '../components/AgGrid/ButtonIconRenderer';
import { stringToNumberFormatter } from '../utils/agGridUtils';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
    getUpcomingMonths,
    getDateRangeFromStartDateByAddingMonths,
    isStringInYearMonthFormat,
    formatLastUploadDate,
} from '../utils/dateUtils';
import { addRowsInMap } from '../components/StrategicVolume/helpers/utils';
import { createTableRows } from '../utils/agGridUtils';
import {
    getStrategicVolumeReport,
    createOneStrategicVolumeReport,
    createStrategicVolumeReport,
    markStrategicVolumeReportsRelease,
    compareStrategicVolumeAndCapacityReport,
    uploadStrategicVolumes,
    getLastStrategicVolumeUploadDate,
} from '../services/strategicVolumeReport';
import { CheckboxCellRenderer } from '../components/AgGrid/CheckboxCellRenderer/CheckboxCellRenderer';
import { ReactComponent as FileExport } from '../assets/file-export.svg';
import ConfirmationDialog from '../components/UI/Dialogs/ConfirmationDialog';
import { StrategicVolumeReportRelease } from '../enums/StrategicVolumeReportRelease';
import StrategicVolumeExportDialog from '../components/StrategicVolume/StrategicVolumeExportDialog';
import { updateStrategicVolume } from '../services/strategicVolume';
import MatchingCapacityReportTooltip from '../components/StrategicVolume/MatchingCapacityReportTooltip';
import dayjs from 'dayjs';
import { parseStringToFloat } from '../utils/numberUtils';
import { parseXLSXData } from '../utils/xlsxUtils';
import PercentageLoadingOverlay from '../components/Loaders/PercentageLoadingOverlay';
import UserRoleLocation from '../components/General/UserRoleLocation';
import UploadXlsxFileButton from '../components/Buttons/UploadXlsxFileButton';
import {
    setCommonStateAfterDataUpload,
    showSuccessfulUploadNotification,
    showErrorUploadNotification,
} from '../utils/componentUtils';
import LastUpload from '../components/General/LastUpload';
import * as Sentry from '@sentry/react';
import { isStringValidJSON } from '../utils';
import TableGridItem from '../components/UI/TableGridItem';
import GridContainer from '../components/Layout/GridContainer';

const fetchTableData = async (firstMonth, location, userId, isTEFUser, numOfMonthColumns) => {
    const { dateFrom, dateTo } = getDateRangeFromStartDateByAddingMonths(firstMonth, numOfMonthColumns);

    const releaseType = isTEFUser ? null : StrategicVolumeReportRelease.RELEASED;

    try {
        const strategicVolumeReport = await getStrategicVolumeReport(location, dateFrom, dateTo, userId, releaseType);
        const monthColumns = getUpcomingMonths(firstMonth, numOfMonthColumns);
        const rowsMap = addRowsInMap({ strategicVolumes: strategicVolumeReport, monthColumns });
        const rows = createTableRows(rowsMap, 'id');
        return { success: true, rows };
    } catch (error) {
        return { success: false, error };
    }
};

const StrategicVolume = () => {
    const classes = useCustomStyles();

    const { dispatchAlert } = useAlertMessage();
    const loggedInUser = useContext(UserContext);
    const allowDataEdit = loggedInUser && loggedInUser?.role?.callmart_tef;
    const NUM_OF_MONTH_COLUMNS = loggedInUser && loggedInUser.role.callmart_tef ? 18 : 6;

    const isForecasterFilterApplied = useRef(false);

    const isMounted = useIsMounted();
    const gridRef = useRef(null);
    const [locationOptions] = useLocationsByLoggedInUserRole();
    const [selectedLocation, setSelectedLocation] = useCookieLocation(locationOptions);
    const [lastUploadDate, setLastUploadDate] = useState();

    const [firstMonth, setFirstMonth] = useState(dayjs());
    const [tableRows, setTableRows] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);

    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [openExportDialog, setOpenExportDialog] = useState(false);

    const [loading, setLoading] = useState(false);
    const [percentage, setPercentage] = useState(0);

    const goBackMonth = useCallback(
        (numOfMonths) => {
            const previousMonth = dayjs(firstMonth, 'YYYY-MM-DD').subtract(numOfMonths, 'months');
            setFirstMonth(previousMonth);
        },
        [firstMonth]
    );

    const goForwardMonth = useCallback(
        (numOfMonths) => {
            const nextMonth = dayjs(firstMonth, 'YYYY-MM-DD').add(numOfMonths, 'months');
            setFirstMonth(nextMonth);
        },
        [firstMonth]
    );

    const selectRow = (params) => {
        params.data.selected = !params.data.selected;
        gridRef.current.api.redrawRows();
    };

    const selectAllRows = (e) => {
        gridRef.current.api.forEachNode((rowNode) => {
            rowNode.data.selected = e.target.checked;
        });

        gridRef.current.api.redrawRows();
    };

    const createColumnDefs = useCallback(
        (month) => {
            const monthColumns = getUpcomingMonths(month, NUM_OF_MONTH_COLUMNS);

            let columns = [
                {
                    field: 'id',
                    headerName: 'Freigabe',
                    cellClass: 'grid-cell-centered',
                    suppressMovable: true,
                    minWidth: 62,
                    headerComponent: () => {
                        return (
                            <div className="freigabe-cell" style={{ textAlign: 'center' }}>
                                <span>Freigabe</span>
                                <br />
                                <Checkbox onChange={selectAllRows} style={{ padding: 0 }}></Checkbox>
                            </div>
                        );
                    },
                    cellRenderer: (params) => {
                        return (
                            <Checkbox
                                checked={params.data.selected}
                                disabled={!allowDataEdit}
                                onChange={() => selectRow(params, tableRows)}
                                style={{ padding: 0 }}
                            ></Checkbox>
                        );
                    },
                    editable: allowDataEdit,
                    hide: !loggedInUser?.role?.callmart_tef && loggedInUser?.role?.callmart_osp,
                },
                {
                    field: 'forecasterNames',
                    headerName: 'Forecaster',
                    filter: 'agSetColumnFilter',
                    minWidth: 100,
                    flex: 2,
                },
                {
                    field: 'workingUnit.worktypeLocation.worktype.name',
                    headerName: 'Worktype',
                    minWidth: 100,
                    flex: 2,
                },
                {
                    field: 'contact',
                    headerName: 'Kontaktart',
                    minWidth: 90,
                },
                {
                    field: 'workingUnit.channel.name',
                    headerName: 'Kanal',
                    minWidth: 70,
                },
                {
                    field: 'workingUnit.task.name',
                    headerName: 'Task',
                    minWidth: 60,
                },
                {
                    field: 'capacityType.name',
                    headerName: 'Capacity',
                    minWidth: 80,
                },
                {
                    field: 'capacityType.unit',
                    headerName: 'Einheit',
                    minWidth: 40,
                },
                {
                    field: 'comment',
                    headerName: 'Anmerkungen',
                    editable: true,
                    suppressPaste: true,
                    minWidth: 80,
                },
            ];

            for (const monthColumn of monthColumns) {
                columns.push({
                    field: `${monthColumn.format('YYYY-MM')}`,
                    headerName: `${monthColumn.format('MMM YY')}`,
                    tooltipComponent: MatchingCapacityReportTooltip,
                    tooltipField: `${monthColumn.format('YYYY-MM')}`,
                    cellRenderer: (params) => {
                        const { column, data, value } = params;
                        const key = column.colId;
                        let cellClasses = '';

                        if (data?.selected && data[key].value) {
                            cellClasses += ' marked-for-release';
                        }

                        if (data?.[key]?.release === StrategicVolumeReportRelease.READY_FOR_RELEASE) {
                            cellClasses += ' ready-for-release';
                        } else if (data?.[key]?.release === StrategicVolumeReportRelease.RELEASED) {
                            cellClasses += ' released';
                        }

                        const cellHtml = (
                            <span className={cellClasses}>
                                {value}
                                {Number.isFinite(data[key]?.differenceWithCapacity) && (
                                    <span style={{ color: 'red' }}> ({data[key]?.differenceWithCapacity})</span>
                                )}
                            </span>
                        );

                        return cellHtml;
                    },
                    valueGetter: (params) => {
                        const value = params?.data[`${monthColumn.format('YYYY-MM')}`]?.value;
                        return value !== undefined && value !== null ? Math.round(value) : value;
                    },
                    valueSetter: (params) => {
                        params.data[`${monthColumn.format('YYYY-MM')}`].value =
                            params.newValue === '' ? 0 : parseStringToFloat(params.newValue);
                        return true;
                    },
                    valueFormatter: stringToNumberFormatter,
                    suppressMovable: true,
                    editable: allowDataEdit,
                });
            }

            const defaultArrowColumnDefs = {
                field: ``,
                cellClass: 'grid-cell-centered',
                headerComponent: ButtonIconRenderer,
                maxWidth: 25,
                editable: false,
                sortable: false,
                resizable: false,
                filter: false,
                suppressMovable: true,
            };

            if (allowDataEdit) {
                columns = [
                    {
                        ...defaultArrowColumnDefs,
                        headerComponentParams: {
                            icon: ArrowBackIosIcon,
                            onClick: () => goBackMonth(6),
                            iconStyle: { width: '14px', height: '14px' },
                        },
                        pinned: 'left',
                    },
                    ...columns,
                    {
                        ...defaultArrowColumnDefs,
                        headerComponentParams: {
                            icon: ArrowForwardIosIcon,
                            onClick: () => goForwardMonth(6),
                            iconStyle: { width: '14px', height: '14px' },
                        },
                        pinned: 'right',
                    },
                ];
            }

            setColumnDefs(columns);
        },
        [
            goBackMonth,
            goForwardMonth,
            allowDataEdit,
            tableRows,
            loggedInUser?.role?.callmart_tef,
            loggedInUser?.role?.callmart_osp,
            NUM_OF_MONTH_COLUMNS,
        ]
    );

    const setTableData = useCallback(
        async (firstMonth, selectedLocation, loggedInUserId, isTEFUser) => {
            gridRef?.current?.api?.showLoadingOverlay();
            const res = await fetchTableData(
                firstMonth,
                selectedLocation,
                loggedInUserId,
                isTEFUser,
                NUM_OF_MONTH_COLUMNS
            );
            if (res.success) {
                if (res.rows.length) {
                    gridRef?.current?.api?.hideOverlay();
                    setTableRows(res.rows);
                } else {
                    gridRef?.current?.api?.showNoRowsOverlay();
                }
            } else {
                gridRef?.current?.api?.hideOverlay();
                dispatchAlert({
                    message: 'Beim Abrufen des Strategischen Forecast ist ein Fehler aufgetreten.',
                    type: 'error',
                });
            }
        },
        [dispatchAlert, NUM_OF_MONTH_COLUMNS]
    );

    const processCellForClipboard = (params) => {
        return params.value.toString().replace('.', ',');
    };

    const handleCellValueChange = (params) => {
        if (isStringInYearMonthFormat(params.column.colId)) params.data[params.column.colId].changed = true;
    };

    const handleCellEdit = async (params) => {
        if (isStringInYearMonthFormat(params.column.colId)) {
            const enteredValue = parseStringToFloat(params.value);
            if (enteredValue && Number.isFinite(enteredValue)) {
                saveStrategicVolumeReport({
                    cellParams: params,
                    loggedInUserId: loggedInUser?.id,
                    isTEFUser: loggedInUser?.role.callmart_tef,
                    firstMonth,
                    selectedLocation,
                });
            } else {
                dispatchAlert({
                    message: 'Geben Sie eine gültige Zahl ein',
                    type: 'error',
                });
            }
        } else {
            await updateStrategicVolume({
                id: params.data.strategicVolumeId,
                [params.column.colId]: params.value,
            });
        }
    };

    const onPasteEnd = () =>
        saveStrategicVolumeReport({
            tableRows,
            loggedInUserId: loggedInUser?.id,
            isTEFUser: loggedInUser?.role?.callmart_tef,
            firstMonth,
            selectedLocation,
        });

    const saveStrategicVolumeReport = useCallback(
        async ({ tableRows, loggedInUserId, isTEFUser, firstMonth, selectedLocation, cellParams }) => {
            try {
                let isDataSaved = false;
                let numOfNewReports = 0;
                if (cellParams) {
                    if (cellParams.newValue) {
                        let data = {
                            report: {
                                ...cellParams.data,
                                date: cellParams.column.colId,
                                value: cellParams.newValue,
                            },
                            userId: loggedInUserId,
                        };

                        await createOneStrategicVolumeReport(data);
                        isDataSaved = true;
                    }
                } else {
                    const result = await createStrategicVolumeReport(tableRows, loggedInUserId);
                    isDataSaved = numOfNewReports > 0 ? true : false;
                    numOfNewReports = result.numOfNewReports;
                }

                if (isDataSaved) {
                    dispatchAlert({
                        message: 'Strategischer Forecast gespeichert',
                        type: 'success',
                    });
                } else if (numOfNewReports === 0) {
                    dispatchAlert({
                        message: 'Geben Sie gültige Daten ein',
                        type: 'error',
                    });
                }
            } catch (error) {
                dispatchAlert({
                    message: 'Strategischer Forecast konnte nicht gespeichert werden',
                    type: 'error',
                });
            }

            setTableData(firstMonth, selectedLocation, loggedInUserId, isTEFUser);
        },
        [dispatchAlert, setTableData]
    );

    const markReportRelease = (rows, releaseType) => {
        markStrategicVolumeReportsRelease({ strategicVolumes: rows, releaseType }).then((res) => {
            if (res.success) {
                gridRef.current.api.forEachNode((rowNode) => {
                    if (rowNode.data.selected) {
                        const rowNodeData = {
                            ...rowNode.data,
                            ...(releaseType === StrategicVolumeReportRelease.RELEASED && { selected: false }),
                        };
                        for (const key of Object.keys(rowNode.data)) {
                            if (isStringInYearMonthFormat(key)) {
                                rowNodeData[key] = {
                                    ...rowNodeData[key],
                                    ...(rowNode.data[key].value && { release: releaseType }),
                                };
                            }
                        }

                        rowNode.setData({ ...rowNodeData });
                    }

                    if (showConfirmationDialog) setShowConfirmationDialog(false);
                });
            }
        });
    };

    const handleMarkReportReleaseClick = async (rows, releaseType) => {
        if (releaseType === StrategicVolumeReportRelease.RELEASED) {
            setShowConfirmationDialog(true);
            return;
        }

        const selectedRows = rows.filter((row) => row.selected);
        if (!selectedRows.length) {
            dispatchAlert({
                message: 'Keine Felder markiert. Bitte wähle die Reihen aus die du freigeben möchtest.',
                type: 'error',
            });

            return;
        }

        markReportRelease(selectedRows, releaseType);
    };

    const closeConfirmationDialog = () => {
        setShowConfirmationDialog(false);
    };

    const handleExportDialogClose = () => {
        setOpenExportDialog(false);
    };

    const handleExportDialogOpen = () => {
        setOpenExportDialog(true);
    };

    const compareWithCapacityReport = async (firstMonth, location, userId, isTEFUser) => {
        const { dateFrom, dateTo } = getDateRangeFromStartDateByAddingMonths(firstMonth, NUM_OF_MONTH_COLUMNS);

        const releaseType = isTEFUser ? null : StrategicVolumeReportRelease.RELEASED;
        const strategicVolumes = await compareStrategicVolumeAndCapacityReport(
            location,
            dateFrom,
            dateTo,
            userId,
            releaseType
        );

        const monthColumns = getUpcomingMonths(firstMonth, NUM_OF_MONTH_COLUMNS);
        const rowsMap = addRowsInMap({ strategicVolumes, monthColumns, compareWithCapacityReport: true });
        const rows = createTableRows(rowsMap, 'id');

        setTableRows(rows);
    };

    const uploadFiles = (files, closeDropzoneDialog) => {
        setLoading(true);

        const file = files[0];

        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = parseXLSXData(e, 'locationCode');

            await uploadStrategicVolumes({ data, userId: loggedInUser.id }, setPercentage)
                .then(async (res) => {
                    const response = res[0];
                    if (!response || !isStringValidJSON(response)) {
                        console.log('Strategic Volume upload response: ', res);
                    }
                    const resData = JSON.parse(response);
                    const { success, errorMessage } = resData;

                    if (success) {
                        showSuccessfulUploadNotification(dispatchAlert);
                    } else {
                        showErrorUploadNotification({
                            dispatchAlert,
                            errorMessage,
                        });
                    }
                })
                .catch(async (error) => {
                    Sentry.captureException(error);
                    showErrorUploadNotification({
                        dispatchAlert,
                        errorMessage: error.message,
                    });
                })
                .finally(async () => {
                    setCommonStateAfterDataUpload(setLoading, setPercentage, closeDropzoneDialog);

                    let lastUpload = await getLastStrategicVolumeUploadDate(selectedLocation);
                    if (lastUpload !== '') {
                        lastUpload = formatLastUploadDate(lastUpload);
                    }
                    setLastUploadDate(lastUpload);
                });
        };
        reader.readAsArrayBuffer(file);
    };

    useEffect(() => {
        if (firstMonth && isMounted) {
            createColumnDefs(firstMonth);
        }
    }, [firstMonth, createColumnDefs, isMounted]);

    useEffect(() => {
        if (selectedLocation && firstMonth && loggedInUser?.id && isMounted) {
            setTableData(firstMonth, selectedLocation, loggedInUser.id, loggedInUser.role.callmart_tef);
        }
    }, [selectedLocation, firstMonth, loggedInUser?.id, isMounted, loggedInUser?.role?.callmart_tef, setTableData]);

    const applyFilterOnForecaster = useCallback(() => {
        const filterInstance = gridRef.current.api.getFilterInstance('forecasterNames');
        let values = [];
        const name = loggedInUser?.name;
        if (filterInstance?.valueModel?.allValues.has(loggedInUser?.name)) {
            values = [name];
        }

        filterInstance.setModel({
            filterType: 'set',
            values,
        });
        gridRef.current.api.onFilterChanged();
        isForecasterFilterApplied.current = true;
    }, [loggedInUser?.name]);

    useEffect(() => {
        if (loggedInUser?.role.callmart_tef && isMounted && tableRows.length > 0 && gridRef.current?.api) {
            if (!isForecasterFilterApplied.current) {
                applyFilterOnForecaster();
            }
        }
    }, [applyFilterOnForecaster, tableRows, isMounted, gridRef.current?.api, loggedInUser?.role.callmart_tef]);

    useEffect(() => {
        if (selectedLocation) {
            getLastStrategicVolumeUploadDate(selectedLocation).then((res) => {
                const lastUpload = formatLastUploadDate(res);
                setLastUploadDate(lastUpload);
            });
        }
    }, [selectedLocation]);

    function onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    return (
        <Container>
            <GridContainer classes={loading ? classes.containerOpacity : ''}>
                {loggedInUser && (
                    <UserRoleLocation
                        loggedInUser={loggedInUser}
                        selectedLocation={selectedLocation}
                        locationOptions={locationOptions}
                        onChange={setSelectedLocation}
                    ></UserRoleLocation>
                )}
                <Grid item xs={loggedInUser?.role.callmart_tef ? 7 : 10} md={3} lg={2}>
                    <LastUpload showInfoIcon={false} date={lastUploadDate}></LastUpload>
                </Grid>
                <Grid item>
                    <Button onClick={handleExportDialogOpen} variant="contained">
                        <FileExport />
                    </Button>
                </Grid>
                <Grid item>
                    <UploadXlsxFileButton
                        buttonName="Upload"
                        variant="contained"
                        uploadFiles={uploadFiles}
                        dialogTitle="Forecasts hochladen"
                    ></UploadXlsxFileButton>
                </Grid>
                {loggedInUser?.role.callmart_tef && (
                    <Grid item>
                        <Button
                            onClick={() =>
                                compareWithCapacityReport(firstMonth, selectedLocation, loggedInUser?.id, allowDataEdit)
                            }
                            variant="outlined"
                            style={{ marginRight: '0.5rem' }}
                        >
                            Abgleich
                        </Button>
                        <Button
                            onClick={() =>
                                handleMarkReportReleaseClick(tableRows, StrategicVolumeReportRelease.READY_FOR_RELEASE)
                            }
                            variant="contained"
                            style={{ backgroundColor: 'orange', color: 'white', marginRight: '0.5rem' }}
                        >
                            Freigabe vormerken
                        </Button>
                        <Button
                            onClick={() =>
                                handleMarkReportReleaseClick(tableRows, StrategicVolumeReportRelease.RELEASED)
                            }
                            variant="contained"
                            style={{ backgroundColor: 'var(--lightGreen)', color: 'white' }}
                        >
                            Freigabe aktivieren
                        </Button>
                    </Grid>
                )}

                <TableGridItem
                    ref={gridRef}
                    rowData={tableRows}
                    defaultColDef={{
                        editable: false,
                        filter: true,
                        sortable: true,
                    }}
                    columnDefs={columnDefs}
                    components={{
                        checkboxCellRenderer: CheckboxCellRenderer,
                    }}
                    onCellEditingStopped={handleCellEdit}
                    onCellValueChanged={handleCellValueChange}
                    onPasteEnd={onPasteEnd}
                    processCellForClipboard={processCellForClipboard}
                    tooltipShowDelay={0}
                    tooltipHideDelay={3000}
                    onFirstDataRendered={onFirstDataRendered}
                ></TableGridItem>
            </GridContainer>
            {showConfirmationDialog && (
                <ConfirmationDialog
                    open={showConfirmationDialog}
                    title="Strategischer Forecast Freigabe"
                    text="Möchtest du die Forecast Werte für alle Standorte freigeben?"
                    onClose={closeConfirmationDialog}
                    onConfirm={() =>
                        markReportRelease(
                            tableRows.filter((row) => row.selected),
                            StrategicVolumeReportRelease.RELEASED
                        )
                    }
                />
            )}
            {openExportDialog && (
                <StrategicVolumeExportDialog
                    open={openExportDialog}
                    onClose={handleExportDialogClose}
                    locations={locationOptions}
                ></StrategicVolumeExportDialog>
            )}
            <PercentageLoadingOverlay loading={loading} percentage={percentage}></PercentageLoadingOverlay>
        </Container>
    );
};

export default StrategicVolume;
