import CachedIcon from '@mui/icons-material/Cached';

export const EventNameCellRenderer = ({ data, value }) => {
    const { eventSerieId, reductionSerieId } = data;
    if (!eventSerieId && !reductionSerieId) return <>{value}</>;
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <CachedIcon fontSize="small" /> {value}
        </div>
    );
};
