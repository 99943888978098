import { FormControl, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ReactHookFormErrorLabel from './ReactHookFormErrorLabel';

const ReactHookFormDateTimePicker = ({
    name,
    label,
    control,
    defaultValue,
    rules,
    formErrors,
    mindatetime,
    ...props
}) => {
    return (
        <FormControl {...props} fullWidth={true}>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={rules}
                render={({ field, fieldState }) => (
                    <>
                        <LocalizationProvider adapterLocale="de" dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                {...field}
                                name={name}
                                renderInput={(props) => <TextField {...props} fullWidth />}
                                label={label}
                                ampm={false}
                                inputFormat="DD.MM.YYYY HH:mm"
                                mask="__.__.____ __:__"
                                minDateTime={mindatetime}
                                {...props}
                            />
                        </LocalizationProvider>
                        {fieldState?.error && (
                            <ReactHookFormErrorLabel
                                fieldError={fieldState.error}
                                fieldName={name}
                                formErrors={formErrors}
                            ></ReactHookFormErrorLabel>
                        )}
                    </>
                )}
            />
        </FormControl>
    );
};

export default ReactHookFormDateTimePicker;
