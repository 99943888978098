import { useAlertMessage } from '.';
import { useCallback } from 'react';

export function useDispatchErrorNotification() {
    const { dispatchAlert } = useAlertMessage();
    const dispatchErrorNotification = useCallback(
        ({ message }) =>
            dispatchAlert({
                message: message,
                type: 'error',
            }),
        [dispatchAlert]
    );

    return { dispatchErrorNotification };
}
