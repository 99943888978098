import { useQuery } from '@tanstack/react-query';
import { getChannels, createChannel, updateChannel } from '../../services/channel';
import { QueryKey } from '../../enums/QueryKey';
import { useUpdateRecord, useCreateRecord } from './crudOperations';

export function useChannels(params) {
    const name = params?.name;

    const { data: channels } = useQuery({
        queryKey: [QueryKey.Channels, { ...(name && name) }],
        queryFn: () => getChannels({ name }),
        initialData: [],
    });

    return [channels];
}

export function useCreateChannel() {
    return useCreateRecord({
        createRecordFn: createChannel,
        fetchRecordsQueryKey: QueryKey.Channels,
        recordName: 'Channel',
    });
}

export function useUpdateChannel() {
    return useUpdateRecord({
        updateRecordFn: updateChannel,
        fetchRecordsQueryKey: QueryKey.Channels,
        recordName: 'Channel',
    });
}
