import api from './index';
import { setToken } from '../utils/authUtils';
import { fetchReadableStream } from './fetchReadableStream';

const BASE = '/callCenterPotential';

export const getAllCallCenterPotentialsByFilters = async (dateFrom, dateTo, location, worktype, channel) => {
    return api
        .get(
            `${BASE}/getAllCallCenterPotentialsByFilters?dateFrom=${dateFrom}&dateTo=${dateTo}&location=${location}&worktype=${worktype}&channel=${channel}`,
            await setToken()
        )
        .then((res) => res.data);
};

export const uploadCallCenterPotentials = async (data, setPercentage) => {
    return await fetchReadableStream(`${BASE}/uploadPotentials`, data, setPercentage);
};

export const getCallCenterPotentialsByDateRangeLocationAndWorktype = async (dateFrom, dateTo, location, worktype) => {
    return api
        .get(
            `${BASE}/getCallCenterPotentialsByDateRangeLocationAndWorktype?dateFrom=${dateFrom}&dateTo=${dateTo}&location=${location}&worktype=${worktype}`,
            await setToken()
        )
        .then((res) => res.data);
};

export const getSumOfCallCenterPotentialsBySlotAndChannel = async (
    dateFrom,
    dateTo,
    locationPriority,
    worktype,
    channel
) => {
    return api
        .get(
            `${BASE}/getSumOfCallCenterPotentialsBySlotAndChannel?dateFrom=${dateFrom}&dateTo=${dateTo}&locationPriority=${locationPriority}&worktype=${worktype}&channel=${channel}`,
            await setToken()
        )
        .then((res) => res.data);
};
