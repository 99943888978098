import { useState, useCallback } from 'react';
import CRUDSettings from '../components/General/CRUDSettings';
import {
    useAlertMessage,
    useChannels,
    useCapacityTypes,
    useTasks,
    useCapacities,
    useCreateCapacity,
    useUpdateCapacity,
    useAllHEMTLocationsWithWorktypes,
    useWorktypeLocations,
} from '../hooks';
import CreateCapacityDialog from '../components/Capacities/CreateCapacityDialog';

const Capacities = () => {
    const { dispatchAlert } = useAlertMessage();

    const [locationsWithWorktypes] = useAllHEMTLocationsWithWorktypes();
    const [worktypeLocations] = useWorktypeLocations();
    const [capacities] = useCapacities();
    const [channels] = useChannels();
    const [tasks] = useTasks();
    const [capacityTypes] = useCapacityTypes();

    const [showCreateCapacityDialog, setShowCreateCapacityDialog] = useState(false);

    const { mutate: handleCreateCapacity } = useCreateCapacity({
        onSettled: () => {
            setShowCreateCapacityDialog(false);
        },
    });
    const { mutate: handleUpdateCapacity } = useUpdateCapacity({
        onSettled: () => {
            setShowCreateCapacityDialog(false);
        },
    });

    const validateData = useCallback(
        (data) => {
            const { location, worktypeLocation } = data;
            const matchingWorktypeLocation = worktypeLocations.find(
                (wtLoc) => wtLoc.locationId === location.id && wtLoc.worktypeId === worktypeLocation.worktype.id
            );

            if (matchingWorktypeLocation) return true;

            return dispatchAlert({
                message: `Please select valid worktype for the selected location`,
                type: 'error',
            });
        },
        [dispatchAlert, worktypeLocations]
    );

    const getWorktypeValues = (params) => {
        const selectedLocation = params.data.location;
        const worktypes = locationsWithWorktypes.find((loc) => loc.id === selectedLocation.id).worktypes;
        return worktypes;
    };

    const getCellValue = (params, colName, secondColName) => {
        if (params.value) return `${params.value[colName]} ${secondColName ? ` - ${params.value[secondColName]}` : ''}`;
        return '';
    };

    const getCommonColDefs = (values, colName, secondColName) => {
        return {
            editable: (params) => params.data.isRowEdited || params.data.isNewRecord,
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: (params) => ({
                values: Array.isArray(values) ? values : values(params),
                cellRenderer: (params) => getCellValue(params, colName, secondColName),
            }),
            cellRenderer: (params) => getCellValue(params, colName, secondColName),
        };
    };

    const columnDefs = [
        {
            field: 'location',
            headerName: 'Standort',
            ...getCommonColDefs(locationsWithWorktypes, 'city', 'code'),
            filterParams: {
                valueGetter: (params) => {
                    return `${params.data?.location.code} - ${params.data?.location.city}`;
                },
            },
        },
        {
            field: 'worktypeLocation.worktype',
            headerName: 'Worktype',
            ...getCommonColDefs(getWorktypeValues, 'worktype'),
            filterParams: {
                valueGetter: (params) => {
                    return params.data?.worktypeLocation.worktype.worktype;
                },
            },
        },
        {
            field: 'channel',
            headerName: 'Kanal',
            ...getCommonColDefs(channels, 'name'),
            filterParams: {
                valueGetter: (params) => {
                    return params.data?.channel.name;
                },
            },
        },
        {
            field: 'task',
            headerName: 'Task',
            ...getCommonColDefs(tasks, 'name'),
            filterParams: {
                valueGetter: (params) => {
                    return params.data?.task.name;
                },
            },
        },
        {
            field: 'capacityType',
            headerName: 'Capacity Type',
            ...getCommonColDefs(capacityTypes, 'name'),
            filterParams: {
                valueGetter: (params) => {
                    return params.data?.capacityType.name;
                },
            },
        },
    ];

    return (
        <>
            <CRUDSettings
                tableRows={capacities}
                columnDefs={columnDefs}
                columnToFocusOnEdit="location"
                btnText="Neuer Capacity"
                recordType="Capacity"
                updateRecord={handleUpdateCapacity}
                recordStructure={{
                    location: null,
                    worktypeLocation: { worktype: null },
                    channel: null,
                    task: null,
                    capacityType: null,
                }}
                validateData={validateData}
                pagination={true}
                paginationPageSize={10}
                isCreationViaDialog={true}
                setShowCreationDialog={setShowCreateCapacityDialog}
            ></CRUDSettings>
            {showCreateCapacityDialog && (
                <CreateCapacityDialog
                    taskOptions={tasks}
                    channelOptions={channels}
                    capacityTypeOptions={capacityTypes}
                    showDialog={showCreateCapacityDialog}
                    onClose={() => setShowCreateCapacityDialog(false)}
                    onSubmit={handleCreateCapacity}
                ></CreateCapacityDialog>
            )}
        </>
    );
};

export default Capacities;
