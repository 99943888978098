import { Checkbox, FormControlLabel } from '@mui/material';
import { Controller } from 'react-hook-form';

const ReactHookFormCheckbox = ({ name, control, label, labelPlacement }) => {
    return (
        <FormControlLabel
            control={
                <Controller
                    name={name}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => <Checkbox {...field} checked={field.value} />}
                />
            }
            label={label}
            labelPlacement={labelPlacement}
        />
    );
};

export default ReactHookFormCheckbox;
