import { useQuery } from '@tanstack/react-query';
import {
    getLatestOfferedReports,
    getOfferedReportExportData,
    getOfferedReportSummary,
} from '../../services/offeredReport';
import { QueryKey } from '../../enums/QueryKey';
import { getStartAndEndOfAMonth } from '../../utils/dateUtils';

export function getLatestOfferedReportsQueryKey(date, offeredCluster) {
    const { dateFrom, dateTo } = getStartAndEndOfAMonth(date);
    return [
        QueryKey.LatestOfferedReports,
        {
            dateFrom: dateFrom.format('YYYY-MM-DD HH:mm:ss'),
            dateTo: dateTo.format('YYYY-MM-DD HH:mm:ss'),
            offeredCluster,
        },
    ];
}

export function useLatestOfferedReports({ date, offeredCluster }) {
    const { dateFrom, dateTo } = getStartAndEndOfAMonth(date);
    const queryKey = getLatestOfferedReportsQueryKey(date, offeredCluster);

    const { data, refetch } = useQuery({
        queryKey,
        queryFn: () => getLatestOfferedReports({ dateFrom, dateTo, offeredCluster }),
        enabled: !!date && !!offeredCluster,
    });

    return { data, refetch };
}

export function useOfferedReportsSummary({ date }) {
    const { dateFrom, dateTo } = getStartAndEndOfAMonth(date);

    const { data, refetch } = useQuery({
        queryKey: [
            QueryKey.OfferedReportsSummary,
            {
                dateFrom: dateFrom.format('YYYY-MM-DD HH:mm:ss'),
                dateTo: dateTo.format('YYYY-MM-DD HH:mm:ss'),
            },
        ],
        queryFn: () => getOfferedReportSummary({ dateFrom, dateTo }),
        enabled: !!date,
    });

    return { data, refetch };
}

export function useOfferedReportsExportData({ date, fetchImmediately = false }) {
    const { dateFrom, dateTo } = getStartAndEndOfAMonth(date);

    const { data, refetch } = useQuery({
        queryKey: [
            QueryKey.OfferedReportsExport,
            {
                dateFrom: dateFrom.format('YYYY-MM-DD HH:mm:ss'),
                dateTo: dateTo.format('YYYY-MM-DD HH:mm:ss'),
            },
        ],
        queryFn: () => getOfferedReportExportData({ dateFrom, dateTo }),
        enabled: !!date && fetchImmediately,
    });

    return { data, refetch };
}
