import CRUDSettings from '../components/General/CRUDSettings';
import { useTasks, useCreateTask, useUpdateTask } from '../hooks';

const Tasks = () => {
    const [tasks] = useTasks();
    const { mutate: handleCreateTask } = useCreateTask();
    const { mutate: handleUpdateTask } = useUpdateTask();

    const columnDefs = [
        {
            field: 'name',
            headerName: 'Task',
            editable: (params) => params.data.isRowEdited || params.data.isNewRecord,
        },
    ];

    return (
        <CRUDSettings
            tableRows={tasks}
            columnDefs={columnDefs}
            columnToFocusOnEdit="name"
            btnText="Neuer Task"
            headerForNameColumn="Task"
            createRecord={handleCreateTask}
            updateRecord={handleUpdateTask}
            recordType="Task"
        ></CRUDSettings>
    );
};

export default Tasks;
