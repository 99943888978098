import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Portal } from '@mui/base/Portal';
import { useCustomStyles } from '../../../hooks';

function ResponsiveDialog({ open, onClose, title, children, isConfirmationDialog, onConfirm, actionButtons }) {
    const theme = useTheme();
    const classes = useCustomStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        onClose();
    };

    return (
        open && (
            <Portal>
                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
                    <DialogContent>{children}</DialogContent>
                    {actionButtons ? (
                        actionButtons
                    ) : (
                        <DialogActions className={isConfirmationDialog ? classes.dialogActions : ''}>
                            {isConfirmationDialog && (
                                <>
                                    <Button onClick={handleClose} variant="outlined">
                                        Abbrechen
                                    </Button>
                                    <Button onClick={onConfirm} variant="contained">
                                        Bestätigen
                                    </Button>
                                </>
                            )}
                            {!isConfirmationDialog && (
                                <Button onClick={handleClose} autoFocus>
                                    Schließen
                                </Button>
                            )}
                        </DialogActions>
                    )}
                </Dialog>
            </Portal>
        )
    );
}

export default ResponsiveDialog;
