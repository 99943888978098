import { useQuery } from '@tanstack/react-query';
import { createTask, getTasks, updateTask } from '../../services/task';
import { QueryKey } from '../../enums/QueryKey';
import { useUpdateRecord, useCreateRecord } from './crudOperations';

export function useTasks() {
    const { data: tasks } = useQuery({
        queryKey: [QueryKey.Tasks],
        queryFn: getTasks,
        initialData: [],
    });

    return [tasks];
}

export function useCreateTask() {
    return useCreateRecord({
        createRecordFn: createTask,
        fetchRecordsQueryKey: QueryKey.Tasks,
        recordName: 'Task',
    });
}

export function useUpdateTask() {
    return useUpdateRecord({
        updateRecordFn: updateTask,
        fetchRecordsQueryKey: QueryKey.Tasks,
        recordName: 'Task',
    });
}
