import { makeStyles } from '@mui/styles';

export const useCustomStyles = () => {
    const classes = makeStyles((theme) => ({
        btnRightAligned: {
            textAlign: 'right',
        },
        tableGridItem: {
            marginTop: theme.spacing(1),
        },
        containerOpacity: {
            opacity: 0.5,
        },
        availableBookingsHeader: {
            color: 'var(--primary)',
            opacity: 0.5,
            justifyContent: 'center',
            width: '15px !important',
            height: '15px !important',
            fontWeight: 'bold',
        },
        bookedBookingsHeader: {
            color: 'var(--primary)',
            justifyContent: 'center',
            width: '15px !important',
            height: '15px !important',
            fontWeight: 'bold',
        },
        contentEnd: {
            justifyContent: 'end',
        },
        dialogActions: {
            justifyContent: 'space-between',
            paddingLeft: '24px',
            paddingRight: '24px',
        },
        marginBottom: {
            marginBottom: theme.spacing(3),
        },
        error: {
            color: 'var(--error) !important',
        },
    }))();

    return classes;
};
