import { useQuery, useMutation } from '@tanstack/react-query';
import { getCapacities, createCapacity, updateCapacity } from '../../services/capacity';
import { QueryKey } from '../../enums/QueryKey';
import { queryClient } from '../../services/queryClient';
import { useDispatchErrorNotification, useDispatchSuccessfullySavedRecord } from '..';
import { useUpdateRecord } from './crudOperations';

export function useCapacities() {
    const { data: capacities } = useQuery({
        queryKey: [QueryKey.Capacities],
        queryFn: getCapacities,
        initialData: [],
    });

    return [capacities];
}

export function useCreateCapacity({ onSettled }) {
    const { dispatchErrorNotification } = useDispatchErrorNotification();
    const { dispatchSuccessfullySavedRecord } = useDispatchSuccessfullySavedRecord();

    return useMutation({
        mutationFn: createCapacity,
        onSuccess: (data) => {
            const { newCapacities } = data;
            const recordName = newCapacities.length > 1 ? 'Capacities' : 'Capacity';

            queryClient.invalidateQueries([QueryKey.Capacities]);
            dispatchSuccessfullySavedRecord(recordName);
        },
        onError: (error) => {
            dispatchErrorNotification({ message: error.message });
        },
        onSettled,
    });
}

export function useUpdateCapacity() {
    return useUpdateRecord({
        updateRecordFn: updateCapacity,
        fetchRecordsQueryKey: QueryKey.Capacities,
        recordName: 'Capacity',
    });
}
