import CustomSelect from '../UI/CustomSelect';
import MenuItem from '@mui/material/MenuItem';
import { plausibilityCheckOptions } from '../CapacityReport/helpers/constants';

function PlausibilityCheckSelect({ selectedValue, onChange }) {
    return (
        <CustomSelect
            selectedValue={selectedValue}
            options={plausibilityCheckOptions}
            onChange={onChange}
            label={'Plausibility Check'}
        >
            {plausibilityCheckOptions &&
                plausibilityCheckOptions.map(({ value, text }) => (
                    <MenuItem value={value} key={value}>
                        {text}
                    </MenuItem>
                ))}
        </CustomSelect>
    );
}

export default PlausibilityCheckSelect;
