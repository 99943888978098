import { useEffect, forwardRef } from 'react';
import { checkIsTableRowExpanded } from '../../utils/agGridUtils';
import { CapacityType } from '../../enums/CapacityType';
import CapacityHorizontalScrollTable from './CapacityHorizontalScrollTable';
import { getMonthColumns } from './helpers/utils';
import { useAlertMessage } from '../../hooks';
import { parseStringToFloat } from '../../utils/numberUtils';

const CapacityReportTable = forwardRef(
    (
        {
            firstMonth,
            onFirstMonthChanged,
            tableRows,
            saveCapacityReport,
            expandedRows,
            onExpandedRowsChanged,
            getExpandedRowsInTable,
        },
        ref
    ) => {
        const { dispatchAlert } = useAlertMessage();

        const calculateSum = (params) => {
            let sum = 0;
            if (params.rowNode.allLeafChildren.length > 0) {
                for (let i = 0; i < params.rowNode.allLeafChildren.length; i++) {
                    const child = params.rowNode.allLeafChildren[i];
                    if (child.data.capacityType.name === CapacityType.CUSTOMER_WORKLOAD.name) {
                        params.values[i] = child.data[params.column.colId]?.value || 0;
                    } else {
                        params.values[i] = 0;
                    }
                }
            }

            params.values.forEach((value) => (sum += value));

            return sum;
        };

        const processCellForClipboard = (params) => {
            return params?.value ? params.value.toString().replace('.', ',') : null;
        };

        const handleCellValueChange = (params) => {
            params.data[params.column.colId].changed = true;
        };

        const handleCellEdit = (params) => {
            const enteredValue = parseStringToFloat(params.value);
            if (enteredValue && Number.isFinite(enteredValue)) {
                saveCapacityReport({
                    cellParams: params,
                });
            } else {
                dispatchAlert({
                    message: 'Geben Sie eine gültige Nummer ein',
                    type: 'error',
                });
            }
        };

        const onPasteEnd = () => saveCapacityReport();

        useEffect(() => {
            if (tableRows?.length && expandedRows.length) {
                ref.current.api.forEachNode((node) => {
                    const isTableRowExpanded = expandedRows.find((n) => checkIsTableRowExpanded(n, node));
                    if (isTableRowExpanded) {
                        node.setExpanded(true);
                    }
                });
            }
        }, [tableRows, expandedRows, ref]);

        return (
            <CapacityHorizontalScrollTable
                ref={ref}
                firstMonth={firstMonth}
                onFirstMonthChanged={onFirstMonthChanged}
                tableRows={tableRows}
                expandedRows={expandedRows}
                onExpandedRowsChanged={onExpandedRowsChanged}
                getExpandedRowsInTable={getExpandedRowsInTable}
                enableRangeSelection={true}
                copyHeadersToClipboard={true}
                suppressAggFuncInHeader={true}
                onCellEditingStopped={handleCellEdit}
                onCellValueChanged={handleCellValueChange}
                onPasteEnd={onPasteEnd}
                processCellForClipboard={processCellForClipboard}
                tableType="CAPACITY_REPORT"
                defaultColDef={{
                    editable: true,
                    filter: true,
                    sortable: true,
                }}
                calculateSum={calculateSum}
                getMonthColumns={getMonthColumns}
            ></CapacityHorizontalScrollTable>
        );
    }
);

export default CapacityReportTable;
