import Potentials from './Potentials';
import Tabs from '../components/UI/Tabs';
import { PotentialsType } from '../enums/PotentialsType';
import Auswertung from '../components/Potentials/Auswertung';
import { LocationPriority } from '../enums/LocationPriority';

export default function PotentialsTabs() {
    const tabs = [
        {
            label: 'Offered',
            tabPanel: <Potentials type={PotentialsType.TEF_OFFERED} />,
        },
        {
            label: 'Handled',
            tabPanel: <Potentials type={PotentialsType.CALL_CENTER} />,
        },
        {
            label: 'Auswertung - Prio 1',
            tabPanel: <Auswertung locationPriority={LocationPriority.ONE}></Auswertung>,
        },
        {
            label: 'Auswertung - Prio Alle',
            tabPanel: <Auswertung locationPriority={LocationPriority.ALL}></Auswertung>,
        },
    ];

    return <Tabs tabs={tabs}></Tabs>;
}
