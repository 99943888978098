import { useState } from 'react';

export function useCellPopover() {
    const [showPopover, setShowPopover] = useState([]);
    const [hoveredCell, setHoveredCell] = useState(null);
    const [hoveredCellHistoryList, setHoveredCellHistoryList] = useState(null);

    const popoverOpen = (openPopover, cell, list) => {
        setShowPopover(openPopover);
        setHoveredCell(cell);
        setHoveredCellHistoryList(list);
    };

    const popoverClose = () => {
        setHoveredCell(null);
    };

    return { showPopover, hoveredCell, hoveredCellHistoryList, popoverOpen, popoverClose };
}
