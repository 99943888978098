import { useRef, useMemo, useCallback } from 'react';
import { useWorkingHours } from '../hooks/api/workingHours';
import { postWorkingHour, postWorkingHours } from '../services/workingHours';
import { parseStringToFloat } from '../utils/numberUtils';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '../services/queryClient';
import { useAlertMessage, useHEMTLocations, useWorktypes, useCookieWorktype } from '../hooks';
import { QueryKey } from '../enums/QueryKey';
import { weekDays } from '../utils/dateUtils';
import Container from '../components/Layout/Container';
import { Grid } from '@mui/material';
import ExportToExcelButton from '../components/Buttons/ExportToExcelButton';
import WorktypeSelect from '../components/Selects/WorktypeSelect';
import TableGridItem from '../components/UI/TableGridItem';

const workingHoursDays = [...weekDays, { name: 'Feiertage', value: 8 }];

const WorkingHours = () => {
    const gridRef = useRef(null);
    const { dispatchAlert } = useAlertMessage();

    const [worktypeOptions] = useWorktypes({ prependAllWorktypesOption: true });
    const [selectedWorktype, setSelectedWorktype] = useCookieWorktype(worktypeOptions);

    const [allLocationsFromHEMT] = useHEMTLocations();
    const { data: workingHours } = useWorkingHours({ worktype: selectedWorktype });
    const tableRows = workingHours?.length > 0 ? workingHours : [];

    const columnDefs = useMemo(() => {
        const columns = [
            {
                field: 'workingUnit.locationId',
                headerName: 'Standort',
                valueGetter: (params) => {
                    const location =
                        allLocationsFromHEMT &&
                        allLocationsFromHEMT.find((loc) => loc.id === params.data.workingUnit.locationId);
                    return location ? `${location.code}-${location.city}` : '';
                },
                rowGroup: true,
                hide: true,
                editable: false,
            },
            {
                field: 'workingUnit.worktype',
                headerName: 'Worktype',
                rowGroup: true,
                hide: true,
                editable: false,
            },
            {
                field: 'workingUnit.channel',
                headerName: 'Kanal',
                rowGroup: true,
                hide: true,
                editable: false,
            },
            {
                field: 'workingUnit.task',
                headerName: 'Task',
                rowGroup: true,
                hide: true,
                editable: false,
            },
            {
                field: 'timeSlot',
                headerName: 'Time',
                editable: false,
            },
        ];

        for (const dayColumn of workingHoursDays) {
            columns.push({
                field: `day${dayColumn.value}`,
                headerName: dayColumn.name,
                cellRenderer: (params) => {
                    let cellClass = 'text-center';

                    if (params?.data && params.data[`day${dayColumn.value}`]?.value === 1) {
                        cellClass += ' background-green';
                    }

                    return (
                        <span className={cellClass} style={{ textAlign: 'center' }}>
                            {params.value}
                        </span>
                    );
                },
                valueGetter: (params) => {
                    if (
                        params.data &&
                        params.data[`day${dayColumn.value}`]?.value !== null &&
                        params.data[`day${dayColumn.value}`]?.value !== undefined
                    )
                        return params.data[`day${dayColumn.value}`]?.value;

                    return null;
                },
                valueSetter: (params) => {
                    params.data[`day${dayColumn.value}`] = {
                        ...params.data[`day${dayColumn.value}`],
                        value: params.newValue,
                    };
                    return true;
                },
                suppressMovable: true,
                aggFunc: () => null,
            });
        }

        return columns;
    }, [allLocationsFromHEMT]);

    const { mutateAsync: handlePostWorkingHour } = useMutation({
        mutationFn: postWorkingHour,
    });
    const { mutateAsync: handlePostWorkingHours } = useMutation({
        mutationFn: postWorkingHours,
    });

    const saveWorkingHours = useCallback(
        async ({ tableRows, workingHourData }) => {
            try {
                if (workingHourData) {
                    await handlePostWorkingHour(workingHourData);
                } else {
                    await handlePostWorkingHours({
                        data: tableRows,
                    });
                }

                dispatchAlert({
                    message: 'Öffnungszeit gespeichert',
                    type: 'success',
                });
            } catch (error) {
                dispatchAlert({
                    message: 'Öffnungszeit konnte nicht gespeichert werden',
                    type: 'error',
                });
            } finally {
                queryClient.invalidateQueries([QueryKey.WorkingHours]);
            }
        },
        [dispatchAlert, handlePostWorkingHour, handlePostWorkingHours]
    );

    const handleCellEdit = async (params) => {
        const enteredValue = parseStringToFloat(params.value);
        if (enteredValue === 1 || enteredValue === 0) {
            const dayNumber = parseInt(params.column.colId.substr(-1));
            saveWorkingHours({
                workingHourData: {
                    workingUnitId: params.data.workingUnit.id,
                    timeSlot: params.data.timeSlot,
                    workingHourId: params.data[`day${dayNumber}`]?.workingHourId,
                    day: dayNumber,
                    value: enteredValue,
                },
            });
        } else {
            dispatchAlert({
                message: 'Geben Sie eine gültige Zahl ein',
                type: 'error',
            });
        }
    };

    const handleCellValueChange = (params) => {
        const dayNumber = parseInt(params.column.colId.substr(-1));
        params.data[`day${dayNumber}`].changed = true;
    };

    const onPasteEnd = () => {
        let numOfInvalidValues = 0;
        let numOfChangedCells = 0;
        for (const row of tableRows) {
            for (const cell of Object.keys(row)) {
                if (row[cell].changed === true) {
                    numOfChangedCells++;
                    const newValue = parseStringToFloat(row[cell].value);
                    if (newValue !== 1 && newValue !== 0) {
                        numOfInvalidValues++;
                    }
                }
            }
        }
        if (numOfInvalidValues === numOfChangedCells) {
            dispatchAlert({
                message: 'Geben Sie gültige Daten ein',
                type: 'error',
            });
        } else {
            saveWorkingHours({
                tableRows,
            });
        }
    };

    const autoGroupColumnDef = useMemo(() => {
        return {
            minWidth: 230,
            headerName: '',
            resizable: true,
        };
    }, []);

    const createExcelRowForExportFile = (exportDataRow) => {
        const { workingUnit, timeSlot } = exportDataRow;
        const { locationId, worktype, channel, task } = workingUnit;

        const location = allLocationsFromHEMT.find((loc) => loc.id === locationId);
        const locationData = location ? `${location.code}-${location.city}` : '';

        const row = [locationData, worktype, channel, task, timeSlot];
        const days = Object.keys(exportDataRow).filter((key) => key.startsWith('day'));
        for (let i = 1; i <= 8; i++) {
            const day = days.find((day) => day.endsWith(i));
            if (day) {
                row.push(exportDataRow[day].value);
            } else {
                row.push('');
            }
        }

        return row;
    };

    return (
        <Container>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <WorktypeSelect
                        selectedWorktype={selectedWorktype}
                        worktypeOptions={worktypeOptions}
                        onChange={setSelectedWorktype}
                    />
                </Grid>
                <Grid item xs={2} lg={1}>
                    <ExportToExcelButton
                        columnNames={columnDefs.map((columnDef) => columnDef.headerName)}
                        sheetName="Tabelle1"
                        getFileName={() => 'Öffnungszeiten.xlsx'}
                        wbTitle="Öffnungszeiten"
                        wbSubject="Export Öffnungszeiten"
                        exportData={workingHours}
                        createRowFromExportData={createExcelRowForExportFile}
                    />
                </Grid>
                <TableGridItem
                    ref={gridRef}
                    columnDefs={columnDefs}
                    animateRows={true}
                    rowData={tableRows}
                    autoGroupColumnDef={autoGroupColumnDef}
                    onPasteEnd={onPasteEnd}
                    onCellEditingStopped={handleCellEdit}
                    onCellValueChanged={handleCellValueChange}
                    suppressAggFuncInHeader={true}
                ></TableGridItem>
            </Grid>
        </Container>
    );
};

export default WorkingHours;
