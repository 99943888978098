import Tabs from '../components/UI/Tabs';
import CapacityAnalysis from './CapacityAnalysis';
import { CapacityAnalysisType } from '../enums/CapacityAnalysisType';

export default function CapacityAnalysisTabs() {
    const tabs = [
        {
            label: 'Vergleich Plan/Plan',
            tabPanel: <CapacityAnalysis type={CapacityAnalysisType.CAPACITY_ANALYSIS} />,
        },
        {
            label: 'Vergleich Plan/IST',
            tabPanel: <CapacityAnalysis type={CapacityAnalysisType.PERFORMED_CAPACITY_ANALYSIS} />,
        },
    ];

    return <Tabs tabs={tabs}></Tabs>;
}
