import { useState, useEffect } from 'react';

export function useSelectedChannel(channels) {
    const [selectedChannel, setSelectedChannel] = useState('');

    useEffect(() => {
        if (channels && channels.length > 0) {
            setSelectedChannel(channels[0]?.id);
        }
    }, [channels]);

    return [selectedChannel, setSelectedChannel];
}
