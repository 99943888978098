import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import { Auth0ProviderWithHistory } from './components/Auth/Auth0ProviderWithHistory';
import { LicenseManager } from 'ag-grid-enterprise';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './services/queryClient';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as Sentry from '@sentry/react';
import ErrorFallback from './pages/ErrorFallback';

Sentry.init({
    dsn: 'https://7d1a775ca3d9a1b52dbf27fe05053f6e@o331348.ingest.sentry.io/4506104691490816',
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                'localhost',
                /^https:\/\/beta-callmart\.azurewebsites\.net/,
                /^https:\/\/callmart\.unetiq\.com/,
            ],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 10% of transactions for performance monitoring.
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    enabled: process.env.NODE_ENV !== 'development',
});
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const baseUrl = process.env.REACT_APP_URL;
const isDevelopmentEnv = process.env.NODE_ENV === 'development';

LicenseManager.setLicenseKey(
    'Using_this_AG_Grid_Enterprise_key_( AG-044025 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Unetiq GmbH )_is_granted_a_( Single Application )_Developer_License_for_the_application_( callmart )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( callmart )_need_to_be_licensed___( callmart )_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 21 August 2024 )____[v2]_MTcyNDE5NDgwMDAwMA==8d5aa22a2eed9cac0339e49265c5a4a5'
);

if (!baseUrl || !baseUrl.startsWith('http')) {
    throw Error('Environment variable `REACT_APP_CMS_URL` needs to start with `http` or `https`');
}

axios.defaults.baseURL = baseUrl;

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <SentryRoutes>
                    <Route
                        path="*"
                        element={
                            <Sentry.ErrorBoundary fallback={ErrorFallback}>
                                <Auth0ProviderWithHistory>
                                    <App />
                                </Auth0ProviderWithHistory>
                            </Sentry.ErrorBoundary>
                        }
                    />
                </SentryRoutes>
            </BrowserRouter>
            {isDevelopmentEnv && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
