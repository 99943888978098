import { forwardRef } from 'react';
import { Dialog, IconButton, Stack, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    alertDialog: {
        '& .MuiAlert-icon': {
            marginTop: '0.5rem',
        },
    },
});

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AlertDialog({ message, handleClose }) {
    const classes = useStyles();

    return (
        <div>
            <Dialog open={message?.show && !message.useSnackbar} onClose={handleClose}>
                <Stack spacing={2} sx={{ width: '100%' }} direction="row">
                    <Alert severity={message.type} className={classes.alertDialog}>
                        <Typography variant="body1" sx={{ display: 'inline' }}>
                            {message.message}
                        </Typography>
                        <IconButton variant="outlined" onClick={handleClose} sx={{ ml: 1, color: 'white' }}>
                            <CloseIcon />
                        </IconButton>
                    </Alert>
                </Stack>
            </Dialog>
        </div>
    );
}
