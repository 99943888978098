import { useState } from 'react';
import { Button, Avatar, Stack, Link } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
import HEMTLogo from '../../assets/HEMT logo.png';
import callmartLogo from '../../assets/Callmart logo.png';
import CardPopover from '../UI/CardPopover';
import { makeStyles } from '@mui/styles';
import { HEMT_API_PRODUCTION } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
    appButton: {
        width: '50%',
    },
}));

function AppSwitch() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button onClick={handleClick} style={{ color: 'white' }}>
                <AppsIcon></AppsIcon>
            </Button>
            <CardPopover anchorEl={anchorEl} onClose={handleClose}>
                <Button
                    href={HEMT_API_PRODUCTION + '/profile'}
                    target="_blank"
                    rel="noopener"
                    component={Link}
                    className={classes.appButton}
                >
                    <Stack spacing={2} alignItems="center">
                        <Avatar alt="HEMT" src={HEMTLogo} />
                        <span>HEMT</span>
                    </Stack>
                </Button>
                <Button
                    href={process.env.CALLMART_URL}
                    target="_blank"
                    rel="noopener"
                    component={Link}
                    className={classes.appButton}
                >
                    <Stack spacing={2} alignItems="center">
                        <Avatar alt="Callmart" src={callmartLogo} />
                        <span>Callmart</span>
                    </Stack>
                </Button>
            </CardPopover>
        </>
    );
}

export default AppSwitch;
