const getFrequencyLabel = (frequency, repetition) => {
    switch (frequency) {
        case 'week':
            return repetition > 1 ? `Wochen` : 'Wöchentlich';
        case 'month':
            return repetition > 1 ? `Monate` : 'Monatlich';
        case 'year':
            return repetition > 1 ? `Jahre` : 'Jährlich';
        default:
            return '';
    }
};

export default getFrequencyLabel;
