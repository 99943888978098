import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/de';

function CustomDatePicker({ date, onChange, ...props }) {
    const handleChange = (newValue) => {
        onChange(newValue);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DatePicker
                {...props}
                fullWidth={true}
                value={date}
                onChange={(newValue) => {
                    handleChange(newValue);
                }}
                renderInput={(params) => <TextField {...params} helperText={null} />}
            />
        </LocalizationProvider>
    );
}

export default CustomDatePicker;
