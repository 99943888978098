import Typography from '@mui/material/Typography';
import { getCreatedAtForHistoryPopover } from '../../utils/dateUtils';

function DailyOSPBookingsHistory({ bookingDeltasHistory }) {
    return (
        <>
            <Typography variant="h5" style={{ textAling: 'center', marginBottom: '1em' }}>
                Änderungen
            </Typography>

            {bookingDeltasHistory &&
                bookingDeltasHistory.map((bookingDelta) => (
                    <div key={bookingDelta.id}>
                        <Typography variant="subtitle1">
                            <span style={{ color: 'var(--grey)', float: 'left' }}>
                                {getCreatedAtForHistoryPopover(bookingDelta.createdAt)}:{' '}
                            </span>
                            <span style={{ color: 'var(--primary)', float: 'right' }}>{bookingDelta.value}</span>
                        </Typography>
                    </div>
                ))}
        </>
    );
}

export default DailyOSPBookingsHistory;
