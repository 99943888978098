import { Typography, CardContent, Card, Grid } from '@mui/material';

const UserRoles = ({ roles }) => {
    return (
        <>
            <Grid item xs={12}>
                <Typography>Roles:</Typography>
            </Grid>
            {roles &&
                roles.map((role) => {
                    return (
                        <Grid item xs={12} sm={6} lg={3} key={role}>
                            <Card>
                                <CardContent style={{ backgroundColor: 'inherit' }}>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        {role}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
        </>
    );
};

export default UserRoles;
