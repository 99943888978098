import { useContext } from 'react';
import { MessageContext } from '../store/Message/MessageContext';

export const useMessage = () => {
    const { message, setMessage } = useContext(MessageContext);

    const resetMessage = () => {
        setMessage({
            show: false,
            type: message.type,
            message: '',
        });
    };
    return { message, setMessage, resetMessage };
};
