import { CapacityType } from '../../../enums/CapacityType';
import { CapacityPlausibilityCheck } from '../../../enums/CapacityPlausibilityCheck';

export const NUM_OF_MONTH_COLUMNS_FOR_CAPACITY_REPORT = 12;

export const COLUMNS_FOR_CAPACITY_REPORT_EXCEL_TEMPLATE = [
    'Datum',
    'Stadt',
    'Standort Detail',
    'Worktype',
    'Kanal',
    'Task',
    'Capacity Type',
    'Value',
];

export const plausibilityCheckOptions = [
    {
        value: 'all',
        text: 'Alle',
    },
    { value: CapacityPlausibilityCheck.CORRECT, text: 'Korrekt' },
    { value: CapacityPlausibilityCheck.WRONG, text: 'Falsch' },
];

export const CapacityTypesPlausibilityCheck = {
    CUSTOMER_WORKLOAD: {
        mainCapacityType: CapacityType.CUSTOMER_WORKLOAD.name,
        influentialCapacityTypes: [
            { name: CapacityType.CUSTOMER_WORKLOAD.name, variable: 'customerWorkload' },
            { name: CapacityType.ABT.name, variable: 'abt' },
            { name: CapacityType.HANDLED_CONTACTS.name, variable: 'handledContacts' },
        ],
    },
    FTE_PAID_AND_SENIOR_AGENT: {
        mainCapacityType: CapacityType.FTE_PAID_AND_SENIOR_AGENT.name,
        influentialCapacityTypes: [
            {
                name: CapacityType.FTE_PAID_AND_SENIOR_AGENT.name,
                variable: 'ftePaidAndSeniorAgent',
            },
            { name: CapacityType.PAID_TIME.name, variable: 'paidTime' },
        ],
    },
    PAID_TIME: {
        mainCapacityType: CapacityType.PAID_TIME.name,
        influentialCapacityTypes: [
            { name: CapacityType.PAID_TIME.name, variable: 'paidTime' },
            { name: CapacityType.VACATION.name, variable: 'vacation' },
            { name: CapacityType.SICKNESS.name, variable: 'sickness' },
            { name: CapacityType.TRAINING.name, variable: 'training' },
            { name: CapacityType.OTHER_UNPRODUCTIVITY.name, variable: 'otherUnproductivity' },
            { name: CapacityType.AVAILABLE_TIME.name, variable: 'availableTime' },
            { name: CapacityType.CUSTOMER_WORKLOAD.name, variable: 'customerWorkload' },
        ],
    },
};
