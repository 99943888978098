import { useEffect, useState, useMemo } from 'react';
import { getCookie } from '../utils';

export function useCookieLocation(locations) {
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedLocationData, setSelectedLocationData] = useState('');

    useEffect(() => {
        if (locations.length > 0) {
            const cookieLocation = parseInt(getCookie('location'));
            const cookieLocationInLocations = locations.find((location) => location.id === cookieLocation);
            if (cookieLocationInLocations) {
                setSelectedLocation(cookieLocation);
                setSelectedLocationData(cookieLocationInLocations);
            } else {
                setSelectedLocation(locations[0]?.id);
                setSelectedLocationData(locations[0]);
            }
        }
    }, [locations]);

    return useMemo(
        () => [selectedLocation, setSelectedLocation, selectedLocationData],
        [selectedLocation, selectedLocationData]
    );
}
