import api from './index';
import { setToken } from '../utils/authUtils';
import { fetchReadableStream } from './fetchReadableStream';

const BASE = '/tefOfferedPotential';

export const getAllTefOfferedPotentialByFilters = async (dateFrom, dateTo, worktype, channel) => {
    return api
        .get(
            `${BASE}/getAllTefOfferedPotentialByFilters?dateFrom=${dateFrom}&dateTo=${dateTo}&worktype=${worktype}&channel=${channel}`,
            await setToken()
        )
        .then((res) => res.data);
};

export const uploadTefOfferedPotentials = async (data, setPercentage) => {
    return await fetchReadableStream(`${BASE}/uploadPotentials`, data, setPercentage);
};

export const getTefOfferedPotentialsForExcelExport = async (dateFrom, dateTo) => {
    return api
        .get(`${BASE}/getTefOfferedPotentialsForExcelExport?dateFrom=${dateFrom}&dateTo=${dateTo}`, await setToken())
        .then((res) => res.data);
};
