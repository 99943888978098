import CRUDSettings from '../components/General/CRUDSettings';
import { useChannels, useCreateChannel, useUpdateChannel } from '../hooks';

const Channels = () => {
    const [channels] = useChannels();
    const { mutate: handleCreateChannel } = useCreateChannel();
    const { mutate: handleUpdateChannel } = useUpdateChannel();

    const columnDefs = [
        {
            field: 'name',
            headerName: 'Channel',
            editable: (params) => params.data.isRowEdited || params.data.isNewRecord,
        },
    ];

    return (
        <CRUDSettings
            tableRows={channels}
            columnDefs={columnDefs}
            btnText="Neuer Channel"
            createRecord={handleCreateChannel}
            updateRecord={handleUpdateChannel}
            recordType="Kanal"
        ></CRUDSettings>
    );
};

export default Channels;
