import { getCookie } from './';
import { getAllLocationFromHEMT } from '../services/telefonica';
import { getAllWorktype, getAllWorktypeByLocation } from '../services/worktype';
import { UserRole } from '../enums/UserRole';
import { getUserById } from '../services/telefonica';

export const onMonthPickerChange = (newValue, oldValue, setSelectedValue) => {
    const oldSelectedMonth = oldValue.getMonth();
    const newSelectedMonth = newValue.getMonth();

    if (oldSelectedMonth !== newSelectedMonth) {
        setSelectedValue(newValue);
    }
};

export const getPreferedUserLocation = async (user) => {
    let allUserLocationIds = [user.primaryLocationId, ...user.additionalLocationIds];
    const savedLocation = getCookie('location');

    if (!savedLocation || !allUserLocationIds.includes(parseInt(savedLocation))) return user.primaryLocation;

    const locations = await getAllLocationFromHEMT();
    const location = locations.find((loc) => loc.id === parseInt(savedLocation));
    return location;
};

export const getOptionLabelDependingOnLabelKeys = (option, labelKey, secondLabelKey) => {
    let label = '';
    if (option[labelKey]) {
        label += option[labelKey];
    }
    if (option[secondLabelKey]) {
        if (option[labelKey]) {
            label += ` - ${option[secondLabelKey]}`;
        } else {
            label += option[secondLabelKey];
        }
    }

    return label;
};

export const getSelectedWorktypeDependingOnCookie = (worktypes) => {
    if (worktypes.length > 0) {
        const cookieWorktype = parseInt(getCookie('worktype'));
        const cookieWorktypeInWorktypeOptions = worktypes.find((worktype) => worktype.id === cookieWorktype);
        if (cookieWorktypeInWorktypeOptions) return cookieWorktypeInWorktypeOptions;
        else return worktypes[0];
    }
};

export const getWorktypeOptionsByUserRole = async (loggedInUserRole, location, prependAllLocationsOption = false) => {
    let worktypes = [];
    if (loggedInUserRole === UserRole.TEF) {
        worktypes = await getAllWorktype();
    } else if (loggedInUserRole === UserRole.OSP) {
        if (location) worktypes = await getAllWorktypeByLocation(location);
    }

    if (worktypes.length && prependAllLocationsOption) {
        if (worktypes.length && prependAllLocationsOption) {
            worktypes.unshift({ id: -1, worktype: 'Alle Worktypes' });
        }
    }

    return worktypes;
};

export const getAllHourSlots = () => {
    let allHourSlots = [];
    for (let i = 7; i <= 22; i++) {
        allHourSlots = [...allHourSlots, `${i < 10 ? `0${i}` : i}:00`, `${i < 10 ? `0${i}` : i}:30`];
    }

    return allHourSlots;
};

export const setCommonStateAfterDataUpload = (setLoading, setPercentage, closeDropzoneDialog) => {
    setLoading(false);
    setPercentage(0);
    closeDropzoneDialog();
};

export const showSuccessfulUploadNotification = (dispatchAlert) => {
    dispatchAlert({
        message: 'Dein Upload wurde erfolgreich abgeschlossen',
        type: 'success',
        useSnackbar: false,
    });
};

export const showErrorUploadNotification = ({ dispatchAlert, errorMessage, customErrorMessage }) => {
    const defaultErrorMessage =
        'Leider gab es Probleme mit deinem Upload. Versuche es später noch einmal oder wende dich an einen Administrator.';

    showErrorNotification({
        dispatchAlert,
        message: errorMessage || customErrorMessage || defaultErrorMessage,
        useSnackbar: false,
    });
};

export const showErrorNotification = ({ dispatchAlert, message, useSnackbar }) => {
    dispatchAlert({
        message,
        type: 'error',
        useSnackbar,
        autoHideDuration: 10000,
    });
};

export const checkIsUserAllowedToAccessLocation = async (userId, locationId) => {
    if (!userId || !locationId) return false;
    const user = await getUserById(userId);
    const userLocationIds = [user.primaryLocationId, ...user.additionalLocationIds];
    return userLocationIds.includes(locationId);
};

export const ifDateLocationAndWorktype = ({ date, location, worktype }) => !!date && !!location && !!worktype;

export const ifDateAndWorktype = ({ date, worktype }) => !!date && !!worktype;

export const handleDatePickerMonthChange = (oldValue, newValue, setNewValue) => {
    let oldSelectedMonth = oldValue.month();
    let newSelectedMonth = newValue.month();

    if (oldSelectedMonth !== newSelectedMonth) {
        setNewValue(newValue);
    }
};

export const fieldIsRequiredMsg = 'Pflichtfeld';
