import { useContext, useState } from 'react';
import { Box, Toolbar, IconButton, Typography, AppBar as MuiAppBar, CssBaseline } from '@mui/material';
import AuthenticationButton from '../Auth/AuthenticationButton';
import UserContext from '../../store/User/UserContext';
import MenuIcon from '@mui/icons-material/Menu';
import SideDrawer from './SideDrawer';
import { styled } from '@mui/material/styles';
import { Route, Routes } from 'react-router-dom';
import Profile from '../../pages/Profile';
import CalldeltaOverview from '../../pages/CalldeltaOverview';
import { makeStyles } from '@mui/styles';
import OSPBookings from '../../pages/OSPBookings';
import DailyOSPBookings from '../../pages/DailyOSPBookings';
import LocationDeltas from '../../pages/LocationsDeltas';
import Dashboard from '../../pages/Dashboard';
import AppSwitch from '../Buttons/AppSwitch';
import HomePage from '../../pages/HomePage/HomePage';
import Impressum from '../Impressum';
import DataPrivacy from '../DataPrivacy';
import ProtectedRoute from '../Auth/ProtectedRoute';
import Events from '../../pages/Events';
import CapacityReport from '../../pages/CapacityReport';
import Potentials from '../../pages/Potentials';
import PotentialsTabs from '../../pages/PotentialsTabs';
import { PotentialsType } from '../../enums/PotentialsType';
import WorktypeLocations from '../../pages/WorktypeLocations';
import PerformedCapacityReport from '../../pages/PerformedCapacityReport';
import CapacityAnalysisTabs from '../../pages/CapacityAnalysisTabs';
import StrategicVolume from '../../pages/StrategicVolume';
import Worktypes from '../../pages/Worktypes';
import WFM from '../../pages/WFM';
import Tasks from '../../pages/Tasks';
import CapacityTypes from '../../pages/CapacityTypes';
import Channels from '../../pages/Channels';
import Capacities from '../../pages/Capacities';
import OfferedReport from '../../pages/OfferedReport';
import WorkingHours from '../../pages/WorkingHours';
import VolumeOverview from '../../pages/VolumeOverview';
import Reduction from '../../pages/Reduction';

const DRAWER_WIDTH = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: DRAWER_WIDTH,
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const useStyles = makeStyles((theme) => ({
    main: {
        paddingTop: theme.mixins.toolbar.minHeight,
    },
}));

function NavBar() {
    const classes = useStyles();

    const loggedInUser = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState('');

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    {loggedInUser && (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: '36px',
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {selectedMenuItem}
                    </Typography>
                    <AppSwitch></AppSwitch>
                    <AuthenticationButton />
                </Toolbar>
            </AppBar>

            <SideDrawer open={open} setOpen={setOpen} onSelectedMenuItemChange={setSelectedMenuItem}>
                <main className={classes.main}>
                    <Routes>
                        <Route exact={true} path="/" element={<HomePage />} />
                        <Route exact={true} path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
                        <Route exact={true} path="/profile" element={<ProtectedRoute component={Profile} />} />
                        <Route
                            exact={true}
                            path="/calldeltaOverview"
                            element={<ProtectedRoute component={CalldeltaOverview} />}
                        />
                        <Route exact={true} path="/ospBookings" element={<ProtectedRoute component={OSPBookings} />} />
                        <Route
                            exact={true}
                            path="/dailyOSPBookings"
                            element={<ProtectedRoute component={DailyOSPBookings} />}
                        />
                        <Route
                            exact={true}
                            path="/locationDeltas"
                            element={<ProtectedRoute component={LocationDeltas} />}
                        />
                        <Route exact={true} path="/impressum" element={<ProtectedRoute component={Impressum} />} />
                        <Route exact={true} path="/dataprivacy" element={<ProtectedRoute component={DataPrivacy} />} />
                        <Route exact={true} path="/events" element={<ProtectedRoute component={Events} />} />
                        <Route
                            exact={true}
                            path="/capacityReport"
                            element={<ProtectedRoute component={CapacityReport} />}
                        />
                        <Route
                            exact={true}
                            path="/potentials"
                            element={
                                <ProtectedRoute
                                    type={
                                        loggedInUser && loggedInUser.role.callmart_osp
                                            ? PotentialsType.CALL_CENTER
                                            : null
                                    }
                                    component={
                                        loggedInUser && loggedInUser.role.callmart_tef ? PotentialsTabs : Potentials
                                    }
                                />
                            }
                        />
                        <Route
                            exact={true}
                            path="/locations"
                            element={<ProtectedRoute component={WorktypeLocations} />}
                        />
                        <Route exact={true} path="/worktypes" element={<ProtectedRoute component={Worktypes} />} />
                        <Route
                            exact={true}
                            path="/capacityAnalysis"
                            element={<ProtectedRoute component={CapacityAnalysisTabs} />}
                        />
                        <Route
                            exact={true}
                            path="/performedCapacityReport"
                            element={<ProtectedRoute component={PerformedCapacityReport} />}
                        />
                        <Route exact={true} path="/volume" element={<ProtectedRoute component={VolumeOverview} />} />
                        <Route
                            exact={true}
                            path="/volume/strategic"
                            element={<ProtectedRoute component={StrategicVolume} />}
                        />
                        <Route
                            exact={true}
                            path="/volume/offered"
                            element={<ProtectedRoute component={OfferedReport} />}
                        />
                        <Route exact={true} path="/reduction" element={<ProtectedRoute component={Reduction} />} />
                        <Route exact={true} path="/WFM" element={<ProtectedRoute component={WFM} />} />
                        <Route exact={true} path="/tasks" element={<ProtectedRoute component={Tasks} />} />
                        <Route
                            exact={true}
                            path="/capacityTypes"
                            element={<ProtectedRoute component={CapacityTypes} />}
                        />
                        <Route exact={true} path="/channels" element={<ProtectedRoute component={Channels} />} />
                        <Route exact={true} path="/capacities" element={<ProtectedRoute component={Capacities} />} />
                        <Route
                            exact={true}
                            path="/workingHours"
                            element={<ProtectedRoute component={WorkingHours} />}
                        />
                    </Routes>
                </main>
            </SideDrawer>
        </Box>
    );
}

export default NavBar;
