import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    active: {
        backgroundColor: 'var(--lightGrey) !important',
    },
}));

function MenuItem({ item, isNestedItem, isMenuOpen, showMenuItemExpandIcon, onClick, currentLocation, ...props }) {
    const classes = useStyles();

    return (
        <ListItem
            button
            onClick={onClick}
            className={currentLocation?.pathname === item?.path ? classes.active : null}
            component={!item?.nestedItems ? Link : 'div'}
            {...(!item?.nestedItems && { to: item.path })}
            {...props}
        >
            <ListItemIcon>{(isMenuOpen && !isNestedItem) || !isMenuOpen ? item.icon : null}</ListItemIcon>
            <ListItemText
                primary={item.text}
                primaryTypographyProps={isMenuOpen ? { whiteSpace: 'normal' } : { whiteSpace: 'nowrap' }}
            />
            {showMenuItemExpandIcon(item)}
        </ListItem>
    );
}

export default MenuItem;
