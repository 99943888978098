import fetchStream from 'fetch-readablestream';
import { readAllChunks } from '../utils';
import { getToken } from '../utils/authUtils';

const APP_URL = process.env.REACT_APP_URL || 'http://localhost:5000';

export const fetchReadableStream = async (url, data, setPercentage) => {
    const token = await getToken();

    return await fetchStream(`${APP_URL}/api${url}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify(data),
    })
        .then((response) => readAllChunks(response.body, setPercentage))
        .then((chunks) => chunks);
};
